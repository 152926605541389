import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import './TaskBoard.scss';
import { calcWaterUnit, isWaterFull, ScoreTranslation, TaskNames, TaskHook, isThereWateringToday } from '../model';

const T = ScoreTranslation;

export const TaskBoard: FunctionComponent<{ hook: TaskHook }> = ({ hook }) => {
  const {
    isComplete,
    isCollapse,
    score,
    day,
    dayStatus,
    goTask,
    watering,
    toggleCollapse
  } = hook;
  return (
    <div className="task-board">
      <div className="task-navbar">
        {isComplete && <h3>所有任務都完成囉！</h3>}
        {
          !isComplete && isCollapse && (
            <button className="btn btn-expand fz-md" onClick={toggleCollapse}>查看任務列表 ▼</button>
          )
        }
        {
          !isComplete && !isCollapse && (
            <>
              <h3 className="day">第 {day} 天</h3>
              <div className="task-list">
                {
                  TaskNames.map(taskName => (
                    <button
                      key={taskName}
                      className={classNames('btn btn-task', { 'is-complete': score[taskName] })}
                      disabled={!!score[taskName]}
                      onClick={() => goTask(taskName)}
                    >
                      <img src={`/task/btn${score[taskName] || 0}.png`} alt="" />
                      <label htmlFor="">{T[taskName]}</label>
                    </button>
                  ))
                }
              </div>
              <button className="btn btn-collapse" onClick={toggleCollapse}>收合任務列表 ▲</button>
              {
                !isThereWateringToday(score) && (
                  <button
                    className="btn btn-watering"
                    disabled={!isWaterFull(score)}
                    onClick={watering}
                  >
                    <img src={`/task/watering${calcWaterUnit(score)}.png`} alt="" />
                  </button>
                )
              }
            </>
          )
        }
      </div>
      <div className="task-sunflower">
        <img className="img-response" src={`/task/day${day}-${dayStatus}.png`} alt="" />
      </div>
    </div>
  );
}
