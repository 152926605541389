import React, { FunctionComponent, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { BaseHeader, BaseLayout } from '@component/layouts';
import { useModal } from '@component/atoms';
import { copy } from '@library/clipboard';
import { GetOnePostParams, OpenReplyModalQuery, Reply } from '../model';
import { useForumControl } from '../use-case';
import { SearchButton, PostDetail, ReplyDetail, ReplyModal } from '../component';
import './post.page.scss';

const PostPageCmp: FunctionComponent = () => {
  const params = useParams<GetOnePostParams>();
  const {
    post,
    getPost,
    addPostEmoji,
    addReply,
    replies,
    getReplies,
    canOpenReplyModal,
    addReplyEmoji,
    getReplyLayerNum,
  } = useForumControl();
  const replyModal = useModal<OpenReplyModalQuery, Reply>();

  useEffect(() => {
    getPost(params);
    getReplies(params);
  }, [params]);

  function shareLink() {
    const link = location.href;
    copy(link)
      .then(() => alert('已複製'))
      .catch(error => console.error(error));
  }

  function sendReply(payload: Reply) {
    addReply(payload)
      .catch(error => console.error(error));
  }

  function openReply(params: OpenReplyModalQuery) {
    replyModal.open(params);
  }

  return (
    <BaseLayout
      className="post-page"
      disabledScroll={replyModal.visible}
      header={
        <>
          <BaseHeader title="留言板" isLeftBtnBack right={<SearchButton />} />
        </>
      }
      body={
        <>
          <PostDetail {...post}
            onEmoji={emoji => addPostEmoji({ emoji })}
            onShare={shareLink}
            onReply={() => openReply(post)}
          />
          {
            replies.map(reply => (
              <ReplyDetail key={reply.replyId} {...reply}
                onEmoji={() => addReplyEmoji(reply)}
                onReply={() => openReply(reply)}
                canOpenReply={canOpenReplyModal}
                getReplyLayerNum={getReplyLayerNum}
              />
            ))
          }
          <ReplyModal modal={replyModal} onConfirm={sendReply} />
        </>
      }
    />
  );
}

export default PostPageCmp;
