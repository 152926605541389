import { useState } from 'react';
import { Article, ArticleConfig, ArticleQueryParams } from '../model';

export function useArticle() {
  const [list, setList] = useState<Article[]>([]);
  const [type, setType] = useState<string>('');

  function search({ type }: ArticleQueryParams) {
    const listNew = ArticleConfig.filter(r => r.type === type);
    setList(listNew);
  }

  return {
    list,
    type,
    setType,
    search,
  };
}
