import React, { useState } from 'react';
import classNames from 'classnames';
import './Modal.scss';

export interface ModalProps {
  visible?: boolean;
  className?: string;
  children?: React.ReactNode;
}


export const Modal = ({ visible, children, className }: ModalProps) => {
  return visible ? (
    <div className={classNames('modal', className)}>
      {children}
    </div>
  ) : <></>;
};

export function useModal<InputData = any, OutputData = any>(
  initInputData?: InputData,
  resetOnClose = true
): ModalControl<InputData, OutputData> {
  const [visible, setVisible] = useState(false);
  const [inputData, setInputData] = useState<InputData>(initInputData as InputData);
  const [outputData, setOutputData] = useState<OutputData>(null as any);

  function open(data?: InputData) {
    setVisible(true);
    if (data) {
      setInputData(data as InputData);
    }
  }

  function close(callback = () => { }) {
    setVisible(false);
    if (callback) {
      callback();
    }
    if (resetOnClose) {
      setInputData(initInputData as InputData);
    }
  }

  return {
    visible,
    inputData,
    open,
    close,
  };
}

export type ClosingCode = Record<'cancel' | 'confirm', number>;

export interface ModalControl<InputData = any, OutputData = any> {
  visible: boolean;
  inputData: InputData;
  open(inputData?: InputData): void;
  close(callback?: (outputData?: OutputData) => void): void;
}
