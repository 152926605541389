import React from 'react';
import { Message, EmailMessage, GeneralMessage, MessageType, LinkMessage, makeMailHref } from '../model';
import { Chatbot } from '../use-case';

const GeneralOption = ({ option }: { option: GeneralMessage }) => {
  return (
    <button
      className='option'
      onClick={() => reply(option)}>
      {option.content || option.description}
    </button>
  );
};

const EmailOption = ({ option }: { option: EmailMessage }) => (
  <a className='option' href={makeMailHref(option.mail)}
    onClick={() => reply(option)}>
    {option.content || option.description}
  </a>
);


const LinkOption = ({ option }: { option: LinkMessage }) => (
  <a className='option' href={option.link} target="_blank" rel="noopener noreferrer"
    onClick={() => reply(option)}>
    {option.content || option.description}
  </a>
);

const reply = async (option: Message) => {
  try {
    await Chatbot.reply(option);
  } catch (error) {
    console.error(error);
  }
};

const Component: Record<MessageType, React.StatelessComponent<{ option: any }>> = {
  message: GeneralOption,
  mail: EmailOption,
  typing: () => null,
  link: LinkOption,
};

export const Option = ({ option }: { option: Message }) => (
  React.createElement(Component[option.type] || (() => null), { option })
);
