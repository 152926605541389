import { HttpHelper } from '@library/http-helper';
import {
  Post,
  Reply,
  PostEmoji,
  ReplyEmoji,
  GetPostsParams,
  GetOnePostParams,
  GetReplyFromPostParams,
  GetReplyFromReplyParams
} from '../model';

export class ForumApiService {

  constructor(
    private endpoint: string
  ) {
  }

  addPost(payload: Post): Promise<Post> {
    const url = `${this.endpoint}/forum/post`;
    return fetch(url, { method: 'POST', body: JSON.stringify(payload), headers: { 'Content-Type': 'application/json' } })
      .then(HttpHelper.handleResponse);
  }

  getPosts({ category }: GetPostsParams): Promise<Post[]> {
    const url = `${this.endpoint}/forum/${category || 0}`;
    return fetch(url)
      .then(HttpHelper.handleResponse);
  }

  getPost({ postId }: GetOnePostParams): Promise<Post> {
    const url = `${this.endpoint}/forum/post/${postId}`;
    return fetch(url)
      .then(HttpHelper.handleResponse);
  }

  addReplyToPost(payload: Reply): Promise<Reply> {
    const url = `${this.endpoint}/forum/reply/${payload.postId}`;
    return fetch(url, { method: 'POST', body: JSON.stringify(payload), headers: { 'Content-Type': 'application/json' } })
      .then(HttpHelper.handleResponse);
  }

  getReplyFromPost({ postId }: GetReplyFromPostParams): Promise<Reply[]> {
    const url = `${this.endpoint}/forum/post/${postId}`;
    return fetch(url)
      .then(HttpHelper.handleResponse)
      .then((data: Post) => data.replies || []);
  }

  getReplyFromReply({ postId, replyId }: GetReplyFromReplyParams): Promise<Reply[]> {
    const url = `${this.endpoint}/forum/reply/${postId}/${replyId}`;
    return fetch(url)
      .then(HttpHelper.handleResponse);
  }

  addReplyToReply(payload: Reply): Promise<Reply> {
    const url = `${this.endpoint}/forum/reply/${payload.postId}/${payload.replyId}`;
    return fetch(url, { method: 'POST', body: JSON.stringify(payload), headers: { 'Content-Type': 'application/json' } })
      .then(HttpHelper.handleResponse);
  }

  addPostEmoji({ postId, emoji }: PostEmoji): Promise<void> {
    const url = `${this.endpoint}/forum/postemoji/${postId}/${emoji}`;
    return fetch(url, { method: 'POST' })
      .then(HttpHelper.handleResponse);
  }

  addReplyEmoji({ postId, replyId }: ReplyEmoji): Promise<void> {
    const url = `${this.endpoint}/forum/replyemoji/${postId}/${replyId}`;
    return fetch(url, { method: 'POST' })
      .then(HttpHelper.handleResponse);
  }

  // 複製貼文連結
}