export interface Chatroom {
  chatroomId: string;
}

export interface AddChatroomInput { }

export interface Message {
  isMine?: boolean;  // jest frontend use, backend not allow.
  time?: string;  // jest frontend use, backend not allow.
  type: MessageType;
  code: string;  // message node code
  content: string;  // message text
  description?: string;  // message text || option label
  next?: Message[];  // next nodes Message without next
  mail?: Mail; // mail
  link?: string;
}

export type MessageType = 'message' | 'typing' | 'mail' | 'link';

export interface GeneralMessage extends Message {
  type: 'message';
  mail?: never;
  link?: never;
}

export interface TypingMessage extends Message {
  type: 'typing';
  mail?: never;
  link?: never;
}

export interface EmailMessage extends Message {
  type: 'mail';
  mail: Mail;
  link?: never;
}

export interface LinkMessage extends Message {
  type: 'link';
  link: string;
  mail?: never;
}

export interface GetMessageInput {
  code: string;
}

export interface Mail {
  mailto: string;
  subject: string;
}

export interface ChatroomMessageHistory {
  username?: string;
  chatroomId: string;
  time?: string;
  message: HistoryMessage;
}

export interface HistoryMessage {
  isMine?: never;
  type: Exclude<MessageType, 'typing'>;
}

export const makeMailHref = (mail: Mail) => `mailto:${mail.mailto}?subject=${encodeURI(mail.subject)}`;
