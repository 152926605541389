import { ModalControl, Modal } from '@component/atoms';
import { Quiz, Score } from '../basketball.model';
import React from 'react';
import { ScoreBord } from './score-bord';

export const QuizModal = ({
  modal,
  onNo,
  onYes,
}: {
  modal: ModalControl<Quiz>;
  onNo?: () => void,
  onYes?: () => void
}) => {
  const { inputData, close } = modal;
  return (
    <Modal visible={modal.visible}>
      <div className='quiz-container'>
        <img className='img-response' src='/game/basketball/bg_quiz.png' alt='' />
        <ScoreBord score={inputData.score as Score} />
        <div className='quiz-modal'>
          <header>
            {inputData.title}
          </header>
          <main>
            <p>{inputData.description}</p>
          </main>
          <footer>
            <div
              className='btn-img'
              onClick={() => close(onNo)}
              style={{ backgroundImage: `url('/game/basketball/btn_no.png')` }}
            />
            <div
              className='btn-img'
              onClick={() => close(onYes)}
              style={{ backgroundImage: `url('/game/basketball/btn_yes.png')` }}
            />
          </footer>
        </div>
      </div>
    </Modal>
  );
};
