import { Scene, NodeLifecircle, Game, SpriteStorage, DisplayNode } from '@library/game';
import { SceneName, TextureName } from './config';
import { ThrowDart } from './useThrowDarts';

export class EndgameScene extends Scene implements NodeLifecircle {
  constructor(
    private game: Game,
    private spriteStorage: SpriteStorage,
    private useCase: ThrowDart,
  ) {
    super();
  }

  onInit() {
    console.log('onInit EndgameScene');
    this.useCase.reset();
  }

  onDestory() {
    console.log('onDestory EndgameScene');
  }

  render() {
    const self = this;
    return [
      DisplayNode.of({
        object: this.spriteStorage.get(TextureName.end_game),
      }),
      DisplayNode.of({
        object: this.spriteStorage.get(TextureName.back_intro),
        decoration: {
          position: { x: 52, y: 562 },
          interactive: true,
          buttonMode: true,
        },
        onPointertap() {
          self.game.navigate(SceneName.PregameScene);
        }
      }),
      DisplayNode.of({
        object: this.spriteStorage.get(TextureName.game_over),
        decoration: {
          position: { x: 217, y: 561 },
          interactive: true,
          buttonMode: true,
        },
        onPointertap() {
          self.game.history.goBack();
        }
      }),
    ];
  }
}
