import { AuthStorage } from '@/auth/repository';
import { HttpHelper } from '@library/http-helper';
import { Score, TaskName } from '../model';

export class ScoreApiService {

  private authStorage = new AuthStorage();

  constructor(
    private endpoint: string,
  ) {
  }

  getScore(): Promise<Score[]> {
    const url = `${this.endpoint}/score/`;
    return fetch(url, { headers: this.makeHeader() })
      .then(HttpHelper.handleResponse);
  }

  completeTask(taskName: TaskName): Promise<null> {
    const url = `${this.endpoint}/score/${taskName}`;
    const body = JSON.stringify({});
    const headers = this.makeHeader();
    return fetch(url, { method: 'POST', body, headers })
      .then(HttpHelper.handleResponse);
  }
  
  watering(): Promise<null> {
    const url = `${this.endpoint}/score/watering`;
    const body = JSON.stringify({});
    const headers = this.makeHeader();
    return fetch(url, { method: 'POST', body, headers })
      .then(HttpHelper.handleResponse);
  }

  getInvitationCount(): Promise<number> {
    const url = `${this.endpoint}/score/invite`;
    return fetch(url, { headers: this.makeHeader() })
      .then(HttpHelper.handleResponse)
      .then(body => body.invitationCount);
  }

  private makeHeader(): HeadersInit {
    return { 'Content-Type': 'application/json', Authorization: this.authStorage.getToken()! };
  }
}
