import React, { FunctionComponent, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { BaseLayout } from '@component/layouts';
import './auth.scss';
import { cellphoneRegExp, emailRegExp, FindBackUsernameFormValue } from '../model';
import { AuthService as useCase } from '../use-case';

export const ForgotAccountPageCmp: FunctionComponent = () => {
  const { register, handleSubmit, errors } = useForm<FindBackUsernameFormValue>();
  const [apiError, setApiError] = useState<string>();
  const [username, setUsername] = useState<string>();

  function onSubmit(data: FindBackUsernameFormValue): void {
    useCase.findBackUsername(data)
      .then(data => {
        setUsername(data);
      }, () => {
        setApiError('資訊錯誤，請再試一次');
      });
  }

  return (
    <BaseLayout
      className="forgot-account-page"
      body={
        username
          ? (
            <div className="from-container horizontal-flex">
              <h3 className="text-center form-title">忘記密碼</h3>
              <h3 className="text-center">您的帳號為：{username}</h3>
              <div className="text-center">
                <Link className="btn btn-submit fz-lg" to="/sign-in">返回登入</Link>
              </div>
            </div>
          ) : (
            <div className="from-container horizontal-flex">
              <h3 className="text-center form-title">忘記密碼</h3>
              <div className="form-group">
                <label>email</label>
                <div className="form-field">
                  <input className="form-control" type="text" name="email" ref={register({ pattern: emailRegExp })} />
                  {errors.email && <p className="error-message">請符合 Email 格式，如. xxxxxx@gmail.com</p>}
                </div>
              </div>
              <div className="form-group">
                <label>手機號碼</label>
                <div className="form-field">
                  <input className="form-control" type="text" name="phone" ref={register({ pattern: cellphoneRegExp })} />
                  {errors.phone && <p className="error-message">限 10 碼數字，如. 0987654321</p>}
                </div>
              </div>
              <div className="text-center">
                <button
                  type="button"
                  className="btn btn-submit fz-lg"
                  onClick={handleSubmit(onSubmit)}
                >確定</button>
                {apiError && <p className="error-message">{apiError}</p>}
              </div>
              <p className="text-center"><Link style={{ color: '#7B6E6D' }} to="/sign-in">返回登入</Link></p>
            </div>
          )
      }
    />
  );
};
