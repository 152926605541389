import React, { useEffect, useState, useRef, FunctionComponent } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { OrientationType, useOrientation } from '@hook/useOrientation';
import { BaseHeader, BaseLayout, StoryLayout } from '@component/layouts';
import { makeSildeApp, Slide, SlideApp } from '@library/slide-board';
import { useQueryParams } from '@library/router';
import { getProvider } from '@/Context';
import { StoryService } from './story.service';
import { STORIES } from './configs';
import { Stroyboard, StroySlideParams } from './story.model';

export const StoryPageCmp: FunctionComponent = () => {
  const { completeTask } = getProvider('taskHook');
  const history = useHistory();
  const storyService = new StoryService(STORIES);
  const { orientationType } = useOrientation(window);
  const params = useParams<Pick<StroySlideParams, 'storyId'>>();
  const query = useQueryParams<Pick<StroySlideParams, 'slideId'>>();
  const [slide, setSlide] = useState<Slide>({ id: '', bg: '', btn: [] });
  const [stroy, setStroy] = useState<Stroyboard>({ storyId: params.storyId });
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [slideApp, setSlideApp] = useState<SlideApp>();
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    storyService.getStoryById(params.storyId!)
      .then(s => {
        setStroy(s);
        const sApp = makeSildeApp({
          view: canvasRef.current as HTMLCanvasElement,
          goBack: () => history.goBack(),
          slide: s.slide as Slide[],
          bgmSrc: s.bgm,
          size: { width: 414, height: 668, },
          onProgress: x => setProgress(x),
          taskName: s.taskName!,
          completeTask,
        });
        setSlideApp(sApp);
      })
      .catch(console.error);
    storyService.getOneSlide({ ...params, ...query })
      .then(setSlide)
      .catch(console.error);

    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function isProgressing() {
    return progress !== 100;
  }

  useEffect(() => {
    return () => slideApp?.destory();
  }, [slideApp]);

  return (
    <BaseLayout
      header={<BaseHeader title={stroy.title} isLeftBtnBack />}
      body={<StoryLayout>
        {
          isProgressing() && (
            <div className="loader-progress">
              <p className="progress-text fz-lg">{progress}%</p>
            </div>
          )
        }
        {
          orientationType === OrientationType.landscape
            ? <h1 className="text-center" style={{ color: '#fff' }}>請轉直螢幕</h1>
            : <canvas ref={canvasRef} className="story-board"></canvas>
        }
      </StoryLayout>}
    />
  );
}
