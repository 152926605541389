import React, { FunctionComponent } from 'react';
import { Modal, ModalControl } from '@component/atoms';
import './about-use-modal.scss';

export interface AboutUsModalParams {
  modal: ModalControl;
}

export const AboutUsModal: FunctionComponent<AboutUsModalParams> = ({ modal }) => (
  <Modal visible={modal.visible} className="about-us-modal">
    <div className="about-us-container">
      <h3>關於我們</h3>
      <p>幸福 On the Go 是花蓮慈濟醫院與慈濟大學跨領域研究團隊共同研發的行動 APP，協助用戶了解如何預防未成年懷孕、青少年性病及愛滋病，希望用戶能有正確的性知識及態度而避免危險性行為的發生。</p>
      <hr className="hr-pink" />
      <h3>團隊成員</h3>
      <p>___ 慈濟大學 ___</p>
      <p>蕭心怡教授 - 計畫主持人</p>
      <p>跨科系學生 - APP內容設計</p>
      <p>___ 美國密蘇里大學 ___</p>
      <p>徐洵達教授 - 計畫共同主持人</p>
      <p>___ 南加州大學 社工學院 ___</p>
      <p>張博盛 - 專案經理</p>
      <p>工程師團隊</p>
      <button className="btn btn-confirm fz-lg" onClick={() => modal.close()}>確定</button>
    </div>
  </Modal>
);
