import React, { useEffect } from 'react';
import { ModalControl, Modal } from '@component/atoms';
import { AnimatName } from '../basketball.model';

export const AnimationModal = ({
  modal,
  onClose,
}: {
  modal: ModalControl<{ animatName: AnimatName }>;
  onClose?: () => void,
}) => {
  const { inputData, close } = modal;

  function calcActionClassName(animatName: AnimatName): string {
    const className = ['action'];
    if (animatName) {
      const action = animatName.replace(/[A-Z]/g, s => `-${s.toLowerCase()}`);
      className.push(action);
    }
    return className.join(' ');
  }

  useEffect(() => {
    if (inputData.animatName) {
      setTimeout(() => close(onClose), 5000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputData]);

  return (
    <Modal visible={modal.visible}>
      <img className={calcActionClassName(inputData.animatName || 'shotScore')} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAZ4AAAKcCAQAAAByVRQSAAAFJUlEQVR42u3TMQ0AAAzDsJU/6WFobxtCpOSASSQA84B5wDxgHsA8YB4wD5gHzAOYB8wD5gHzgHkA84B5wDxgHsA8YB4wD5gHzAOYB8wD5gHzgHkA84B5wDxgHsA8YB4wD5gHzAOYB8wD5gHzgHkA84B5wDxgHsA8YB4wD5gHzAOYB8wD5gHzgHkA84B5wDxgHsA8YB4wD5gHzAOYB8wD5gHzgHkA84B5wDxgHjAPYB4wD5gHzAOYB8wD5gHzgHkA84B5wDxgHjAPYB4wD5gHzAOYB8wD5gHzgHkA84B5wDxgHjAPYB4wD5gHzAOYB8wD5gHzgHkA84B5wDxgHjAPYB4wD5gHzAOYB8wD5gHzgHkA84B5wDxgHjAPYB4wD5gHzAPmAcwD5gHzgHkA84B5wDxgHjAPYB4wD5gHzAPmAcwD5gHzgHkA84B5wDxgHjAPYB4wD5gHzAPmAcwD5gHzgHkA84B5wDxgHjAPYB4wD5gHzAPmAcwD5gHzgHkA84B5wDxgHjAPYB4wD5gHzAPmAcwD5gHzgHnAPIB5wDxgHjAPYB4wD5gHzAPmAcwD5gHzgHnAPIB5wDxgHjAPYB4wD5gHzAPmAcwD5gHzgHnAPIB5wDxgHjAPYB4wD5gHzAPmAcwD5gHzgHnAPIB5wDxgHjAPYB4wD5gHzAPmAcwD5gHzgHnAPIB5wDxgHjAPmEcCMA+YB8wD5gHMA+YB84B5wDyAecA8YB4wD5gHMA+YB8wD5gHMA+YB84B5wDyAecA8YB4wD5gHMA+YB8wD5gHMA+YB84B5wDyAecA8YB4wD5gHMA+YB8wD5gHMA+YB84B5wDyAecA8YB4wD5gHMA+YB8wD5gHMA+YB84B5wDyAecA8YB4wD5gHMA+YB8wD5gHzAOYB84B5wDyAecA8YB4wD5gHMA+YB8wD5gHzAOYB84B5wDyAecA8YB4wD5gHMA+YB8wD5gHzAOYB84B5wDyAecA8YB4wD5gHMA+YB8wD5gHzAOYB84B5wDyAecA8YB4wD5gHMA+YB8wD5gHzAOYB84B5wDxgHsA8YB4wD5gHMA+YB8wD5gHzAOYB84B5wDxgHsA8YB4wD5gHMA+YB8wD5gHzAOYB84B5wDxgHsA8YB4wD5gHMA+YB8wD5gHzAOYB84B5wDxgHsA8YB4wD5gHMA+YB8wD5gHzAOYB84B5wDxgHsA8YB4wD5gHzAOYB8wD5gHzAOYB84B5wDxgHsA8YB4wD5gHzAOYB8wD5gHzAOYB84B5wDxgHsA8YB4wD5gHzAOYB8wD5gHzAOYB84B5wDxgHsA8YB4wD5gHzAOYB8wD5gHzAOYB84B5wDxgHsA8YB4wD5gHzAOYB8wD5gHzgHkkAPOAecA8YB7APGAeMA+YB8wDmAfMA+YB84B5APOAecA8YB7APGAeMA+YB8wDmAfMA+YB84B5APOAecA8YB7APGAeMA+YB8wDmAfMA+YB84B5APOAecA8YB7APGAeMA+YB8wDmAfMA+YB84B5APOAecA8YB7APGAeMA+YB8wDmAfMA+YB84B5APOAecA8YB4wD2AeMA+YB8wDmAfMA+YB84B5APOAecA8YB4wD2AeMA+YB8wDmAfMA+YB84B5APOAecA8YB4wD2AeMA+YB8wDmAfMA+YB84B5APOAecA8YB4wD2AeMA+YB8wDmAfMA+YB84B5APOAecA8YB4wD2AeMA+YB8wD5gHMA+YB84B5APOAecA8YB4wD2AeMA+YB8wD5gFaD5ZHAp1C6blEAAAAAElFTkSuQmCC" alt='' />
    </Modal >
  );
};
