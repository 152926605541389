import React from 'react';
import { ModalControl, Modal } from '@component/atoms';
import { Result, Score } from '../basketball.model';
import { ScoreBord } from './score-bord';

export const ResultModal = ({
  modal,
  onClose,
}: {
  modal: ModalControl<Result>;
  onClose?: () => void,
}) => {
  const { inputData, close } = modal;
  return (
    <Modal visible={modal.visible}>
      <div className='result-container'>
        <img className='img-response' src='/game/basketball/bg_quiz.png' alt='' />
        <ScoreBord score={inputData.score as Score} />
        <div className='result-modal'>
          <header>
            {inputData.title}
          </header>
          <main>
            <p>{inputData.description}</p>
            {
              inputData.reference
              && <a className="reference" href={inputData.reference.link} target="_blank" rel="noopener noreferrer">
                {inputData.reference.label}
              </a>
            }
          </main>
          <footer>
            <div
              className='btn-img'
              onClick={() => close(onClose)}
              style={{
                backgroundImage: `url('${
                  inputData.whetherToScore
                    ? '/game/basketball/btn_yes_answer.png'
                    : '/game/basketball/btn_no_answer.png'
                  }')`
              }}
            />
          </footer>
        </div>
      </div>
    </Modal>
  );
};
