import { Message, GetMessageInput, Chatroom, ChatroomMessageHistory } from '../model';
import { HttpHelper } from '@library/http-helper';
import { AuthStorage } from '@/auth/repository';

export class ChatbotApiService {

  private authStorage = new AuthStorage();

  constructor(
    private endpoint: string
  ) {
  }

  getFirst(): Promise<Message> {
    const url = `${this.endpoint}/message/0`;
    return fetch(url)
      .then(HttpHelper.handleResponse);
  }

  getOne(params: GetMessageInput): Promise<Message> {
    const url = `${this.endpoint}/message/${params.code}`;
    return fetch(url)
      .then(HttpHelper.handleResponse);
  }

  saveHistory(record: ChatroomMessageHistory): Promise<string> {
    const url = `${this.endpoint}/message/message-history`;
    const payload: ChatroomMessageHistory = {
      ...record,
      username: this.authStorage.getToken()!,
      time: new Date().toLocaleString('zh-TW', { hour12: false }),
    };
    return fetch(url, { method: 'POST', body: JSON.stringify(payload), headers: this.makeHeader() })
      .then(HttpHelper.handleResponse);
  }

  getNewChatroom(): Promise<Chatroom> {
    const url = `${this.endpoint}/message/chatroom`;
    return fetch(url)
      .then(HttpHelper.handleResponse);
  }

  private makeHeader(): HeadersInit {
    return { 'Content-Type': 'application/json', Authorization: this.authStorage.getToken()! };
  }
}
