import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { History } from 'history';
import { useHistory } from 'react-router-dom';
import { OrientationType, useOrientation } from '@hook/useOrientation';
import { BaseHeader, BaseLayout, StoryLayout } from '@component/layouts';
import { makeGame } from './game';
import { Game } from '@library/game';
import { getProvider } from '@/Context';

export const DartPageCmp: FunctionComponent = () => {
  const { completeTask } = getProvider('taskHook');
  const history: History = useHistory();
  const { orientationType } = useOrientation(window);
  const [dartGame] = useState<Game>(makeGame(completeTask));

  const canvasRef = useCallback((node: HTMLCanvasElement) => {
    if (node !== null) {
      console.log('game init');
      dartGame.history = history;
      dartGame.bootstrap({ view: node, width: 414, height: 668 });
    }
  }, [history]);

  useEffect(() => {
    return () => {
      dartGame.destroy();
      dartGame.history = null as any;
      console.log('game destory');
    };
  }, [dartGame]);

  return (
    <BaseLayout
      header={<BaseHeader title='飛鏢遊戲' isLeftBtnBack />}
      body={<StoryLayout>
        {
          orientationType === OrientationType.landscape
            ? <h1 className='text-center' style={{ color: '#fff' }}>請轉直螢幕</h1>
            : <canvas ref={canvasRef} className='game-board'></canvas>
        }
      </StoryLayout>}
    />
  );
}
