import React, { FunctionComponent, useEffect } from 'react';
import { BaseHeader, BaseLayout } from '@component/layouts';
import './resources.page.scss';
import classNames from 'classnames';
import { ChipSelect, OptionComponent } from '@component/atoms';
import { getProvider } from '@/Context';
import { useArticle } from '../use-case';
import { Article, ArticleTypeOptions } from '../model';

export const ResourcePageCmp: FunctionComponent = () => {
  const { list, type, setType, search } = useArticle();

  useEffect(() => {
    if (type) {
      search({ type });
    }
  }, [type]);

  return (
    <BaseLayout
      className="social-welfare-agency-page"
      header={<BaseHeader title="社會資源" />}
      body={(
        <>
          <ChipSelect
            className="article-type-options"
            options={ArticleTypeOptions}
            optionComponent={ArticleTypeOptionCmp}
            onValueChanged={(value: string) => setType(value)}
            value={type}
          />
          <div className="text-center">
            <img className="img-response" src="/agency/line2.png" alt="" />
          </div>
          <ArtcleListCmp resource={list} />
        </>
      )}
    />
  );
};

const ArticleTypeOptionCmp: OptionComponent<string> = ({ option, className, onClick, isActive }) => (
  <li className={classNames('article-type-option', className, { active: isActive })} onClick={onClick}>
    <span>{option}</span>
  </li>
);

const ArtcleListCmp: FunctionComponent<{ resource: Article[] }> = ({ resource }: { resource: Article[] }) => {
  const { completeTask } = getProvider('taskHook');

  function completeTaskResource() {
    completeTask('taskResource');
  }

  return (
    <>
      {
        resource.map(r => <ArticleCmp key={r.link} {...r} onClick={completeTaskResource} />)
      }
    </>
  )
};

const ArticleCmp: FunctionComponent<ArticleCmpProps> = ({ link, linkType, title, onClick }: ArticleCmpProps) => (
  <div className="article-list">
    <a href={link} target="_blank" onClick={onClick}>
      <span className="article-type">{linkType}</span>
      <span className="article-title">{title}</span>
    </a>
  </div>
);

type ArticleCmpProps = Article & { onClick(): void; }
