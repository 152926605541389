import { Stroyboard } from '../story.model';
import { PREGNANCY_SLIDE } from './pregnancy.config';
import { NO_CONDOM_SLIDE } from './no-condom.config';
import { MAGIC_JOHNSON_SLIDE } from './magic-johnson.config';

export const STORIES: Stroyboard[] = [{
  storyId: 'pregnancy',
  thumbnail: '/story/pregnancy/story1_pic.png',
  title: '情牽雙條線',
  description: '高中情侶的小明和小柔，意外發\n現驗孕棒呈現雙條線！\n一夕之間，倆人從「高中生」的\n身份突然升級為「父母」。\n如果妳是小柔，妳會怎麼抉擇呢',
  link: '/story/pregnancy',
  slide: PREGNANCY_SLIDE,
  bgm: '/story/pregnancy/goodboy/bgm.mp3',
  taskName: 'taskStory1',
}, {
  storyId: 'no-condom',
  thumbnail: '/story/no-condom/story2_pic.png',
  title: '不戴套，性病靠',
  description: '我是阿賢，單身許久，終於在網\n路上認識了一位女生願意和我約\n會。我們在你情我願之下發生了\n一夜情。\n我沒戴保險套，也不夠了解\n她，我非常後悔..',
  link: '/story/no-condom',
  slide: NO_CONDOM_SLIDE,
  bgm: '/story/no-condom/bgm.mp3',
  taskName: 'taskStory2',
}, {
  storyId: 'magic-johnson',
  thumbnail: '/story/magic-johnson/story3_pic.png',
  title: '愛滋病並不是我的世界末日，而是另一個生命階段的開始',
  description: '當一名職業籃球手不再打籃球，\n當一名公眾人物染上愛滋病，\n這是一篇真人改編的故事，\n你準備好進入我的世界嗎？',
  link: '/story/magic-johnson',
  slide: MAGIC_JOHNSON_SLIDE,
  bgm: '/story/magic-johnson/bgm.mp3',
  taskName: 'taskStory3',
}];
