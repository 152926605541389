import { QuizConfig, Score, StepName, Quiz, Result, Step, Rule, Animat, AnimatName, QuizResponse } from './basketball.model';
import { CONFIG } from './config';
import { random } from '@library/random';

const b = BasketballGame();

export const useBasketballGame = () => b;

function BasketballGame() {
  console.log('useBasketballGame');
  let currentStep: Step = null as any as Step;
  let leftQuiz: QuizConfig[] = CONFIG.quiz.slice();
  let currentScore: Score = { ...CONFIG.defalutScore };
  const setCurrentScore = (s: Score) => currentScore = s;
  let quizResponse: QuizResponse = { quizId: null as any, replyAnswer: null as any };
  const setQuizResponse = (r: QuizResponse) => quizResponse = r;
  let totalScore = 0;

  function nextStep(): StepName {
    // get rule, pick quiz, get result, run animat, show total score
    const currentStepIndex = CONFIG.step.indexOf(currentStep);
    const nextStepIndex = currentStepIndex === -1 || currentStepIndex === CONFIG.step.length - 1
      ? 0
      : currentStepIndex + 1;
    currentStep = CONFIG.step[nextStepIndex];
    return currentStep.name;
  }

  function getRule(): Rule {
    return { ...CONFIG.rule, score: currentScore };
  }

  function getTotalScore(): number {
    return totalScore;
  }

  function showAnimat(): Animat {
    const isGreatThen3 = currentScore.yes >= 3;
    const stage = currentStep.stage;
    let animatName: AnimatName;
    if (stage === 1 && isGreatThen3) {
      animatName = 'shotScore';
    } else if (stage === 1 && !isGreatThen3) {
      animatName = 'shotNoScore';
    } else if (stage === 2 && isGreatThen3) {
      animatName = 'slamDunkScore';
    } else if (stage === 2 && !isGreatThen3) {
      animatName = 'slamDunkNoScore';
    }
    const animat = CONFIG.animat.find(a => a.name === animatName);
    if (!animat) { throw new Error('no animat'); }
    setCurrentScore({ ...CONFIG.defalutScore });
    return animat;
  }

  function pickQuiz(): Quiz {
    const pickedIndex = random(0, leftQuiz.length - 1);
    const pickedQuizConfig = leftQuiz[pickedIndex];
    leftQuiz = leftQuiz.filter(q => q !== pickedQuizConfig);
    return makeQuiz(pickedQuizConfig, currentScore);
  }

  function makeQuiz(quizConfig: QuizConfig, score: Score): Quiz {
    const { quizId, question, option } = quizConfig;
    return { quizId, title: '問題', description: question, option, score };
  }

  function replyQuiz({ quizId, replyAnswer }: QuizResponse) {
    setQuizResponse({ quizId, replyAnswer });
  }

  function getResult(): Result {
    const { quizId, replyAnswer } = quizResponse;
    const quizConfig = CONFIG.quiz.find(q => q.quizId === quizId);
    if (!quizConfig) { throw new Error('no quiz'); }
    const whetherToScore = quizConfig.answer === replyAnswer;
    const { yes, no } = currentScore;
    if (whetherToScore) {
      setCurrentScore({ yes: yes + 1, no });
      totalScore = totalScore + 1;
    } else {
      setCurrentScore({ yes, no: no + 1 });
    }
    return makeResult(quizConfig, whetherToScore, currentScore);
  }

  function makeResult(quizConfig: QuizConfig, whetherToScore: boolean, score: Score): Result {
    return {
      title: whetherToScore ? CONFIG.resultTitleYes : CONFIG.resultTitleNo,
      description: quizConfig.tips,
      reference: quizConfig.reference,
      whetherToScore,
      score,
    };
  }

  function restart() {
    currentStep = null as any as Step;
    leftQuiz = CONFIG.quiz.slice();
    setCurrentScore({ ...CONFIG.defalutScore });
    totalScore = 0;
  }

  return {
    get currentScore() { return currentScore; },
    nextStep,
    getRule,
    pickQuiz,
    replyQuiz,
    getResult,
    showAnimat,
    getTotalScore,
    restart,
  }
}
