import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { BaseHeader, BaseLayout } from '@component/layouts';
import { ChipSelect } from '@component/atoms';
import { Avatar, AvatarOptions, Category, CategoryOptions } from '../model';
import { useForumControl } from '../use-case';
import { SearchButton, AvatarOptionCmp } from '../component';
import './new-post.page.scss';

const NewPostPageCmp: FunctionComponent = () => {
  const { addPost } = useForumControl();
  const { register, control, handleSubmit, errors } = useForm();
  const history = useHistory();

  function goBack() {
    const isGoBack = confirm('放棄貼文?');
    if (isGoBack) {
      history.goBack();
    }
  }

  function save(data: any) {
    addPost(data)
      .then(() => {
        history.goBack();
      });
  }

  const categories = CategoryOptions
    .filter(c => c.code !== Category.all)
    .map(c => c.code);
  function toCategoryLabel(code: number): string {
    return CategoryOptions.find(c => c.code === code)!.label;
  }

  return (
    <BaseLayout
      className="new-post-page"
      header={
        <BaseHeader title="留言板" isLeftBtnBack right={<SearchButton />} />
      }
      body={
        <div className="container">
          <header>
            <p className="title">新的文章······</p>
          </header>
          <main>
            <div className="form-group">
              <label>頭像</label>
              <div className="form-field">
                <Controller
                  control={control}
                  render={({ onChange, value }) => (
                    <ChipSelect
                      className="form-control"
                      options={AvatarOptions}
                      optionComponent={AvatarOptionCmp}
                      onValueChanged={value => onChange(value)}
                      value={value}
                    />
                  )}
                  name="avatar"
                  rules={{ required: true }}
                  defaultValue={Avatar.boy1}
                />
                {errors.avatar && <p className="error-message">必填</p>}
              </div>
            </div>
            <div className="form-group">
              <label>暱稱</label>
              <div className="form-field">
                <input className="form-control" type="text" name="nickname" ref={register({ required: true })} />
                {errors.nickname && <p className="error-message">必填</p>}
              </div>
            </div>
            <div className="form-group">
              <label>年齡</label>
              <div className="form-field">
                <Controller
                  control={control}
                  render={({ onChange, value }) => (
                    <select className="form-control" value={value} onChange={event => onChange(+event.target.value)}>
                      <option value="">請選擇</option>
                      <option value="12">12</option>
                      <option value="13">13</option>
                      <option value="14">14</option>
                      <option value="15">15</option>
                      <option value="16">16</option>
                      <option value="17">17</option>
                      <option value="18">18</option>
                    </select>
                  )}
                  name="age"
                  rules={{ required: true }}
                  defaultValue={Avatar.boy1}
                />
                {errors.age && <p className="error-message">必填</p>}
              </div>
            </div>
            <div className="form-group">
              <label>標題</label>
              <div className="form-field">
                <input className="form-control" type="text" name="topic" ref={register({ required: true })} />
                {errors.topic && <p className="error-message">必填</p>}
              </div>
            </div>
            <div className="form-group">
              <label>分類</label>
              <div className="form-field">
                <Controller
                  control={control}
                  render={({ onChange, value }) => (
                    <ChipSelect
                      className="form-control"
                      options={categories}
                      onValueChanged={value => onChange(value)}
                      value={value}
                      toLabel={toCategoryLabel}
                    />
                  )}
                  name="category"
                  rules={{ required: true }}
                  defaultValue={Category.none}
                />
                {errors.category && <p className="error-message">必填</p>}
              </div>
            </div>
            <div className="form-group">
              <label>內容</label>
              <div className="form-field">
                <textarea className="form-control" name="content" ref={register({ required: true })}></textarea>
                {errors.content && <p className="error-message">必填</p>}
              </div>
            </div>
            <div className="form-group">
              <label></label>
              <div className="form-control">
                <button type="button" onClick={handleSubmit(save)}>張貼</button>
                <button type="button" onClick={goBack}>取消</button>
              </div>
            </div>
          </main>
        </div>
      }
    />
  );
};

export default NewPostPageCmp;