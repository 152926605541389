import React, { FunctionComponent, useEffect } from 'react';
import { BaseHeader, BaseLayout } from '@component/layouts';
import './social-welfare-agency.page.scss';
import { Agency, AgencyActivity } from '../model';
import { useAgencyUseCase } from '../use-case';
import classNames from 'classnames';

export const SocialWelfareAgencyPageCmp: FunctionComponent = () => {
  const {
    agency, storeAgency,
    storeAgencyActivity,
    service, setService,
    area, setArea,
    category, setCategory,
    serviceOptions, areaOptions, categoryOptions,
    isService, isArea, isCategory,
    getAllAgency,
    searchAgency,
    clearAgency,
    getAllActivity,
  } = useAgencyUseCase(process.env.REACT_APP_ONTHEGO_API as string);

  useEffect(() => {
    getAllAgency();
    getAllActivity();
    return () => {
    };
  }, []);

  useEffect(() => {
    if (service === serviceOptions[0]) {
      searchAgency({ area, category });
    } else {
      clearAgency();
    }
  }, [service, area, category, storeAgency]);

  return (
    <BaseLayout
      className="social-welfare-agency-page"
      header={<BaseHeader title="社福機構" />}
      body={(
        <>
          <div className="text-center"><img className="img-response" src="/agency/service_title.png" alt="" /></div>
          <div className="btn-img-group">
            <div className="btn-img" onClick={() => setService(serviceOptions[0])}>
              {
                isService(serviceOptions[0], service!)
                  ? <img className="img-response" src="/agency/contact_after.png" alt="" />
                  : <img className="img-response" src="/agency/contact.png" alt="" />
              }
            </div>
            <div className="btn-img" onClick={() => setService(serviceOptions[1])}>
              {
                isService(serviceOptions[1], service!)
                  ? <img className="img-response" src="/agency/news_after.png" alt="" />
                  : <img className="img-response" src="/agency/news.png" alt="" />
              }
            </div>
          </div>
          {
            isService(serviceOptions[0], service!)
            && <>
              <div className="text-center"><img className="img-response" src="/agency/area_title.png" alt="" /></div>
              <div className="btn-img-group">
                <div className="btn-img" onClick={() => setArea(areaOptions[0])}>
                  {
                    isArea(areaOptions[0], area!)
                      ? <img className="img-response" src="/agency/hualien_after.png" alt="" />
                      : <img className="img-response" src="/agency/hualien.png" alt="" />
                  }
                </div>
                <div className="btn-img" onClick={() => setArea(areaOptions[1])}>
                  {
                    isArea(areaOptions[1], area!)
                      ? <img className="img-response" src="/agency/taitung_after.png" alt="" />
                      : <img className="img-response" src="/agency/taitung.png" alt="" />
                  }
                </div>
              </div>
              {
                area
                && <>
                  <div className="text-center"><img className="img-response" src="/agency/resource_category_title.png" alt="" /></div>
                  <div className="btn-img-group">
                    <div
                      className={classNames('btn-img', { active: isCategory(categoryOptions[0], category!) })}
                      onClick={() => setCategory(categoryOptions[0])}
                    >
                      <img className="img-response" src="/agency/search_button.png" alt="" />
                    </div>
                    <div
                      className={classNames('btn-img', { active: isCategory(categoryOptions[1], category!) })}
                      onClick={() => setCategory(categoryOptions[1])}
                    >
                      <img className="img-response" src="/agency/financial_support_button.png" alt="" />
                    </div>
                    <div
                      className={classNames('btn-img', { active: isCategory(categoryOptions[2], category!) })}
                      onClick={() => setCategory(categoryOptions[2])}
                    >
                      <img className="img-response" src="/agency/social_button.png" alt="" />
                    </div>
                    <div
                      className={classNames('btn-img', { active: isCategory(categoryOptions[3], category!) })}
                      onClick={() => setCategory(categoryOptions[3])}
                    >
                      <img className="img-response" src="/agency/counseling_button.png" alt="" />
                    </div>
                  </div>
                  <div className="text-center"><img className="img-response" src="/agency/special_needs_title.png" alt="" /></div>
                  <div className="btn-img-group">
                    <div
                      className={classNames('btn-img', { active: isCategory(categoryOptions[4], category!) })}
                      onClick={() => setCategory(categoryOptions[4])}
                    >
                      <img className="img-response" src="/agency/children_protection_button.png" alt="" />
                    </div>
                    <div
                      className={classNames('btn-img', { active: isCategory(categoryOptions[5], category!) })}
                      onClick={() => setCategory(categoryOptions[5])}
                    >
                      <img className="img-response" src="/agency/pregancy_button.png" alt="" />
                    </div>
                    <div
                      className={classNames('btn-img', { active: isCategory(categoryOptions[6], category!) })}
                      onClick={() => setCategory(categoryOptions[6])}
                    >
                      <img className="img-response" src="/agency/adoption_button.png" alt="" />
                    </div>
                  </div>
                </>
              }
              <AgencyCardList source={agency} />
            </>
          }
          {
            isService(serviceOptions[1], service!)
            && <>
              <div className="text-center"><img className="img-response" src="/agency/news_title.png" alt="" /></div>
              <AgencyActivityCardList source={storeAgencyActivity} />
            </>
          }
        </>
      )}
    />
  );
};

const AgencyCardList: FunctionComponent<{ source: Agency[] }> = ({ source }: { source: Agency[] }) => (
  <ul className="agency-card-list">
    {
      source.map((agency, i) => (
        <li className="agency-card" key={i}>
          {
            !!i && <div className="text-center"><img className="img-response" src="/agency/line2.png" alt="" /></div>
          }
          <h3>{agency.organization}</h3>
          {
            agency.introduction
            && <p className="p-des">{agency.introduction}</p>
          }
          <div className="agency-link-container">
            <ul className="agency-attribute-container">
              {
                agency.address
                && <li className="agency-attribute">
                  {
                    agency.googleMap
                      ? <a href={agency.googleMap} target="_blank" rel="noreferrer noopener">
                        <img className="list-icon" src="/agency/place-black-18dp.svg" alt="" />
                        <span>{agency.address}</span>
                      </a>
                      : agency.address
                  }
                </li>
              }
              {
                agency.phoneNo
                && <li className="agency-attribute">
                  <a href={'tel:' + agency.phoneNo}>
                    <img className="list-icon" src="/agency/local_phone-black-18dp.svg" alt="" />
                  </a>
                  <span>{agency.phoneNo}</span>
                </li>
              }
              {
                agency.facebook
                && <li className="agency-attribute">
                  <a href={agency.facebook} target="_blank" rel="noreferrer noopener">
                    <img className="list-icon" src="/agency/facebook-black-18dp.svg" alt="" />
                    <span>臉書連結</span>
                  </a>
                </li>
              }
              {
                agency.website
                && <li className="agency-attribute">
                  <a className="list-icon" href={agency.website} target="_blank" rel="noreferrer noopener">
                    <img className="list-icon" src="/agency/language-black-18dp.svg" alt="" />
                    <span>網站連結</span>
                  </a>
                </li>
              }
            </ul>
          </div>
        </li>
      ))
    }
  </ul>
);

const AgencyActivityCardList: FunctionComponent<{ source: AgencyActivity[] }> = ({ source }: { source: AgencyActivity[] }) => (
  <div className="activity-card-list">
    {
      source
        .map(agencyActivity => agencyActivity.activityList.map(activity => activity))
        .flat()
        .map((activity, i) => (
          <a className="activity-card" href={activity.link} target="_blank" rel="noreferrer noopener" key={i}>
            <h3>{activity.topic}</h3>
            <p className="p-date">{activity.date}</p>
          </a>
        ))
    }
  </div>
);
