import React from 'react';
import { ModalControl, Modal } from '@component/atoms';
import { ImgModalData } from '../basketball.model';

export const StartModal = ({
  modal,
}: {
  modal: ModalControl<ImgModalData>;
}) => {
  const { inputData, close } = modal;
  return (
    <Modal visible={modal.visible}>
      <div className="start-container">
        <img className="img-response" src={inputData.bgSrc} alt="" />
        <footer>
          {
            inputData.btn?.map((b, i) => (
              <div
                key={i}
                className="btn-img"
                onClick={() => close(b.onClose)}
                style={{ backgroundImage: `url('${b.src}')` }}
              />
            ))
          }
        </footer>
      </div>
    </Modal>
  );
};
