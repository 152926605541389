import React, { createContext, FunctionComponent, useContext } from 'react';
import { TaskHook, useTaskHook } from './task';

interface ContextValue {
  taskHook: TaskHook
}

type ProviderName = keyof ContextValue;

const Context = createContext<ContextValue>({ taskHook: {} as TaskHook });

export function getProvider(name: ProviderName): ContextValue[ProviderName] {
  return useContext(Context)[name];
}

export const ContextProvider: FunctionComponent = ({ children }) => {
  return (
    <Context.Provider value={{ taskHook: useTaskHook() }}>
      {children}
    </Context.Provider>
  );
};
