import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthService as authService } from '@/auth';
import { TaskHook, DayStatus, TaskAndRouteMapping, Score, calcDayStatus, calcLatestScore, Complete, TaskName } from '../model';
import { ScoreApiService } from '../repository';
import { copy } from '@library/clipboard';

export function useTaskHook(): TaskHook {
  const apiService = new ScoreApiService(process.env.REACT_APP_ONTHEGO_API as string);
  const [isComplete, setComplete] = useState(false);
  const [isCollapse, setCollapse] = useState(true);
  const [latestScore, setLatestScore] = useState<Score>({} as Score);
  const [latestDay, setLatestDay] = useState<number>(1);
  const [dayStatus, setDayStatus] = useState<DayStatus>(DayStatus.start);
  const [invitationCount, setInvitationCount] = useState<number>(0);
  const [myInvitationCode, setMyInvitationCode] = useState<string>('');
  const history = useHistory();

  function getScore() {
    apiService.getScore()
      .then(data => {
        const latestScore = calcLatestScore(data);
        setLatestScore(latestScore);
        setLatestDay(data.length);
        setDayStatus(calcDayStatus(latestScore));
      }, console.error);
  }

  function getInvitationCount() {
    apiService.getInvitationCount()
      .then(count => {
        setInvitationCount(count);
        setMyInvitationCode(authService.getMyUserId());
      }, console.error);
  }

  function invite() {
    const invaitationLink = history.createHref({
      pathname: '/sign-up',
      search: `inviter=${myInvitationCode}`
    });
    const externalInvaitationLink = `${location.origin}/${invaitationLink}`;
    copy(externalInvaitationLink)
      .then(() => alert('已複製'))
      .catch(error => console.error(error));
  }

  function markAllComplete() {
    setComplete(true);
  }

  function toggleCollapse() {
    setCollapse(!isCollapse);
  }

  function goTask(taskName: TaskName) {
    history.push(TaskAndRouteMapping[taskName]);
  }

  function completeTask(taskName: TaskName) {
    apiService.completeTask(taskName)
      .then(() => {
        setLatestScore({ ...latestScore, [taskName]: Complete.done })
      }, console.error);
  }

  function watering() {
    setDayStatus(DayStatus.watering);
    apiService.watering()
      .then(() => {
        setLatestScore({ ...latestScore, watering: Complete.done });
        setDayStatus(DayStatus.end);
      }, error => {
        setDayStatus(DayStatus.start);
        console.error(error);
      });
  }

  return {
    isComplete,
    isCollapse,
    score: latestScore,
    day: latestDay,
    dayStatus,
    invitationCount,
    myInvitationCode,
    invite,
    markAllComplete,
    getScore,
    getInvitationCount,
    completeTask,
    toggleCollapse,
    goTask,
    watering,
  };
}
