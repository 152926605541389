import React, { createElement, FunctionComponent } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { RouteConfig } from './model';

export const ARoute: FunctionComponent<RouteConfig> = ({
  path,
  component,
  exact,
  canActivate = { canActivate: () => true },
  redirectOnInactive = { to: '/' },
}) => {
  return canActivate.canActivate()
    ? (
      <Route
        exact={exact}
        path={path}
        aliax
        children={createElement(component)}
      />
    )
    : (
      <Redirect {...redirectOnInactive} />
    );
}
