import React from 'react';
import { Score } from "../basketball.model";

export const ScoreBord = ({ score = { yes: 0, no: 0 } }: { score?: Score} ) => (
  <div className="score-board">
    <div className="symbol"><img className="img-response" src="/game/basketball/symbol_score_yes.png" alt="" /></div>
    <div className="symbol"><img className="img-response" src="/game/basketball/symbol_score_no.png" alt="" /></div>
    <div className="score">{score.yes}</div>
    <div className="score">{score.no}</div>
  </div>
);
