export const SceneName = {
  PreloadScene: 'PreloadScene',
  PregameScene: 'PregameScene',
  IntroScene: 'IntroScene',
  ThrowDartsScene: 'ThrowDartsScene',
  ResultScene: 'ResultScene',
  EndgameScene: 'EndgameScene',
}

export const TextureName = {
  back_intro: 'back_intro.png',
  blue_dart: 'blue_dart.png',
  boy_button: 'boy_button.png',
  boy_button_after: 'boy_button_after.png',
  boy_game_board: 'boy_game_board.png',
  boy_result1: 'boy_result1.png',
  boy_result2: 'boy_result2.png',
  boy_result3: 'boy_result3.png',
  boy_score0: 'boy_score0.png',
  boy_score1: 'boy_score1.png',
  boy_score2: 'boy_score2.png',
  boy_score3: 'boy_score3.png',
  end_game: 'end_game.png',
  game_over: 'game_over.png',
  girl_button: 'girl_button.png',
  girl_button_after: 'girl_button_after.png',
  girl_game_board: 'girl_game_board.png',
  girl_result1: 'girl_result1.png',
  girl_result2: 'girl_result2.png',
  girl_result3: 'girl_result3.png',
  girl_result4: 'girl_result4.png',
  girl_result5: 'girl_result5.png',
  girl_score0: 'girl_score0.png',
  girl_score1: 'girl_score1.png',
  girl_score2: 'girl_score2.png',
  girl_score3: 'girl_score3.png',
  girl_score4: 'girl_score4.png',
  girl_score5: 'girl_score5.png',
  green_dart: 'green_dart.png',
  indicator: 'indicator.png',
  intro_game: 'intro_game.png',
  pre_start_game: 'pre_start_game.png',
  red_dart: 'red_dart.png',
  strength_bar: 'strength_bar.png',
  understood: 'understood.png',
};

export const Gender = {
  boy: 'boy',
  girl: 'girl',
};

export interface GameScoreRule {
  scoreTexture: string;
  resultTextureName: string;
  gender: string;
  requiredScore: number;
}

export interface ScoreChange {
  before: number;
  after: number;
}

export const ScoreRules: GameScoreRule[] = [{
  scoreTexture: TextureName.boy_score0,
  resultTextureName: '',
  gender: Gender.boy,
  requiredScore: 0
}, {
  scoreTexture: TextureName.boy_score1,
  resultTextureName: TextureName.boy_result1,
  gender: Gender.boy,
  requiredScore: 80
}, {
  scoreTexture: TextureName.boy_score2,
  resultTextureName: TextureName.boy_result2,
  gender: Gender.boy,
  requiredScore: 160
}, {
  scoreTexture: TextureName.boy_score3,
  resultTextureName: TextureName.boy_result3,
  gender: Gender.boy,
  requiredScore: 250
}, {
  scoreTexture: TextureName.girl_score0,
  resultTextureName: '',
  gender: Gender.girl,
  requiredScore: 0
}, {
  scoreTexture: TextureName.girl_score1,
  resultTextureName: TextureName.girl_result1,
  gender: Gender.girl,
  requiredScore: 60
}, {
  scoreTexture: TextureName.girl_score2,
  resultTextureName: TextureName.girl_result2,
  gender: Gender.girl,
  requiredScore: 120
}, {
  scoreTexture: TextureName.girl_score3,
  resultTextureName: TextureName.girl_result3,
  gender: Gender.girl,
  requiredScore: 180
}, {
  scoreTexture: TextureName.girl_score4,
  resultTextureName: TextureName.girl_result4,
  gender: Gender.girl,
  requiredScore: 240
}, {
  scoreTexture: TextureName.girl_score5,
  resultTextureName: TextureName.girl_result5,
  gender: Gender.girl,
  requiredScore: 300
}].reverse();

export const ThrowStateName = {
  start: 'start',
  chosingAngle: 'chosingAngle',
  chosingStrength: 'chosingStrength',
  dartFlying: 'dartFlying',
  scoring: 'scoring',
  result: 'result',
  end: 'end',
};

export const TargetBoardScore: Record<string, number[]> = {
  ellipse_0_50: [205, 234, 32, 30],
  ellipse_1_40: [204.5, 235, 67.5, 65],
  polygon_0_30: [124, 123, 151.5, 107.5, 196, 97, 234, 99, 271.5, 117, 237, 178.5, 164.5, 183.5],
  polygon_1_30: [171.5, 290.5, 244, 286.5, 285.5, 348.5, 252, 367, 211.5, 373, 170, 367.5, 133, 352],
  polygon_2_20: [124, 123.5, 164.5, 184, 138.5, 235, 69, 235, 74.5, 199.5, 87, 172, 103.5, 145.5],
  polygon_3_20: [272, 235, 342.5, 235, 339, 266.5, 327, 297, 312, 323.5, 285.5, 349, 243.5, 286.5],
  polygon_4_10: [271.5, 117, 301.5, 141.5, 324.5, 169.5, 340, 201, 342.5, 235, 271.5, 235, 237, 179],
  polygon_5_10: [69, 235, 138, 235, 171.5, 290.5, 133, 352, 107.5, 332, 83.5, 300, 70.5, 267, 69, 235],
};
