import { Scene, NodeLifecircle, Game, SpriteStorage, DisplayNode } from '@library/game';
import { SceneName, TextureName, Gender } from './config';
import { ThrowDart } from './useThrowDarts';

export class PregameScene extends Scene implements NodeLifecircle {
  constructor(
    private game: Game,
    private spriteStorage: SpriteStorage,
    private useCase: ThrowDart,
  ) {
    super();
  }

  onInit() {
    console.log('onInit pregameScene');
    this.useCase.reset();
  }

  onDestory() {
    console.log('onDestory pregameScene');
  }

  render() {
    const self = this;
    return [
      DisplayNode.of({
        object: this.spriteStorage.get(TextureName.pre_start_game),
      }),
      DisplayNode.of({
        object: this.spriteStorage.get(TextureName.boy_button),
        decoration: {
          position: { x: 226, y: 106 },
          interactive: true,
          buttonMode: true,
        },
        onPointertap: () => {
          self.useCase.setGender(Gender.boy);
          self.game.navigate(SceneName.IntroScene);
        }
      }),
      DisplayNode.of({
        object: this.spriteStorage.get(TextureName.girl_button),
        decoration: {
          position: { x: 23, y: 106 },
          interactive: true,
          buttonMode: true,
        },
        onPointertap: () => {
          self.useCase.setGender(Gender.girl);
          self.game.navigate(SceneName.IntroScene);
        }
      }),
    ];
  }
}
