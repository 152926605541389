import { Stroyboard, StroySlideParams } from './story.model';
import { Slide } from '@library/slide-board';

export class StoryService {
  constructor(private config: Stroyboard[]) {}

  async getOneSlide(params: StroySlideParams): Promise<Slide> {
    const story = this.config.find(c => c.storyId === params.storyId);
    const slide = story?.slide!.find(c => c.id === params.slideId)
      || story?.slide![0];
    if (!slide) {
      throw new Error(`Not found story "${params.storyId}" slide "${params.slideId}".`)
    }
    return slide as Slide;
  }

  async getStory(): Promise<Stroyboard[]> {
    return this.config;
  }

  async getStoryById(storyId: string): Promise<Stroyboard> {
    const story = this.config.find(c => c.storyId === storyId);
    return story!;
  }
};
