import React, { FunctionComponent, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { BaseHeader, BaseLayout, StoryLayout } from '@component/layouts';
import { useModal } from '@component/atoms/Modal';
import { Quiz, Result, Rule, StepName, QuizResponse, AnimatName, ImgModalData } from './basketball.model';
import { useBasketballGame } from './basketball-quiz.service';
import { RuleModal, QuizModal, ResultModal, AnimationModal, OverModal, StartModal } from './compose';
import './basketball.scss';
import { getProvider } from '@/Context';

export const BasketballPageCmp: FunctionComponent = () => {
  const { completeTask } = getProvider('taskHook');
  const history = useHistory();
  const gameSev = useBasketballGame();
  const quizModal = useModal<Quiz>({});
  const resultModal = useModal<Result>({});
  const ruleModal = useModal<Rule>({});
  const animationModal = useModal<{ animatName: AnimatName }>({ animatName: '' as any });
  const startModal = useModal<ImgModalData>({
    bgSrc: '/game/basketball/bg_game_start.png',
    btn: [{
      src: '/game/basketball/btn_start.png',
      onClose: onStartClose
    }]
  });
  const overModal = useModal<ImgModalData>({
    bgSrc: '/game/basketball/bg_game_over.png',
    message: '得分: ',
    btn: [{
      src: '/game/basketball/btn_restart.png',
      onClose: restart
    }, {
      src: '/game/basketball/btn_over.png',
      onClose: over
    }],
  });

  useEffect(() => {
    restart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function executeNextStep() {
    const stepName = gameSev.nextStep();
    const stepStrategy: Record<StepName, () => any> = {
      showStart,
      getRule,
      pickQuiz,
      getResult,
      showAnimat,
      showGameOver,
    };
    stepStrategy[stepName]();
  }

  function showStart() {
    startModal.open();
  }
  function onStartClose() {
    executeNextStep();
  }

  function getRule() {
    const rule = gameSev.getRule();
    ruleModal.open(rule);
  }
  function onCloseRule() {
    executeNextStep();
  }

  function pickQuiz() {
    const quiz = gameSev.pickQuiz();
    quizModal.open(quiz);
  }
  function onQuizClose(response: QuizResponse) {
    gameSev.replyQuiz(response);
    executeNextStep();
  }

  function getResult() {
    const result = gameSev.getResult();
    resultModal.open(result);
  }
  function onResultClose() {
    executeNextStep();
  }

  function showAnimat() {
    const animat = gameSev.showAnimat();
    animationModal.open({ animatName: animat.name });
  }
  function onAnimatClose() {
    executeNextStep();
  }

  function showGameOver() {
    overModal.open();
    completeTask('taskBasketballGame');
  }
  function restart() {
    gameSev.restart();
    executeNextStep();
  }
  function over() {
    history.goBack();
  }

  return (
    <BaseLayout
      header={<BaseHeader title="籃球遊戲" isLeftBtnBack />}
      body={<StoryLayout>
        <StartModal modal={startModal} />
        <OverModal modal={overModal} />
        <RuleModal modal={ruleModal} onClose={onCloseRule} />
        <QuizModal
          modal={quizModal}
          onNo={() => onQuizClose({ quizId: quizModal.inputData.quizId as string, replyAnswer: 'no' })}
          onYes={() => onQuizClose({ quizId: quizModal.inputData.quizId as string, replyAnswer: 'yes' })}
        />
        <ResultModal modal={resultModal} onClose={onResultClose} />
        <AnimationModal modal={animationModal} onClose={onAnimatClose} />
        {/* <div className="action shot-no-score" /> */}
      </StoryLayout>}
    />
  );
};