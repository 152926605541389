import { TaskName, CompleteTaskFn } from '@/task';

export enum RelatedHref {
  previous,
  next,
  first,
  last,
  close,
}

export interface SlideApp {
  destory(): void;
}

export interface SlideAppParams {
  view: HTMLCanvasElement;
  bgmSrc?: string;
  onProgress?(progress: number): void;
  slide: Slide[],
  size: Size;
  goBack(): void;
  taskName: TaskName;
  completeTask: CompleteTaskFn;
}

export interface Slide {
  id: string;
  bg: string;
  btn: Btn[];
}

export interface Btn {
  href: string | RelatedHref;
  shap?: Shap;
}

export interface Shap {
  w: number;
  h: number;
  x: number;
  y: number;
}

export interface Size {
  width: number;
  height: number;
}
