export interface StateParams {
  name?: string,
  onEnter?(): void;
  onLeave?(): void;
  canNext?(state?: State): boolean;
  next?(state?: State): State;
}

export class State {
  static of(params: StateParams): State {
    return new State(params);
  }

  name: string = '';

  private customNextFn!: (state?: State) => State;

  constructor({ next, ...params }: StateParams) {
    if (next) {
      this.customNextFn = next;
    }
    Object.assign(this, params);
  }

  onEnter() { }
  onLeave() { }

  defaultNext(): State { return null!; }

  canNext(state?: State) {
    return !!state;
  }

  next(state?: State): State {
    const nextState = this.customNextFn
      ? this.customNextFn(state)
      : state;
    const canNext = this.canNext(nextState);
    if (nextState && canNext) {
      this.onLeave();
      nextState.onEnter();
      return nextState;
    } else {
      return this;
    }
  }
}
