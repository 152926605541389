import React, { FunctionComponent, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { BaseHeader, BaseLayout } from '@component/layouts';
import './home.scss';
import { TaskBoard, is5DaysComplete } from '@/task';
import { getProvider } from '@/Context';

export const HomePageCmp: FunctionComponent = () => {
  const taskHook = getProvider('taskHook');

  useEffect(() => {
    if (is5DaysComplete(taskHook.day, taskHook.score)) {
      taskHook.markAllComplete();
    }
  }, [taskHook.day, taskHook.score]);

  useEffect(() => {
    taskHook.getScore();
  }, []);

  return (
    <BaseLayout
      header={<BaseHeader title="幸福 On the Go" />}
      body={
        <>
          <TaskBoard hook={taskHook} />
          <div className="menu">
            <Link className="menu-item" to="/chatbot">
              <img src="/home/chatbot.png" alt="" />
              <img src="/home/chatbot_des.png" alt="" />
            </Link>
            <Link className="menu-item" to="/story">
              <img src="/home/story.png" alt="" />
              <img src="/home/story_des.png" alt="" />
            </Link>
            <Link className="menu-item" to="/game">
              <img src="/home/game.png" alt="" />
              <img src="/home/game_des.png" alt="" />
            </Link>
            <Link className="menu-item" to="/forum">
              <img src="/home/forum.png" alt="" />
              <img src="/home/forum_des.png" alt="" />
            </Link>
            <Link className="menu-item" to="/social-welfare-agency">
              <img src="/home/ngos.png" alt="" />
              <img src="/home/ngos_des.png" alt="" />
            </Link>
            <Link className="menu-item" to="/resource">
              <img src="/home/resources.png" alt="" />
            </Link>
          </div>
          <a className="link-questionnaire" href="https://forms.gle/rCGks8pJsCciFHJi8" target="_blank">
            APP 使用<br/>心得問卷
          </a>
        </>
      }
    />
  );
}