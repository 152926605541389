import React, { useState, useEffect, FunctionComponent } from 'react';
import { Stroyboard } from './story.model';
import { StoryService } from './story.service';
import { BaseHeader, BaseLayout } from '@component/layouts';
import { TopicList } from '@component/atoms';
import { STORIES } from './configs';

export const StoryListPageCmp: FunctionComponent = () => {
  const storyService = new StoryService(STORIES);
  const [list, setList] = useState<Stroyboard[]>([]);

  useEffect(() => {
    storyService.getStory()
      .then(setList)
      .catch(console.error);
    return () => {
    };
  }, [storyService]);

  return (
    <BaseLayout
      header={<BaseHeader title="情境故事" />}
      body={<TopicList list={list} />}
    />
  );
}
