import { Sprite, Polygon, Container, Graphics } from 'pixi.js';
import { Scene, NodeLifecircle, Game, SpriteStorage, DisplayNode } from '@library/game';
import { CancelChangeFn } from '@library/storage';
import { SceneName, TextureName, Gender, ThrowStateName } from './config';
import { ThrowDart, Position } from './useThrowDarts';

export class ThrowDartsScene extends Scene implements NodeLifecircle {

  private cancelThrowDartEventFns: CancelChangeFn[] = [];

  constructor(
    private game: Game,
    private spriteStorage: SpriteStorage,
    private useCase: ThrowDart,
  ) {
    super();
  }

  onInit() {
    console.log('onInit ThrowDartsScene');
    this.updateSceneOnThrowDartEvents();
    this.useCase.nextState();
  }

  onDestory() {
    console.log('onDestory ThrowDartsScene');
    this.cancelUpdateScene();
  }

  updateSceneOnThrowDartEvents() {
    const self = this;
    const { onScore, onAngle, onStrength, onDartPosition, onState } = this.useCase;

    this.cancelThrowDartEventFns = [
      onScore(score => self.updateScore(score)),
      onAngle(angle => self.updateAngle(angle)),
      onStrength(strength => self.updateStrength(strength)),
      onDartPosition(position => self.updateDartPosition(position)),
      onState(stateName => self.navigateToResultSceneOnResultState(stateName)),
    ];
  }

  cancelUpdateScene() {
    this.cancelThrowDartEventFns
      .forEach(cancelChangeFn => cancelChangeFn());
  }

  updateScore(score: number) {
    const scoreNode = this.children[1] as DisplayNode<Sprite>;
    const scoreRule = this.useCase.getScoreRule();
    scoreNode.decoration = {
      ...scoreNode.decoration,
      texture: this.spriteStorage.getTexture(scoreRule.scoreTexture),
    };
  }

  updateAngle(angle: number) {
    const angleNode = this.children[3];
    angleNode.decoration = {
      ...angleNode.decoration,
      rotation: angle * (Math.PI / 180)
    };
  }

  updateStrength(strength: number) {
    const strengthNode = this.children[2];
    strengthNode.object.mask = new Graphics()
      .beginFill(0xffffff)
      .drawRect(33, 509, strength * 3.78, 147)
      .endFill();
  }

  updateDartPosition(position: Position) {
    const dartNode = this.children[3];
    dartNode.decoration = { ...position };
  }

  navigateToResultSceneOnResultState(stateName: string) {
    if (stateName === ThrowStateName.result) {
      this.game.navigate(SceneName.ResultScene);
    }
  }

  private getGameBoardTextureName(): string {
    if (this.useCase.isGender(Gender.boy)) {
      return TextureName.boy_game_board;
    }
    if (this.useCase.isGender(Gender.girl)) {
      return TextureName.girl_game_board;
    }
    return '';
  }

  render() {
    const self = this;
    return [
      DisplayNode.of({
        object: this.spriteStorage.get(this.getGameBoardTextureName()),
        decoration: {
          interactive: true,
          buttonMode: true,
        },
        onPointertap() {
          self.useCase.nextState();
        }
      }),
      DisplayNode.of({
        object: new Sprite(),
      }),
      DisplayNode.of({
        object: this.spriteStorage.get(TextureName.strength_bar),
        decoration: {
          x: 33,
          y: 509,
        },
      }),
      DisplayNode.of({
        object: this.spriteStorage.get(TextureName.blue_dart),
        decoration: {
          anchor: { x: 0.5, y: 1 },
        },
      }),
    ];
  }
}
