import { Rule, QuizConfig, Score, Step, StepName, Animat } from './basketball.model';

export const CONFIG: {
  step: Step[];
  rule: Rule;
  quiz: QuizConfig[];
  defalutScore: Score;
  resultTitleYes: string;
  resultTitleNo: string;
  animat: Animat[];
} = {
  step: [
    { name: StepName.showStart, stage: 0, },
    { name: StepName.getRule, stage: 0, },
    { name: StepName.pickQuiz, stage: 1, },
    { name: StepName.getResult, stage: 1, },
    { name: StepName.pickQuiz, stage: 1, },
    { name: StepName.getResult, stage: 1, },
    { name: StepName.pickQuiz, stage: 1, },
    { name: StepName.getResult, stage: 1, },
    { name: StepName.pickQuiz, stage: 1, },
    { name: StepName.getResult, stage: 1, },
    { name: StepName.showAnimat, stage: 1, },
    { name: StepName.pickQuiz, stage: 2, },
    { name: StepName.getResult, stage: 2, },
    { name: StepName.pickQuiz, stage: 2, },
    { name: StepName.getResult, stage: 2, },
    { name: StepName.pickQuiz, stage: 2, },
    { name: StepName.getResult, stage: 2, },
    { name: StepName.pickQuiz, stage: 2, },
    { name: StepName.getResult, stage: 2, },
    { name: StepName.showAnimat, stage: 2, },
    { name: StepName.showGameOver, stage: 0, },
  ],
  animat: [
    { name: 'shotScore', frame: [] },
    { name: 'shotNoScore', frame: [] },
    { name: 'slamDunkScore', frame: [] },
    { name: 'slamDunkNoScore', frame: [] },
  ],
  rule: {
    title: '遊戲規則',
    description: `歡迎來到籃球遊戲！\n安迪快要參加比賽了，\n所以他要認真練球！\n請試著回答關於性病知識的問題，\n只要答對3題以上，安迪就會進球，\n如果錯太多的話就會失敗喔！\n快來幫助他吧!`,
  },
  defalutScore: { yes: 0, no: 0 },
  resultTitleYes: '恭喜你答對了 ^_^',
  resultTitleNo: '好可惜噢，你答錯了:(',
  quiz: [
    {
      quizId: '1',
      question: '菜花、疱疹、愛滋病等其他性病，有特效藥(100%痊癒的藥)可供治療。',
      option: ['yes', 'no'],
      answer: 'no',
      tips: '解釋：梅毒、淋病是細菌性的性病，常使用盤尼西林治療，菜花、疱疹、愛滋病等，目前沒有特效藥。',
      reference: {
        label: '資料來源：國建署性福e學園',
        link: 'https://young.hpa.gov.tw/index/yngshow.aspx?CDE=YNG201707211334413FT',
      },
    },
    {
      quizId: '2',
      question: '菜花疾病的外觀看起來像花椰菜的形狀',
      option: ['yes', 'no'],
      answer: 'yes',
      tips: '解釋：除了外觀看起來像花椰菜狀,長大後可經觸診發現、觸感較軟,顏色上可看到白色、深褐色或接近離患處周圍的膚色',
    },
    {
      quizId: '3',
      question: '生殖器疱疹為自身黏膜或皮膚傷口與患者生殖器上的疱疹接觸傳染的。',
      option: ['yes', 'no'],
      answer: 'yes',
      tips: '解釋：生殖器疱疹(疱疹二型)是黏膜或皮膚傷口與患者生殖器上的疱疹接觸而傳染，最普遍的傳播行為是性交。',
      reference: {
        label: '資料來源： 國建署性福e學園',
        link: 'https://young.hpa.gov.tw/index/yngshow.aspx?CDE=YNG20170721133111A17',
      },
    },
    {
      quizId: '4',
      question: '被梅毒病患抓傷手臂，會感染嗎？',
      option: ['yes', 'no'],
      answer: 'no',
      tips: '解釋：梅毒的傳染方式主要是性交、與感染者的黏膜滲出物接觸、血液感染',
    },
    {
      quizId: '5',
      question: '受披衣菌感染的孕婦，有很大的機率在生小孩時，把披衣菌經產道傳染給嬰兒',
      option: ['yes', 'no'],
      answer: 'yes',
      tips: '解釋：在性行為時精液、陰道分泌物或血液都可能攜帶披衣菌，若接觸到這些體液可能會被感染,所以孕婦生產時有可能會垂直傳染給嬰兒。',
    },
    {
      quizId: '6',
      question: '目前為止沒有任何抗病毒藥物可治療菜花，如要清除病毒唯有靠自身免疫能力。',
      option: ['yes', 'no'],
      answer: 'yes',
      tips: '解釋：到目前為止並沒有任何抗病毒藥物可用以治療菜花，要清除病毒唯有靠自身免疫能力。',
      reference: {
        label: '資料來源：國建署性福e學園',
        link: 'https://young.hpa.gov.tw/index/yngshow.aspx?CDE=YNG201707211330541ER&t=YTP20170704133332PSL',
      },
    },
    {
      quizId: '7',
      question: '淋病的病患，男性感染的症狀比起女性比較不明顯，診斷也比較困難。',
      option: ['yes', 'no'],
      answer: 'no',
      tips: '解釋：淋病的患者，是女性感染的症狀比起男性更不明顯，所以診斷會比較困難喔。',
      reference: {
        label: '資料來源：衛生福利部疾病管制屬',
        link: 'http://cdc.gov.tw/Disease/SubIndex/nWvBNnt9UvaZzdrzbQcfBA',
      }
    },
    {
      quizId: '8',
      question: '受淋病感染的女性，沒有及時接受治療，可能會造成不孕。',
      option: ['yes', 'no'],
      answer: 'yes',
      tips: '解釋：淋病主要影響生殖系統，如果沒有及時接受治療，是可能會造成不孕的喔。',
      reference: { label: '資料來源：衛生福利部疾病管制屬', link: 'http://cdc.gov.tw/Disease/SubIndex/nWvBNnt9UvaZzdrzbQcfBA', }
    },
    {
      quizId: '9',
      question: '梅毒可能會往淋巴結、骨頭肌肉或心血管等器官感染  ',
      option: ['yes', 'no'],
      answer: 'yes',
      tips: '解釋：梅毒的症狀還有感染初期會在陰莖或陰道上，產生無痛潰瘍及硬性下疳(在生殖器部位圓形規則的糜爛或淺潰瘍)喔。',
    },
    {
      quizId: '10',
      question: '唯一可以預防性病的方式就是不與性病患者發生性行為',
      option: ['yes', 'no'],
      answer: 'no',
      tips: '解釋：除了不發生性行為,還需要注意個人衛生避免間接感染,及性生活要有一個穩定且健康的性伴侶,不要一個換一個喔',
    },
    {
      quizId: '11',
      question: '受披衣菌感染時，若導致骨盆腔發炎可以用抗生素治療，而且程序簡單。',
      option: ['yes', 'no'],
      answer: 'no',
      tips: '解釋：受披衣菌感染時而導致骨盆腔發炎或不孕，則治療這類感染併發症的程序會複雜得多。',
      reference: {
        label: '資料來源： 國建署性福e學園',
        link: 'https://young.hpa.gov.tw/index/yngshow.aspx?CDE=YNG201707211333170NQ',
      }
    },
    {
      quizId: '12',
      question: '未滿14歲感染淋病是要通報父母的',
      option: ['yes', 'no'],
      answer: 'yes',
      tips: '解釋： 淋病列為第三類法定傳染病,應於一週內完成通報,為考量身心發展階段特殊性，未滿14歲者，通報後由衛生局專人與相關人員向其父母告知。'
    },
  ],
};