export function patch<T extends object | Array<any>>(
  source: T,
  patches: T extends any[] ? Record<number, T[0]> : Partial<T>
): T {
  return Array.isArray(source)
    ? Object.assign((source as any[]).slice(), patches) as T
    : Object.assign({}, source, patches);
}

export function formatDate(dateStr: string): string {
  return new Date(dateStr).toLocaleString('en-US', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    //   hour: 'numeric',
    //   minute: 'numeric',
    //   second: 'numeric',
    hour12: false
  });
}
