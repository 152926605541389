import { TaskName } from './Scroe';

export const TaskAndRouteMapping: Record<TaskName, string> = {
  taskLogin: '/',
  taskStory1: '/story/pregnancy',
  taskStory2: '/story/no-condom',
  taskStory3: '/story/magic-johnson',
  taskMaleGame: '/game/dart',
  taskFemaleGame: '/game/dart',
  taskCondomGame: '/game/condom',
  taskBasketballGame: '/game/basketball',
  taskChatroom: '/chatbot',
  taskForum: '/forum',
  taskResource: '/social-welfare-agency',
  taskCharityOrganization: '/social-welfare-agency',
};
