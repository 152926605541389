import React from 'react';
import { Message, EmailMessage, GeneralMessage, TypingMessage, MessageType, LinkMessage, makeMailHref } from '../model';
import classNames from 'classnames';

const GeneralText = ({ msg }: { msg: GeneralMessage }) => (
  <>
    <span>{msg.description || msg.content}</span>
    <label className="time">{datetime(msg.time)}</label>
  </>
);

const EmailLink = ({ msg }: { msg: EmailMessage }) => (
  <>
    <a href={makeMailHref(msg.mail)}>
      {msg.description || msg.content}
    </a>
    <label className="time">{datetime(msg.time)}</label>
  </>
);

const TypingText = ({ msg }: { msg: TypingMessage }) => (
  <span className="dot-typing" style={{ marginTop: '.6em', marginLeft: '.6em' }}></span>
);

const LinkText = ({ msg }: { msg: LinkMessage }) => (
  <>
    <a href={msg.link} target="_blank" rel="noopener noreferrer">
      {msg.description || msg.content}
    </a>
    <label className="time">{datetime(msg.time)}</label>
  </>
);

const datetime = (t: any) => {
  return new Date(t)
    .toLocaleTimeString('en-US', { hour12: false })
    .substr(0, 5);
};

const Component: Record<MessageType, React.StatelessComponent<{ msg: any }>> = {
  message: GeneralText,
  mail: EmailLink,
  typing: TypingText,
  link: LinkText,
};

export const Msg = ({ msg }: { msg: Message }) => (
  <div className={classNames({ msg: true, me: msg.isMine })}>
    {!msg.isMine && <img src="/chatroom/female1s.png" alt="" />}
    <p className="msg-text">
      {React.createElement(Component[msg.type] || (() => null), { msg })}
    </p>
  </div>
);
