import { AuthService } from '@/auth';
import { useModal } from '@component/atoms';
import { AboutUsModal } from '@component/modals';
import React, { FunctionComponent, ReactNode, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import './base-header.scss';

export interface BaseHeaderProps {
  title?: string;
  isLeftBtnBack?: boolean;
  right?: ReactNode;
}

export const BaseHeader = ({ title, isLeftBtnBack, right }: BaseHeaderProps) => {
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const aboutUsHook = useModal();

  function toggleVisible() {
    setVisible(!visible);
  }

  function signOut() {
    const isConfirm = confirm('確定登出?');
    if (!isConfirm) return;
    AuthService.signOut();
    history.push('/lobby');
  }

  function goBack() {
    history.goBack();
  }

  function goAboutUs() {
    aboutUsHook.open();
  }

  return (
    <div className="base-header flex align-n-c">
      {
        isLeftBtnBack
          ? (
            <div onClick={goBack}>
              <img src="/home/back.png" alt="" />
            </div>
          )
          : (
            <div className="link" onClick={toggleVisible}>
              <img src="/home/menu.png" alt="" />
            </div>
          )
      }
      <p className="align-c title" style={{ flex: '1 1 auto' }}>{title}</p>
      { right ? right : <></>}
      <Link className="link" to="/">
        <img src="/home/home.png" alt="" />
      </Link>
      <BaseMenu visible={visible} signOut={signOut} goBack={goBack} goAboutUs={goAboutUs}></BaseMenu>
      <AboutUsModal modal={aboutUsHook} />
    </div>
  )
};

interface BaseMenuProps {
  visible: boolean;
  signOut(): void;
  goBack(): void;
  goAboutUs(): void;
}

const BaseMenu: FunctionComponent<BaseMenuProps> = ({ visible, signOut, goBack, goAboutUs }) => (
  <>
    {
      visible && (
        <div className="base-menu">
          <div className="btn-link" onClick={goBack}>返回</div>
          <Link className="btn-link" to="/task">領取禮物 & 邀請碼</Link>
          <Link className="btn-link" to="/intro">功能介紹</Link>
          <div className="btn-link" onClick={goAboutUs}>關於我們</div>
          <div className="btn-link" onClick={signOut}>登出</div>
        </div>
      )
    }
  </>
);
