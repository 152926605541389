import React, { FunctionComponent, useEffect } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { BaseHeader, BaseLayout } from '@component/layouts';
import { Category, CategoryOptions } from '../model';
import { useForumControl } from '../use-case';
import { PostDetail, SearchButton } from '../component';
import './forum.page.scss';

const ForumPageCmp: FunctionComponent = () => {
  const { category, isCategory, posts, setCategory, getPosts } = useForumControl();

  useEffect(() => {
    getPosts({ category });
  }, [category]);

  return (
    <BaseLayout
      className="forum-page"
      header={
        <BaseHeader title="留言板" right={<SearchButton />} />
      }
      body={
        <>
          <div className="toolbar">
            {
              CategoryOptions
                .filter(c => c.code !== Category.none)
                .map(c => (
                  <button
                    className={classNames('tab', { active: isCategory(c.code) })}
                    key={c.label}
                    onClick={() => setCategory(c.code)}
                  >
                    {c.label}
                  </button>
                ))
            }
          </div>
          {
            posts.map(post => (
              <Link key={post.postId} className="post-link" to={`/forum/post/${post.postId}`}>
                <PostDetail key={post.postId!} disableEmojiPicker {...post} />
              </Link>
            ))
          }
          <Link className="btn-add-post" to="/forum/post/new">
            <img src="/post/add_post.png" alt="" />
          </Link>
        </>
      }
    />
  );
}

export default ForumPageCmp;
