import React, { FunctionComponent, useEffect } from 'react';
import { getProvider } from '@/Context';
import { BaseHeader, BaseLayout } from '@component/layouts';
import './task.scss';

export const TaskPageCmp: FunctionComponent = () => {
  const taskHook = getProvider('taskHook');

  useEffect(() => {
    // Todo: 盤點和重構 計分 和 禮物 的獎勵系統設計，否則頁面處理太多商業邏輯
    taskHook.getInvitationCount();
  }, []);

  function invite() {
    taskHook.invite();
  }

  return (
    <BaseLayout
      className="task-page"
      header={<BaseHeader title="領取禮物 & 邀請碼" />}
      body={
        <>
          <dl>
            <dt>領取禮物</dt>
            <dt>活動截止日 2020/12/31</dt>
          </dl>
          <dl>
            <dt className="gift">Line 貼圖</dt>
            <dd className="gift">領取</dd> {/* Todo: 沒經費支撐，暫不處理 */}
            <dt>[?] 第3天任務</dt>
          </dl>
          <dl>
            <dt className="gift">711 禮卷</dt>
            <dd className="gift">領取</dd> {/* Todo: 沒經費支撐，暫不處理 */}
            <dt>[?] 第5天任務</dt>
            <dt>[?] 邀請 {taskHook.invitationCount} / 2 人</dt>
            <dd><button type="button" onClick={invite}>複製邀請連結</button></dd>
          </dl>
        </>
      }
    />
  );
};
