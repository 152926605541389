import React from 'react';
import { ModalControl, Modal } from '@component/atoms';
import { Rule, Score } from '../basketball.model';
import { ScoreBord } from './score-bord';

export const RuleModal = ({
  modal,
  onClose,
}: {
  modal: ModalControl<Rule>;
  onClose?: () => void
}) => {
  const { inputData, close } = modal;
  return (
    <Modal visible={modal.visible}>
      <div className="rule-container">
        <img className="img-response" src="/game/basketball/bg_quiz.png" alt="" />
        <ScoreBord score={inputData.score as Score} />
        <div className="rule-modal">
          <header>
            {inputData.title}
          </header>
          <main>
            <p>{inputData.description}</p>
          </main>
          <footer>
            <div
              className="btn-img"
              onClick={() => close(onClose)}
              style={{ backgroundImage: `url('/game/basketball/btn_go.png')` }}
            />
          </footer>
        </div>
      </div>
    </Modal>
  );
};
