import { useState, useEffect } from 'react';
import { patch } from '@library/object';
import {
  ForumControl,
  Post,
  GetOnePostParams,
  PostEmoji,
  EmojiCount,
  Reply,
  ReplyEmoji,
  GetPostsParams,
  GetReplyFromReplyParams,
  GetReplyFromPostParams,
  Category,
  OpenReplyModalQuery,
  makePost,
  Emoji,
  GetReplyLayerNumParams
} from '../model';
import { ForumApiService } from '../repository';
import { getProvider } from '@/Context';

export function useForumControl(): ForumControl {
  const { completeTask } = getProvider('taskHook');
  const apiService = new ForumApiService(process.env.REACT_APP_ONTHEGO_API as string);
  const [category, setCategory] = useState<number | null>(Category.all);
  const [posts, setPosts] = useState<Post[]>([]);
  const [post, setPost] = useState<Post>(makePost());
  const [replies, setReplies] = useState<Reply[]>([]);
  const [sortedReplies, setSortedReplies] = useState<Reply[]>([]);

  useEffect(() => {
    setSortedReplies(sortReplies(replies));
  }, [replies]);

  function isCategory(c: number | null): boolean {
    return category === c;
  }

  function executeCompleteTask() {
    completeTask('taskForum');
  }

  async function getPosts(params: GetPostsParams): Promise<void> {
    try {
      const data = await apiService.getPosts(params);
      setPosts(data || []);
      setCategory(params.category);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async function addPost(payload: Post): Promise<void> {
    try {
      const data = await apiService.addPost(payload);
      setPosts([...posts, data]);
      executeCompleteTask();
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async function getPost(params: GetOnePostParams): Promise<void> {
    try {
      const data = await apiService.getPost(params);
      data.emojiCounts = data.emojiCounts || [];
      setPost(data);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async function addPostEmoji({ emoji }: Pick<PostEmoji, 'emoji'>): Promise<void> {
    try {
      const { postId, emojiCounts } = post;
      const params: PostEmoji = { postId: postId!, emoji };
      await apiService.addPostEmoji(params);

      const emojiCountsNew = addEmojiCount(emojiCounts!, params);
      const postNew: Post = { ...post, emojiCounts: emojiCountsNew };
      setPost(postNew);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async function getReplies(params: GetReplyFromPostParams): Promise<void> {
    try {
      const allReplies = await getRepliesWhenHasReplies(params); // deep loop
      setReplies(allReplies || []);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async function getRepliesWhenHasReplies(reply: Reply | GetReplyFromPostParams): Promise<Reply[]> {
    const isPost = reply.postId && 'replyId' in reply === false;
    const isReplyAndHasReplies = 'replies' in reply && typeof reply.replies === 'number' && !!reply.replies;
    if (isPost || isReplyAndHasReplies) {
      const rs = await queryReplies(reply);
      const replies2D = await Promise.all(
        rs.map(r => getRepliesWhenHasReplies(r))
      );
      return rs.concat(...replies2D);
    } else {
      return [];
    }
  }

  async function queryReplies(params: GetReplyFromReplyParams | GetReplyFromPostParams): Promise<Reply[]> {
    const isFromPost = params.postId && 'replyId' in params === false;
    if (isFromPost) {
      return apiService.getReplyFromPost(params as GetReplyFromPostParams);
    }

    const isFromReply = params.postId && 'replyId' in params;
    if (isFromReply) {
      return apiService.getReplyFromReply(params as GetReplyFromReplyParams);
    }

    throw new Error(`postId is required`);
  }

  async function addReply(payload: Reply): Promise<void> {
    try {
      if (payload.postId !== post.postId) {
        throw new Error(`no postId as "${payload.postId}"`);
      }

      const isToPost = payload.postId === post.postId && !payload.replyId;
      if (isToPost) {
        const replyNew = await apiService.addReplyToPost(payload);
        const repliesNew = replies.concat(replyNew);
        setReplies(repliesNew);
        const postNew: Post = { ...post, replyCount: (post.replyCount || 0) + 1 };
        setPost(postNew);
        executeCompleteTask();
      }

      const isToReply = payload.postId === post.postId && !!payload.replyId;
      if (isToReply) {
        const replyNew = await apiService.addReplyToReply(payload);
        const repliesNew = replies.concat(replyNew);
        setReplies(repliesNew);
        const postNew: Post = { ...post, replyCount: (post.replyCount || 0) + 1 };
        setPost(postNew);
        executeCompleteTask();
      }

    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async function addReplyEmoji(replyEmoji: ReplyEmoji): Promise<void> {
    try {
      await apiService.addReplyEmoji(replyEmoji);

      const [index, reply]: [string, Reply] = Object.entries(replies)
        .find(([i, r]) => r.postId === replyEmoji.postId && r.replyId === replyEmoji.replyId)!;
      const { type, count } = reply.emojiCount || { type: Emoji.heart, count: 0 };
      const emojiCountNew: EmojiCount = { type, count: count + 1 };
      const replyNew: Reply = patch(reply, { emojiCount: emojiCountNew });
      const repliesNew: Reply[] = patch(replies, { [index]: replyNew });
      setReplies(repliesNew);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  function getReplyLayerNum(params: GetReplyLayerNumParams): number {
    const replyIndexes = makeReplyIndexes(replies);
    const datePath = makeDatePath(replyIndexes, params.replyId);
    const matches = datePath.match(/Z\./g);
    return matches ? matches.length - 1 : 0;
  }

  function sortReplies(replies: Reply[]): Reply[] {
    const replyIndexes = makeReplyIndexes(replies);
    const result = replies
      .map(r => [makeDatePath(replyIndexes, r.replyId), r.replyId])
      .sort(([aDatePath], [bDatePath]) => aDatePath > bDatePath ? -1 : 1)
      .map(([datePath, replyId]) => replyIndexes[replyId]);
    return result;
  }

  function canOpenReplyModal({ postId, replyId }: OpenReplyModalQuery): boolean {
    return postId === post.postId;
  }

  return {
    category,
    setCategory,
    isCategory,

    posts,
    getPosts,
    addPost,

    post,
    getPost,
    addPostEmoji,

    replies: sortedReplies,
    getReplies,
    addReply,
    addReplyEmoji,
    canOpenReplyModal,
    getReplyLayerNum,
  };
}

function addEmojiCount(emojiCounts: EmojiCount[] = [], { emoji: type }: Pick<PostEmoji, 'emoji'>): EmojiCount[] {
  const [index, { count }]: [string, EmojiCount] = Object.entries(emojiCounts)
    .find(([i, e]) => e.type === type)
    || [emojiCounts.length.toString(), { type, count: 0 }];
  return patch(emojiCounts, { [index]: { type, count: count + 1 } });
}

export function calcPostEmojiAmount(emojiCounts: EmojiCount[]): number {
  return emojiCounts?.reduce((amoumt, e) => amoumt + e.count, 0);
}


function makeReplyIndexes(replies: Reply[]): Record<string, Reply> {
  return replies
    .reduce((acc, r) => {
      acc[r.replyId] = r;
      return acc;
    }, {} as Record<string, Reply>);
}

function makeDatePath(replyIndexes: Record<string, Reply>, replyId: string): string {
  let reply = replyIndexes[replyId];
  let datePath = '';
  while (reply) {
    datePath = `${reply.replyDate}.${datePath}`;
    reply = replyIndexes[reply.postId];  // parent post
  }
  return datePath + 'Z';  // make parent post higher order
}
