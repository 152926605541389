import React from 'react';
import './Topic.scss';
import { Link } from 'react-router-dom';

export interface TopicProps {
  link?: string;
  thumbnail?: string;
  title?: string;
  description?: string;
}

export const Topic = ({ thumbnail, title, description }: TopicProps) => (
  <div className="topic">
    <div className="topic-thumb flex align-c-c col-s1 col-1 row-s1 row-2">
      <img src={thumbnail} alt="" />
    </div>
    <div className="flex align-s-e col-s2 row-s1">
      <h3>{title || 'Untitled'}</h3>
    </div>
    <div className="col-s2 row-s2">
      <pre>{description}</pre>
    </div>
  </div>
);

export const TopicList = ({ list }: { list: TopicProps[] }) => (
  <ul className="topic-list">
    {list.map((s, i) => (
      <Link className="topic-link" key={i} to={s.link || '/'}>
        <Topic {...s} />
      </Link>
    ))}
  </ul>
);