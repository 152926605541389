import { HttpHelper } from '@library/http-helper';
import {
  SignInReqBody,
  SignInResBody,
  SignUpReqBody,
  SignUpResBody,
  VerfyForgotPasswordReqBody,
  VerfyForgotPasswordResBody,
  ModifyPasswordReqBody,
  ModifyPasswordResBody,
  FindBackUsernameReqBody,
  FindBackUsernameResBody,
  ModifyAngelReqBody,
  ModifyAngelResBody
} from '../model';
import { AuthStorage } from './auth-storage';

export class AuthApiService {

  private authStorage = new AuthStorage();

  constructor(
    private endpoint: string
  ) {
  }

  signIn(payload: SignInReqBody): Promise<SignInResBody> {
    const url = `${this.endpoint}/acct/login`;
    return fetch(url, { method: 'POST', body: JSON.stringify(payload), headers: { 'Content-Type': 'application/json' } })
      .then(HttpHelper.handleResponse);
  }

  signUp(payload: SignUpReqBody): Promise<SignUpResBody> {
    const url = `${this.endpoint}/acct/user`;
    return fetch(url, { method: 'POST', body: JSON.stringify(payload), headers: { 'Content-Type': 'application/json' } })
      .then(HttpHelper.handleResponse);
  }

  modifyAngel(payload: ModifyAngelReqBody): Promise<ModifyAngelResBody> {
    const url = `${this.endpoint}/acct/user/angel`;
    return fetch(url, { method: 'PUT', body: JSON.stringify(payload), headers: this.makeHeader() })
      .then(HttpHelper.handleResponse);
  }

  verfyForgotPassword({ username, gender, monthOfBirth, dayOfBirth }: VerfyForgotPasswordReqBody): Promise<VerfyForgotPasswordResBody> {
    const queryString = `?username=${username}&gender=${gender}&monthOfBirth=${monthOfBirth}&dayOfBirth=${dayOfBirth}`
    const url = `${this.endpoint}/acct/user/forgot-password${queryString}`;
    return fetch(url, { headers: { 'Content-Type': 'application/json' } })
      .then(HttpHelper.handleResponse);
  }

  modifyPassword(payload: ModifyPasswordReqBody): Promise<ModifyPasswordResBody> {
    const url = `${this.endpoint}/acct/user/password`;
    return fetch(url, { method: 'PUT', body: JSON.stringify(payload), headers: { 'Content-Type': 'application/json' } })
      .then(HttpHelper.handleResponse);
  }

  findBackUsername({ email, phone }: FindBackUsernameReqBody): Promise<FindBackUsernameResBody> {
    const queryString = `?email=${email || ''}&phone=${phone || ''}`
    const url = `${this.endpoint}/acct/user/account${queryString}`;
    return fetch(url, { headers: { 'Content-Type': 'application/json' } })
      .then(HttpHelper.handleResponse);
  }

  private makeHeader(): HeadersInit {
    return { 'Content-Type': 'application/json', Authorization: this.authStorage.getToken()! };
  }

}
