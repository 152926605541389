import { useState } from 'react';
import { Agency, AgencyArea, AgencyCategory, AgencyQueryParams, AgencyService, AgencyActivity } from '../model';
import { HttpHelper } from '@library/http-helper';
import { getProvider } from '@/Context';

export function useAgencyUseCase(endpoint: string) {
  const { completeTask } = getProvider('taskHook');
  const [storeAgency, setStoreAgency] = useState<Agency[]>([]);
  const [storeAgencyActivity, setStoreAgencyActivity] = useState<AgencyActivity[]>([]);
  const [agency, setAgency] = useState<Agency[]>([]);
  const [service, setService] = useState<AgencyService>();
  const [area, setArea] = useState<AgencyArea>();
  const [category, setCategory] = useState<AgencyCategory>();

  const serviceOptions: AgencyService[] = ['聯絡社福機構', '活動新知'];
  const areaOptions: AgencyArea[] = ['花蓮', '台東'];
  const categoryOptions: AgencyCategory[] = [
    '福利諮詢',
    '經濟扶助',
    '社區關懷',
    '或許你要找人聊聊',
    '兒少與成人保護',
    '青少年懷孕',
    '收出養',
  ];

  async function getAllAgency(): Promise<Agency[]> {
    const url = `${endpoint}/agency.json`;
    const data: Agency[] = await fetch(url)
      .then(HttpHelper.handleResponse);
    setStoreAgency(data);
    completeTask('taskCharityOrganization');
    return data;
  }

  function filterAgencies(source: Agency[], params: AgencyQueryParams): Agency[] {
    const { area, category } = params;
    const hasAllCondition = !!(area && category);

    if (hasAllCondition) {
      return source
        .filter(a => {
          const isMatchCity = params.area ? a.area === params.area : true;
          const isMatchCategory = params.category ? a.category.includes(params.category) : true;
          return isMatchCity && isMatchCategory;
        });
    } else {
      return [];
    }
  }

  async function getAllActivity(): Promise<AgencyActivity[]> {
    const url = `${endpoint}/activity/all`;
    const data: AgencyActivity[] = await fetch(url)
      .then(HttpHelper.handleResponse);
    setStoreAgencyActivity(data);
    return data;
  }

  async function searchAgency(params: AgencyQueryParams): Promise<Agency[]> {
    const filteredAgencies = filterAgencies(storeAgency, params);
    setAgency(filteredAgencies);
    return filteredAgencies;
  }

  function clearAgency() {
    setAgency([]);
  }

  const isService: (o: AgencyService, v: AgencyService) => boolean = (o, v) => !!(o && v) && o === v;
  const isArea: (o: AgencyArea, v: AgencyArea) => boolean = (o, v) => !!(o && v) && o === v;
  const isCategory: (o: AgencyCategory, v: AgencyCategory) => boolean = (o, v) => !!(o && v) && o === v;

  return {
    agency,
    storeAgency,
    storeAgencyActivity,
    service, setService,
    area, setArea,
    category, setCategory,
    serviceOptions, areaOptions, categoryOptions,
    isService, isArea, isCategory,
    getAllAgency,
    searchAgency,
    clearAgency,
    getAllActivity,
  }
}
