
export interface SignInReqBody {
  username: string;
  password: string;
}
export type SignInResBody = string;

export interface SignUpReqBody {
  username: string;
  password: string;
  gender: string;
  grade: string;
  birthdate: string;
  city: string;
  email?: string;
  phone?: string;
  inviter?: string;
}
export type SignUpResBody = null;

export interface ModifyAngelReqBody {
  angel: string; // 1 2 3 4 5 6
}
export type ModifyAngelResBody = null;

export interface VerfyForgotPasswordReqBody {
  username: string;
  gender: string;
  monthOfBirth: number;
  dayOfBirth: number;
}
export type VerfyForgotPasswordResBody = null;

export interface ModifyPasswordReqBody {
  password: string;
  username: string;
  gender: string;
  birthday: string; // MM-dd
}
export type ModifyPasswordResBody = null;

export interface FindBackUsernameReqBody {
  email?: string;
  phone?: string;
}
export type FindBackUsernameResBody = string;

export const GenderOption = [
  { id: 'none', label: '請選擇', value: '' },
  { id: 'boy', label: '男', value: 'boy' },
  { id: 'girl', label: '女', value: 'girl' },
];
export const GradeOption = [
  { id: 'none', label: '請選擇', value: '' },
  { id: '7', label: '國一', value: '7' },
  { id: '8', label: '國二', value: '8' },
  { id: '9', label: '國三', value: '9' },
  { id: '10', label: '高一', value: '10' },
  { id: '11', label: '高二', value: '11' },
  { id: '12', label: '高三', value: '12' },
  { id: 'temporaryLeave', label: '休學', value: 'temporaryLeave' },
  { id: 'other', label: '其他', value: 'other' },
];
export const CityOption = [
  { id: 'none', label: '請選擇', value: '' },
  { id: '基隆市', label: '基隆市', value: '基隆市' },
  { id: '臺北市', label: '臺北市', value: '臺北市' },
  { id: '新北市', label: '新北市', value: '新北市' },
  { id: '桃園市', label: '桃園市', value: '桃園市' },
  { id: '新竹市', label: '新竹市', value: '新竹市' },
  { id: '新竹縣', label: '新竹縣', value: '新竹縣' },
  { id: '苗栗縣', label: '苗栗縣', value: '苗栗縣' },
  { id: '臺中市', label: '臺中市', value: '臺中市' },
  { id: '彰化縣', label: '彰化縣', value: '彰化縣' },
  { id: '南投縣', label: '南投縣', value: '南投縣' },
  { id: '雲林縣', label: '雲林縣', value: '雲林縣' },
  { id: '嘉義市', label: '嘉義市', value: '嘉義市' },
  { id: '嘉義縣', label: '嘉義縣', value: '嘉義縣' },
  { id: '臺南市', label: '臺南市', value: '臺南市' },
  { id: '高雄市', label: '高雄市', value: '高雄市' },
  { id: '屏東縣', label: '屏東縣', value: '屏東縣' },
  { id: '臺東縣', label: '臺東縣', value: '臺東縣' },
  { id: '花蓮縣', label: '花蓮縣', value: '花蓮縣' },
  { id: '宜蘭縣', label: '宜蘭縣', value: '宜蘭縣' },
  { id: '澎湖縣', label: '澎湖縣', value: '澎湖縣' },
  { id: '金門縣', label: '金門縣', value: '金門縣' },
  { id: '連江縣', label: '連江縣', value: '連江縣' },
];

export const emailRegExp = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
export const cellphoneRegExp = /^09\d{8}$/;
