import React, { FunctionComponent } from 'react';
import { formatDate } from '@library/object';
import { Reply, OpenReplyModalQuery, GetReplyLayerNumParams, Emoji } from '../model';

export const ReplyDetail: FunctionComponent<Reply & {
  onEmoji(): void;
  onReply(): void;
  canOpenReply(params: OpenReplyModalQuery): boolean;
  getReplyLayerNum(params: GetReplyLayerNumParams): number;
}> = ({
  postId,
  replyId,
  avatar,
  nickname,
  age,
  content,
  replyDate,
  emojiCount,
  onEmoji = () => { },
  onReply = () => { },
  canOpenReply = () => false,
  getReplyLayerNum = () => 0,
}) => (
      <div className="reply" style={{ paddingLeft: 60 * getReplyLayerNum({ replyId }) }}>
        <div className="post-head">
          <div className="avatar"><img className="img-response" src={`/post/avatar${avatar}_L.png`} alt="" /></div>
          <div className="meta">
            <div className="user-info"><span>{nickname}</span>．<span>{age}歲</span></div>
            <div className="post-info"><span>{formatDate(replyDate!)}</span></div>
          </div>
        </div>
        <div className="post-content">
          <p>{content}</p>
        </div>
        <div className="emoji-container">
          <button className="btn-emoji" onClick={onEmoji}>
            <img src={`/post/emoji${emojiCount?.type || Emoji.heart}.png`} alt="" />
          </button>
          <span>{emojiCount?.count || 0}</span>
        </div>
        {
          canOpenReply({ postId, replyId })
          && <div className="btn-reply" onClick={onReply}>回應</div>
        }
      </div>
    );
