import React, { ReactNode } from 'react';
import classNames from 'classnames';
import './base-layout.scss';

interface BaseLayoutProps {
  aside?: ReactNode;
  header?: ReactNode;
  body?: ReactNode;
  footer?: ReactNode;
  className?: string;
  disabledScroll?: boolean;
}

export const BaseLayout = ({ aside, header, body, footer, className = '', disabledScroll }: BaseLayoutProps) => (
  <div className={classNames('base-layout', className, { 'disabled-scroll': disabledScroll })}>
    <div className="base-layout-container">
      <header>
        {header ? header : null}
      </header>
      <main>
        {body ? body : null}
      </main>
      <footer>
        {footer ? footer : null}
      </footer>
    </div>
    <aside>
      {aside ? aside : null}
    </aside>
  </div>
);
