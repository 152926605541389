import { Btn, RelatedHref, Slide } from '@library/slide-board';

const FullScreenShap = { w: 414, h: 736, x: 0, y: 0 };
const FullScreenNextBtn: Btn[] = [{ href: RelatedHref.next, shap: FullScreenShap }];

export const NO_CONDOM_SLIDE: Slide[] = [
  {
    id: '01', bg: '/story/no-condom/01.jpg',
    btn: [
      FullScreenNextBtn[0],
      { href: RelatedHref.close, shap: { x: 9, y: 11, w: 54, h: 41 }, },
    ],
  },
  { id: '02', bg: '/story/no-condom/02.jpg', btn: FullScreenNextBtn, },
  {
    id: '03.1', bg: '/story/no-condom/03.1.jpg',
    btn: [
      {
        href: RelatedHref.next,
        shap: { x: 133, y: 332, w: 148, h: 44 },
      }, {
        href: RelatedHref.next,
        shap: { x: 133, y: 394, w: 148, h: 44 },
      }, {
        href: RelatedHref.next,
        shap: { x: 133, y: 456, w: 148, h: 44 },
      },
    ],
  },
  { id: '03.2', bg: '/story/no-condom/03.2.jpg', btn: FullScreenNextBtn, },
  { id: '03.3', bg: '/story/no-condom/03.3.jpg', btn: FullScreenNextBtn, },
  { id: '03.4', bg: '/story/no-condom/03.4.jpg', btn: FullScreenNextBtn, },
  { id: '03.5', bg: '/story/no-condom/03.5.jpg', btn: FullScreenNextBtn, },
  { id: '04', bg: '/story/no-condom/04.jpg', btn: FullScreenNextBtn, },
  { id: '05', bg: '/story/no-condom/05.jpg', btn: FullScreenNextBtn, },
  { id: '06', bg: '/story/no-condom/06.jpg', btn: FullScreenNextBtn, },
  { id: '07.1', bg: '/story/no-condom/07.1.jpg', btn: FullScreenNextBtn, },
  { id: '07.2', bg: '/story/no-condom/07.2.jpg', btn: FullScreenNextBtn, },
  { id: '07.3', bg: '/story/no-condom/07.3.jpg', btn: FullScreenNextBtn, },
  { id: '07.4', bg: '/story/no-condom/07.4.jpg', btn: FullScreenNextBtn, },
  { id: '08.1', bg: '/story/no-condom/08.1.jpg', btn: FullScreenNextBtn, },
  { id: '08.2', bg: '/story/no-condom/08.2.jpg', btn: FullScreenNextBtn, },
  { id: '08', bg: '/story/no-condom/08.jpg', btn: FullScreenNextBtn, },
  { id: '09', bg: '/story/no-condom/09.jpg', btn: FullScreenNextBtn, },
  {
    id: '10', bg: '/story/no-condom/10.jpg',
    btn: [
      {
        href: RelatedHref.next,
        shap: { x: 107, y: 398, w: 233, h: 45 },
      }, {
        href: '13',
        shap: { x: 107, y: 494, w: 233, h: 45 },
      },
    ],
  },
  { id: '11', bg: '/story/no-condom/11.jpg', btn: FullScreenNextBtn, },
  { id: '12', bg: '/story/no-condom/12.jpg', btn: [{ href: '15.1', shap: FullScreenShap }], },
  { id: '13', bg: '/story/no-condom/13.jpg', btn: FullScreenNextBtn, },
  { id: '14', bg: '/story/no-condom/14.jpg', btn: FullScreenNextBtn, },
  { id: '15.1', bg: '/story/no-condom/15.1.jpg', btn: FullScreenNextBtn, },
  {
    id: '15.2', bg: '/story/no-condom/15.2.jpg',
    btn: [
      {
        href: RelatedHref.next,
        shap: { x: 74, y: 399, w: 265, h: 44 },
      }, {
        href: '19',
        shap: { x: 74, y: 484, w: 265, h: 44 },
      },
    ],
  },
  { id: '16', bg: '/story/no-condom/16.jpg', btn: FullScreenNextBtn, },
  { id: '18', bg: '/story/no-condom/18.jpg', btn: FullScreenNextBtn, },
  { id: '19', bg: '/story/no-condom/19.jpg', btn: FullScreenNextBtn, },
  { id: '21', bg: '/story/no-condom/21.jpg', btn: FullScreenNextBtn, },
  { id: '22', bg: '/story/no-condom/22.jpg', btn: FullScreenNextBtn, },
  {
    id: '23', bg: '/story/no-condom/23.jpg',
    btn: [
      {
        href: RelatedHref.next,
        shap: { x: 125, y: 355, w: 164, h: 45 },
      }, {
        href: '25',
        shap: { x: 125, y: 442, w: 164, h: 45 },
      },
    ],
  },
  {
    id: '24.1', bg: '/story/no-condom/24.1.jpg',
    btn: [
      {
        href: '26.1',
        shap: FullScreenShap,
      }, {
        href: RelatedHref.next,
        shap: { x: 64, y: 192, w: 278, h: 157 },
      },
    ],
  },
  { id: '24.2', bg: '/story/no-condom/24.2.jpg', btn: [{ href: '26.1', shap: FullScreenShap, }], },
  { id: '25', bg: '/story/no-condom/25.jpg', btn: FullScreenNextBtn, },
  { id: '26.1', bg: '/story/no-condom/26.1.jpg', btn: FullScreenNextBtn, },
  { id: '26.2', bg: '/story/no-condom/26.2.jpg', btn: FullScreenNextBtn, },
  { id: '26.3', bg: '/story/no-condom/26.3.jpg', btn: FullScreenNextBtn, },
  { id: '26.4', bg: '/story/no-condom/26.4.jpg', btn: FullScreenNextBtn, },
  { id: '26.5', bg: '/story/no-condom/26.5.jpg', btn: FullScreenNextBtn, },
  { id: '26.6', bg: '/story/no-condom/26.6.jpg', btn: FullScreenNextBtn, },
  { id: '27', bg: '/story/no-condom/27.jpg', btn: FullScreenNextBtn, },
  { id: '28.1', bg: '/story/no-condom/28.1.jpg', btn: FullScreenNextBtn, },
  { id: '28.2', bg: '/story/no-condom/28.2.jpg', btn: FullScreenNextBtn, },
  { id: '28.3', bg: '/story/no-condom/28.3.jpg', btn: FullScreenNextBtn, },
  { id: '29', bg: '/story/no-condom/29.jpg', btn: [{ href: RelatedHref.close, shap: FullScreenShap }] },
];
