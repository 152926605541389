export interface Score {
  playDate: string;				//操作日期	yyyy-MM-dd
  taskLogin: Complete;				//每日登入
  taskStory1: Complete;				//情境故事1
  taskStory2: Complete;				//情境故事2
  taskStory3: Complete;				//情境故事3
  taskMaleGame: Complete;			//男性避孕遊戲
  taskFemaleGame: Complete;			//女性避孕遊戲
  taskCondomGame: Complete;			//保險套遊戲
  taskBasketballGame: Complete;		//籃球遊戲
  taskChatroom: Complete;			//問幸福小天使問題
  taskForum: Complete;				//在留言板發問或回覆
  taskResource: Complete;			//在資源分享觀看影片或閱讀文章
  taskCharityOrganization: Complete;	//查看社福機構
  watering: Complete; 				//澆水
}

type ScoreKey = keyof Score;
export type TaskName = Exclude<ScoreKey, 'playDate' | 'watering'>

export const TaskNames: Array<TaskName> = [
  'taskLogin',
  'taskStory1',
  'taskStory2',
  'taskStory3',
  'taskMaleGame',
  'taskFemaleGame',
  'taskCondomGame',
  'taskBasketballGame',
  'taskChatroom',
  'taskForum',
  'taskResource',
  'taskCharityOrganization',
];

export const calcLatestScore: (list: Score[]) => Score = list => {
  return [...list]
    .sort((a, b) => a.playDate.localeCompare(b.playDate))
    .pop()!;
};

export const calcWaterUnit: (score: Score) => number = score => {
  const completeCount = TaskNames.reduce((acc, x) => acc + (score[x] as Complete), 0);
  const unit1 = 4;
  return completeCount / unit1 | 0;
};

export const calcDayStatus: (score: Score) => DayStatus = score => {
  return score.watering === Complete.done ? DayStatus.end : DayStatus.start;
};

export const isWaterFull: (score: Score) => boolean = score => {
  const completeCount = TaskNames.reduce((acc, x) => acc + (score[x] as Complete), 0);
  return completeCount === TaskNames.length;
};

export const isThereWateringToday: (score: Score) => boolean = score => {
  return score.watering === Complete.done;
};

export const is5DaysComplete: (day:number, score: Score) => boolean = (day, score) => {
  return day === 5 && score.watering === Complete.done;
};

export const ScoreTranslation: Record<keyof Score, string> = {
  playDate: '操作日期',
  taskLogin: '每日登入',
  taskStory1: '情境故事1',
  taskStory2: '情境故事2',
  taskStory3: '情境故事3',
  taskMaleGame: '完成男性飛鏢遊戲',
  taskFemaleGame: '完成女性飛鏢遊戲',
  taskCondomGame: '保險套遊戲',
  taskBasketballGame: '籃球遊戲',
  taskChatroom: '問幸福小天使問題',
  taskForum: '在留言板發問或回覆',
  taskResource: '在資源分享觀看影片或閱讀文章',
  taskCharityOrganization: '查看社福機構',
  watering: '澆水',
};

export enum Complete {
  undone = 0,
  done = 1,
}

export enum DayStatus {
  start = 'start',
  watering = 'watering',
  end = 'end',
}
