export async function copy(text: string): Promise<void> {
  const doc = window.document;
  const textarea = doc.createElement('textarea');
  textarea.style.height = '0';
  textarea.style.width = '0';
  textarea.style.overflow = 'hidden';
  textarea.value = text;
  doc.body.appendChild(textarea);
  textarea.select();
  const successful = doc.execCommand('copy');
  window.getSelection()?.removeAllRanges();
  doc.body.removeChild(textarea);

  if (successful) {
    return;
  } else {
    throw new Error('Copy failed');
  }
}
