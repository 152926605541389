import React, { FunctionComponent, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import { BaseLayout } from '@component/layouts';
import { useQueryParams } from '@library/router';
import './auth.scss';
import {
  cellphoneRegExp,
  CityOption,
  emailRegExp,
  GenderOption,
  GradeOption,
  SignUpFormValue
} from '../model';
import { AuthService as useCase } from '../use-case';

export interface SignUpPageRouteParams {
  inviter: string;
}

export const SignUpPageCmp: FunctionComponent = () => {
  const { register, control, handleSubmit, errors, getValues, setValue } = useForm<SignUpFormValue>();
  const history = useHistory();
  const [apiError, setApiError] = useState<string>();
  const { inviter } = useQueryParams<SignUpPageRouteParams>();

  useEffect(() => {
    setValue('inviter', inviter);
    return () => {};
  }, [inviter]);

  function onSubmit(data: SignUpFormValue) {
    useCase.signUp(data)
      .then(() => {
        history.replace('/intro');
      }, () => {
        setApiError('格式錯誤，請再試一次');
      });
  }

  function samePasswordValidation(value: string): boolean {
    const password = getValues('password');
    return value === password;
  }

  return (
    <BaseLayout
      className="sign-up-page"
      body={
        <div className="from-container horizontal-flex">
          <h3 className="text-center form-title">請回答以下問題建立帳號</h3>
          <div className="form-group">
            <label>帳號</label>
            <div className="form-field">
              <input className="form-control" type="text" id="username" name="username" ref={register({ required: true, minLength: 6 })} />
              {errors.username && errors.username.type === 'required' && <p className="error-message">必填</p>}
              {errors.username && errors.username.type === 'minLength' && <p className="error-message">至少 6 字</p>}
            </div>
          </div>
          <div className="form-group">
            <label>密碼</label>
            <div className="form-field">
              <input className="form-control" type="password" id="password" name="password" ref={register({ required: true, minLength: 6 })} />
              {errors.password && errors.password.type === 'required' && <p className="error-message">必填</p>}
              {errors.password && errors.password.type === 'minLength' && <p className="error-message">至少 6 字</p>}
            </div>
          </div>
          <div className="form-group">
            <label>再次輸入密碼</label>
            <div className="form-field">
              <input className="form-control" type="password" name="repeatPassword" ref={register({ validate: { same: samePasswordValidation } })} />
              {errors.repeatPassword && errors.repeatPassword.type === 'same' && <p className="error-message">須和密碼相同</p>}
            </div>
          </div>
          <div className="form-group">
            <label>性別</label>
            <div className="form-field">
              <Controller
                control={control}
                render={({ onChange, value }) => (
                  <select className="form-control" value={value} onChange={event => onChange(event.target.value)}>
                    {GenderOption.map(op => <option key={op.id} value={op.value}>{op.label}</option>)}
                  </select>
                )}
                name="gender"
                rules={{ required: true }}
                defaultValue=""
              />
              {errors.gender && <p className="error-message">必填</p>}
            </div>
          </div>
          <div className="form-group">
            <label>國/高中年級</label>
            <div className="form-field">
              <Controller
                control={control}
                render={({ onChange, value }) => (
                  <select className="form-control" value={value} onChange={event => onChange(event.target.value)}>
                    {GradeOption.map(op => <option key={op.id} value={op.value}>{op.label}</option>)}
                  </select>
                )}
                name="grade"
                rules={{ required: true }}
                defaultValue=""
              />
              {errors.grade && <p className="error-message">必填</p>}
            </div>
          </div>
          <div className="form-group">
            <label>生日</label>
            <div className="form-field">
              <Controller
                control={control}
                render={({ onChange, value }) => (
                  <input className="form-control" type="date" value={value} onChange={event => onChange(event.target.value)} />
                )}
                name="birthdate"
                rules={{ required: true }}
                defaultValue=""
              />
              {errors.birthdate && <p className="error-message">必填</p>}
            </div>
          </div>
          <div className="form-group">
            <label>居住城市</label>
            <div className="form-field">
              <Controller
                control={control}
                render={({ onChange, value }) => (
                  <select className="form-control" value={value} onChange={event => onChange(event.target.value)}>
                    {CityOption.map(op => <option key={op.id} value={op.value}>{op.label}</option>)}
                  </select>
                )}
                name="city"
                rules={{ required: true }}
                defaultValue=""
              />
              {errors.city && <p className="error-message">必填</p>}
            </div>
          </div>
          <div className="form-group">
            <label>email</label>
            <div className="form-field">
              <input className="form-control" type="text" name="email" ref={register({ pattern: emailRegExp })} />
              {errors.email && <p className="error-message">請符合 Email 格式，如. xxxxxx@gmail.com</p>}
            </div>
          </div>
          <div className="form-group">
            <label>手機號碼</label>
            <div className="form-field">
              <input className="form-control" type="text" name="phone" ref={register({ pattern: cellphoneRegExp })} />
              {errors.phone && <p className="error-message">限 10 碼數字，如. 0987654321</p>}
            </div>
          </div>
          <div className="form-group">
            <label>邀請碼</label>
            <div className="form-field">
              <input className="form-control" type="text" name="inviter" readOnly ref={register} />
            </div>
          </div>
          <div className="text-center">
            <button
              type="button"
              className="btn btn-submit fz-lg"
              onClick={handleSubmit(onSubmit)}
            >填完送出</button>
            {apiError && <p className="error-message">{apiError}</p>}
            <p className="text-center"><Link style={{ color: '#7B6E6D' }} to="/sign-in">返回登入</Link></p>
          </div>
        </div>
      }
    />
  );
};
