import React, { FunctionComponent, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import { BaseLayout } from '@component/layouts';
import './auth.scss';
import { SignInFormValue } from '../model';
import { AuthService as useCase } from '../use-case';

export const SignInPageCmp: FunctionComponent = () => {
  const { register, handleSubmit, errors } = useForm<SignInFormValue>();
  const history = useHistory();
  const [apiError, setApiError] = useState<string>();

  function onSubmit(data: SignInFormValue) {
    useCase.signIn(data)
      .then(() => {
        history.push('/');
      }, () => {
        setApiError('帳號密碼錯誤，請再試一次');
      });
  }

  function signInAsAnonymous() {
    useCase.signInAsAnonymous();
    history.push('/intro');
  }

  return (
    <BaseLayout
      className="sign-in-page"
      body={
        <div>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '40px' }}>
            <h2 style={{ color: '#D67A72' }}>幸福 On the Go</h2>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
            <img style={{ margin: '20px' }} src="/home/boy.png" alt="" />
            <img style={{ margin: '20px' }} src="/home/girl.png" alt="" />
          </div>
          <div className="from-container horizontal-flex">
            <h3 className="text-center form-title">登入帳號</h3>
            <div className="form-group">
              <label>帳號</label>
              <div className="form-field">
                <input className="form-control" type="text" id="username" name="username" ref={register({ required: true })} />
                {errors.username && <p className="error-message">必填</p>}
              </div>
            </div>
            <div className="form-group">
              <label>密碼</label>
              <div className="form-field">
                <input className="form-control" type="password" id="password" name="password" ref={register({ required: true })} />
                {errors.password && <p className="error-message">必填</p>}
              </div>
            </div>
            <p className="text-center">
              <Link style={{ color: '#A69696' }} to="/forgot-account">忘記帳號</Link>
              <span>&nbsp;</span>
              <Link style={{ color: '#A69696' }} to="/forgot-password">忘記密碼</Link>
              <span>&nbsp;</span>
              <Link style={{ color: '#7B6E6D' }} to="/sign-up">註冊</Link>
            </p>
            <div className="text-center">
              <button
                type="button"
                className="btn btn-submit fz-lg"
                onClick={handleSubmit(onSubmit)}
              >登入</button>
              {apiError && <p className="error-message">{apiError}</p>}
            </div>
            <p className="text-center">
              <button className="btn" onClick={signInAsAnonymous}>訪客進入</button>
            </p>
          </div>
        </div>
      }
    />
  );
};
