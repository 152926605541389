import React, { FunctionComponent } from 'react';
import './App.scss';
import { HashRouter as Router, Switch, RedirectProps } from 'react-router-dom';
import { StoryListPageCmp, StoryPageCmp } from './story';
import { HomePageCmp, IntroPageCmp, LobbyPageCmp } from './home';
import { CondomPageCmp } from './game/condom';
import { GameListPageCmp } from './game/game-list.page';
import { BasketballPageCmp } from './game/basketball';
import { DartPageCmp } from './game/dart';
import { ChatbotPageCmp } from './chatroom';
import { SocialWelfareAgencyPageCmp } from './social-welfare-agency';
import { ResourcePageCmp } from './resource';
import { ForumPageCmp, NewPostPageCmp, PostPageCmp } from './forum';
import { TaskPageCmp } from './task';
import {
  SignInPageCmp,
  SignUpPageCmp,
  ForgotPasswordPageCmp,
  ModifyPasswordPageCmp,
  ForgotAccountPageCmp,
  AuthService,
  UnauthorizedService
} from './auth';
import { ARoute, RouteConfig } from './route';
import { ContextProvider } from './Context';

const UnauthorizedFallback: RedirectProps = { to: '/lobby' };

const ROUTE_CONFIG: RouteConfig[] = [{
  path: '/',
  component: HomePageCmp,
  exact: true,
  canActivate: AuthService,
  redirectOnInactive: UnauthorizedFallback,
}, {
  path: '/lobby',
  exact: true,
  component: LobbyPageCmp,
  canActivate: UnauthorizedService,
}, {
  path: '/sign-in',
  exact: true,
  component: SignInPageCmp,
  canActivate: UnauthorizedService,
}, {
  path: '/sign-up',
  exact: true,
  component: SignUpPageCmp,
  canActivate: UnauthorizedService,
}, {
  path: '/forgot-password',
  exact: true,
  component: ForgotPasswordPageCmp,
  canActivate: UnauthorizedService,
}, {
  path: '/reset-password',
  exact: true,
  component: ModifyPasswordPageCmp,
  canActivate: UnauthorizedService,
}, {
  path: '/forgot-account',
  exact: true,
  component: ForgotAccountPageCmp,
  canActivate: UnauthorizedService,
}, {
  path: '/intro',
  component: IntroPageCmp,
  exact: true,
  canActivate: AuthService,
  redirectOnInactive: UnauthorizedFallback,
}, {
  path: '/story',
  component: StoryListPageCmp,
  exact: true,
  canActivate: AuthService,
  redirectOnInactive: UnauthorizedFallback,
}, {
  path: '/story/:storyId',
  component: StoryPageCmp,
  canActivate: AuthService,
  redirectOnInactive: UnauthorizedFallback,
}, {
  path: '/game',
  component: GameListPageCmp,
  exact: true,
  canActivate: AuthService,
  redirectOnInactive: UnauthorizedFallback,
}, {
  path: '/game/condom',
  component: CondomPageCmp,
  canActivate: AuthService,
  redirectOnInactive: UnauthorizedFallback,
}, {
  path: '/game/basketball',
  component: BasketballPageCmp,
  canActivate: AuthService,
  redirectOnInactive: UnauthorizedFallback,
}, {
  path: '/game/dart',
  component: DartPageCmp,
  canActivate: AuthService,
  redirectOnInactive: UnauthorizedFallback,
}, {
  path: '/chatbot',
  component: ChatbotPageCmp,
  canActivate: AuthService,
  redirectOnInactive: UnauthorizedFallback,
}, {
  path: '/social-welfare-agency',
  component: SocialWelfareAgencyPageCmp,
  canActivate: AuthService,
  redirectOnInactive: UnauthorizedFallback,
}, {
  path: '/resource',
  component: ResourcePageCmp,
  canActivate: AuthService,
  redirectOnInactive: UnauthorizedFallback,
}, {
  path: '/forum',
  component: ForumPageCmp,
  exact: true,
  canActivate: AuthService,
  redirectOnInactive: UnauthorizedFallback,
}, {
  path: '/forum/post/new',
  component: NewPostPageCmp,
  exact: true,
  canActivate: AuthService,
  redirectOnInactive: UnauthorizedFallback,
}, {
  path: '/forum/post/:postId',
  component: PostPageCmp,
  canActivate: AuthService,
  redirectOnInactive: UnauthorizedFallback,
}, {
  path: '/task',
  component: TaskPageCmp,
  canActivate: AuthService,
  redirectOnInactive: UnauthorizedFallback,
}];

const App: FunctionComponent = () => (
  <Router>
    <ContextProvider>
      <Switch>
        {
          ROUTE_CONFIG.map(props => (
            <ARoute key={props.path} {...props} />
          ))
        }
      </Switch>
    </ContextProvider>
  </Router>
);

export default App;
