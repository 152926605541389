import React, { FunctionComponent, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import { BaseLayout } from '@component/layouts';
import './auth.scss';
import { GenderOption, VerfyForgotPasswordFormValue } from '../model';
import { AuthService as useCase } from '../use-case';

export const ForgotPasswordPageCmp: FunctionComponent = () => {
  const history = useHistory();
  const { register, control, handleSubmit, errors } = useForm<VerfyForgotPasswordFormValue>();
  const [apiError, setApiError] = useState<string>();

  function onSubmit(data: VerfyForgotPasswordFormValue): void {
    console.log(data);
    useCase.verfyForgotPassword(data)
      .then(() => {
        history.push('/reset-password');
      }, () => {
        setApiError('帳號資訊錯誤，請再試一次');
      });
  }

  return (
    <BaseLayout
      className="forgot-password-page"
      body={
        <div className="from-container horizontal-flex">
          <h3 className="text-center form-title">忘記密碼</h3>
          <div className="form-group">
            <label>帳號</label>
            <div className="form-field">
              <input className="form-control" type="text" name="username" ref={register({ required: true })} />
              {errors.username && <p className="error-message">必填</p>}
            </div>
          </div>
          <div className="form-group">
            <label>性別</label>
            <div className="form-field">
              <Controller
                control={control}
                render={({ onChange, value }) => (
                  <select className="form-control" value={value} onChange={event => onChange(event.target.value)}>
                    {GenderOption.map(op => <option key={op.id} value={op.value}>{op.label}</option>)}
                  </select>
                )}
                name="gender"
                rules={{ required: true }}
                defaultValue=""
              />
              {errors.gender && <p className="error-message">必填</p>}
            </div>
          </div>
          <div className="form-group">
            <label>生日</label>
            <div className="form-field">
              <Controller
                control={control}
                render={({ onChange, value }) => (
                  <input className="form-control" type="date" value={value} onChange={event => onChange(event.target.value)} />
                )}
                name="birthdate"
                rules={{ required: true }}
                defaultValue=""
              />
              {errors.birthdate && <p className="error-message">必填</p>}
            </div>
          </div>
          <div className="text-center">
            <button
              type="button"
              className="btn btn-submit fz-lg"
              onClick={handleSubmit(onSubmit)}
            >確定</button>
            {apiError && <p className="error-message">{apiError}</p>}
            <p className="text-center"><Link style={{ color: '#7B6E6D' }} to="/sign-in">返回登入</Link></p>
          </div>
        </div>
      }
    />
  );
};
