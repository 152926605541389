import { Btn, RelatedHref, Slide } from '@library/slide-board';

const CONTENT_BTN: Btn[] = [
  {
    href: RelatedHref.previous,
    shap: { x: 18, y: 453, w: 69, h: 44 },
  }, {
    href: RelatedHref.next,
    shap: { x: 334, y: 453, w: 69, h: 44 },
  }
];

export const SLIDE: Slide[] = [
  {
    id: 'intro', bg: '/game/condom/intro.png',
    btn: [
      {
        href: RelatedHref.next,
        shap: { w: 129, h: 60, x: 259, y: 410 }
      }
    ],
  },
  { id: 'step1-1', bg: '/game/condom/step1-1.png', btn: CONTENT_BTN },
  { id: 'step1-2', bg: '/game/condom/step1-2.png', btn: CONTENT_BTN },
  { id: 'step2-1', bg: '/game/condom/step2-1.png', btn: CONTENT_BTN },
  { id: 'step2-2', bg: '/game/condom/step2-2.png', btn: CONTENT_BTN },
  { id: 'step3-1', bg: '/game/condom/step3-1.png', btn: CONTENT_BTN },
  { id: 'step3-2', bg: '/game/condom/step3-2.png', btn: CONTENT_BTN },
  { id: 'step4-1', bg: '/game/condom/step4-1.png', btn: CONTENT_BTN },
  { id: 'step4-2', bg: '/game/condom/step4-2.png', btn: CONTENT_BTN },
  { id: 'step5-1', bg: '/game/condom/step5-1.png', btn: CONTENT_BTN },
  { id: 'step5-2', bg: '/game/condom/step5-2.png', btn: CONTENT_BTN },
  { id: 'step5-3', bg: '/game/condom/step5-3.png', btn: CONTENT_BTN },
  { id: 'step6-1', bg: '/game/condom/step6-1.png', btn: CONTENT_BTN },
  { id: 'step6-2', bg: '/game/condom/step6-2.png', btn: CONTENT_BTN },
  { id: 'step6-3', bg: '/game/condom/step6-3.png', btn: CONTENT_BTN },
  { id: 'step7-1', bg: '/game/condom/step7-1.png', btn: CONTENT_BTN },
  { id: 'step7-2', bg: '/game/condom/step7-2.png', btn: CONTENT_BTN },
  { id: 'step7-3', bg: '/game/condom/step7-3.png', btn: CONTENT_BTN },
  {
    id: 'finished', bg: '/game/condom/finished.png',
    btn: [
      {
        href: 'step1-1',
        shap: { w: 128, h: 59, x: 57, y: 533, }
      }, {
        href: RelatedHref.close,
        shap: { w: 128, h: 59, x: 231, y: 533 }
      }
    ]
  },
];
