import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { OrientationType, useOrientation } from '@hook/useOrientation';
import { BaseHeader, BaseLayout, StoryLayout } from '@component/layouts';
import { makeSildeApp, SlideApp } from '@library/slide-board';
import { SLIDE } from './condom.config';
import { getProvider } from '@/Context';

export const CondomPageCmp: FunctionComponent = () => {
  const { completeTask } = getProvider('taskHook');
  const history = useHistory();
  const { orientationType } = useOrientation(window);
  const [slideApp, setSlideApp] = useState<SlideApp>();
  const [progress, setProgress] = useState(0);

  const canvasRef = useCallback((node: HTMLCanvasElement) => {
    if (node !== null) {
      console.log('game init');
      const sApp = makeSildeApp({
        view: node,
        goBack: () => history.goBack(),
        slide: SLIDE,
        size: {
          width: 414,
          height: 668,
        },
        onProgress: x => setProgress(x),
        taskName: 'taskCondomGame',
        completeTask,
      });
      setSlideApp(sApp);
    }
    return () => {
      console.log('game destory');
    };
  }, [history]);

  useEffect(() => {
    return () => slideApp?.destory();
  }, [slideApp]);

  function isProgressing() {
    return progress !== 100;
  }

  return (
    <BaseLayout
      header={<BaseHeader title="保險套遊戲" isLeftBtnBack />}
      body={<StoryLayout>
        {
          isProgressing() && (
            <div className="loader-progress">
              <p className="progress-text fz-lg">{progress}%</p>
            </div>
          )
        }
        {
          orientationType === OrientationType.landscape
            ? <h1 className="text-center" style={{ color: '#fff' }}>請轉直螢幕</h1>
            : <canvas ref={canvasRef} className="game-board"></canvas>
        }
      </StoryLayout>}
    />
  );
}
