import { BehaviorSubject } from 'rxjs';
import { Message } from '../model';

export class ChatbotStore {
  private storeMessages = new BehaviorSubject<Message[]>([]);
  readonly messages$ = this.storeMessages.asObservable();
  private storeOptions = new BehaviorSubject<Message[]>([]);
  readonly options$ = this.storeOptions.asObservable();

  getOptions() {
    return this.storeOptions.getValue();
  }

  setOptions(options: Message[]) {
    return this.storeOptions.next(options);
  }

  getAll() {
    return this.storeMessages.getValue();
  }

  setAll(list: Message[]) {
    return this.storeMessages.next(list);
  }

  addOne(record: Message) {
    const list = this.storeMessages.getValue();
    const listNew = list.concat(record);
    this.storeMessages.next(listNew);
  }

  putOne(before: Message, after: Message) {
    const list = this.storeMessages.getValue();
    const listNew = list.map(r => r === before ? after : r);
    this.storeMessages.next(listNew);
  }
}