export interface Article {
  type: string;
  title: string;
  linkType: string;
  link: string;
}

export interface ArticleQueryParams {
  type: string;
}

export const ArticleType = {
  aids: '愛滋病毒',
  venerealDisease: '性病',
  pregnancy: '懷孕',
  menstruation: '月經',
  contraception: '避孕',
  masturbate: '自慰',
};

export const ArticleTypeOptions = Object.values(ArticleType);

export const ArticleConfig: Article[] = [
  { type: ArticleType.aids, title: "人類免疫缺乏病毒(愛滋病毒)感染", linkType: "文章", link: "https://www.cdc.gov.tw/Category/Page/lehLY2EFku4q7Gqv4bql2w" },
  { type: ArticleType.aids, title: "愛滋知識與預防篇", linkType: "文章", link: "https://www.cdc.gov.tw/Category/List/qTp6DeK3rmOlqcVc7diAkA" },
  { type: ArticleType.aids, title: "暴露愛滋病毒「前」預防性投藥", linkType: "文章", link: "https://www.cdc.gov.tw/Category/MPage/tXBKgpeVZ9l9929TEdZGJw" },
  { type: ArticleType.aids, title: "【疾管署】愛滋病-「有備無患PrEP」係蝦米 毛大告訴你妳", linkType: "影片", link: "https://www.cdc.gov.tw/Category/MoviePage/2xHloQ6fXNagOKPnayrjgQ?movieId=exUHEpBptjvk2x7NxluyJA" },
  { type: ArticleType.aids, title: "暴露愛滋病毒「後」預防性投藥 PEP", linkType: "文章", link: "https://www.cdc.gov.tw/Category/QAPage/JQc1_rTZWlHX9-hw4U_4yg" },
  { type: ArticleType.aids, title: "愛滋病到底怎麼傳染的？", linkType: "影片", link: "https://www.youtube.com/watch?v=lPNqGnYr8ck&feature=youtu.be" },
  { type: ArticleType.aids, title: "【愛滋病防治】 跟著毛大動次動 毛大陪你談PEP", linkType: "影片", link: "https://www.youtube.com/watch?v=3aHgJdqqOmM&feature=youtu.be" },
  { type: ArticleType.venerealDisease, title: "性病的治療", linkType: "文章", link: "https://young.hpa.gov.tw/index/yngshow.aspx?CDE=YNG201707211334413FT" },
  { type: ArticleType.venerealDisease, title: "生殖器泡疹", linkType: "文章", link: "https://young.hpa.gov.tw/index/yngshow.aspx?CDE=YNG20170721133111A17" },
  { type: ArticleType.venerealDisease, title: "菜花", linkType: "文章", link: "https://young.hpa.gov.tw/index/yngshow.aspx?CDE=YNG201707211330541ER&t=YTP20170704133332PSL" },
  { type: ArticleType.venerealDisease, title: "披衣菌感染", linkType: "文章", link: "https://young.hpa.gov.tw/index/yngshow.aspx?CDE=YNG201707211333170NQ" },
  { type: ArticleType.venerealDisease, title: "淋病", linkType: "文章", link: "https://www.cdc.gov.tw/Category/Page/oENrYytXCrwUl6SijsDFcw" },
  { type: ArticleType.venerealDisease, title: "梅毒", linkType: "文章", link: "https://www.cdc.gov.tw/Category/Page/pzPKSi2S4zCt94DDnwqVNg" },
  { type: ArticleType.venerealDisease, title: "性病徵兆有這些!? 性病的自我檢測!! 千萬要注意!!", linkType: "影片", link: "https://www.youtube.com/watch?v=ld6qr07fNQA" },
  { type: ArticleType.pregnancy, title: "新生命的開始─懷孕歷程", linkType: "文章", link: "https://young.hpa.gov.tw/index/yngshow.aspx?CDE=YNG20170721134234KAA&t=YTP20170704133442D4W" },
  { type: ArticleType.pregnancy, title: "真的懷孕了嗎？", linkType: "文章", link: "https://young.hpa.gov.tw/index/yngshow.aspx?CDE=YNG20170824143205RPV&t=YTP20170704133442D4W" },
  { type: ArticleType.pregnancy, title: "自然受孕", linkType: "文章", link: "https://young.hpa.gov.tw/index/yngshow.aspx?CDE=YNG20170721133854EFP&t=YTP201707041334570N8" },
  { type: ArticleType.pregnancy, title: "懷孕初期12種症狀，出現這些徵兆代表妳可能有喜囉！", linkType: "文章", link: "https://www.mababy.com/knowledge-detail?id=6066" },
  { type: ArticleType.menstruation, title: "常見經期問題-經痛怎麼辦？", linkType: "文章", link: "https://young.hpa.gov.tw/index/yngshow.aspx?CDE=YNG20190528150203HVV" },
  { type: ArticleType.menstruation, title: "女性生理用品懶人包", linkType: "文章", link: "https://young.hpa.gov.tw/index/yngshow.aspx?CDE=YNG20191028165229AWW&t=YTP20170704133224DRE" },
  { type: ArticleType.menstruation, title: "月經週期的保健", linkType: "文章", link: "https://young.hpa.gov.tw/index/yngshow.aspx?CDE=YNG20170710142144DR8&t=YTP20170704133224DRE" },
  { type: ArticleType.menstruation, title: "衛生用品的選擇─衛生棉", linkType: "文章", link: "https://young.hpa.gov.tw/index/yngshow.aspx?CDE=YNG20170710144020YOQ&t=YTP20170704133224DRE" },
  { type: ArticleType.menstruation, title: "為什麼我還沒有月經？", linkType: "文章", link: "https://young.hpa.gov.tw/index/yngshow.aspx?CDE=YNG201710041158414OJ&t=YTP20170704133224DRE" },
  { type: ArticleType.menstruation, title: "【衛教資訊】女孩如何清潔自己的生殖器官", linkType: "文章", link: "https://young.hpa.gov.tw/index/yngshow.aspx?CDE=YNG20200505111524DOC&t=YTP20170704133224DRE" },
  { type: ArticleType.menstruation, title: "如何計算排卵期 / 安全期？", linkType: "影片", link: "https://www.youtube.com/watch?v=4SVps92k8uU" },
  { type: ArticleType.contraception, title: "【懶人包】避孕方式懶人包", linkType: "文章", link: "https://young.hpa.gov.tw/index/yngshow.aspx?CDE=YNG20190412091731HBK&t=YTP20170704133622N8A" },
  { type: ArticleType.contraception, title: "避孕方法五花八門？其實充滿迷思！", linkType: "文章", link: "https://young.hpa.gov.tw/index/yngshow.aspx?CDE=YNG20180406100823CN6&t=YTP20170704133622N8A" },
  { type: ArticleType.contraception, title: "避孕大哉問──避孕原理說明", linkType: "文章", link: "https://young.hpa.gov.tw/index/yngshow.aspx?CDE=YNG20170721134427ZAL&t=YTP20170704133622N8A" },
  { type: ArticleType.contraception, title: "口服避孕藥", linkType: "文章", link: "https://young.hpa.gov.tw/index/yngshow.aspx?CDE=YNG20170819195942L4J&t=YTP20170704133622N8A" },
  { type: ArticleType.contraception, title: "男性結紮", linkType: "文章", link: "https://young.hpa.gov.tw/index/yngshow.aspx?CDE=YNG20170819201349FE3&t=YTP20170704133622N8A" },
  { type: ArticleType.contraception, title: "女性結紮", linkType: "文章", link: "https://young.hpa.gov.tw/index/yngshow.aspx?CDE=YNG20170819201441UJB&t=YTP20170704133622N8A" },
  { type: ArticleType.contraception, title: "月經週期法", linkType: "文章", link: "https://young.hpa.gov.tw/index/yngshow.aspx?CDE=YNG20170819201751SBT&t=YTP20170704133622N8A" },
  { type: ArticleType.contraception, title: "一錠搞定？事後避孕藥的5大迷思", linkType: "文章", link: "http://www.257085.org.tw/knowledge_detail.php?Key=56" },
  { type: ArticleType.contraception, title: "保險套的使用方法", linkType: "文章", link: "https://young.hpa.gov.tw/index/yngshow.aspx?CDE=YNG20170801230754AM7&t=YTP20170704133332PSL" },
  { type: ArticleType.contraception, title: "保險套的原理及使用方式", linkType: "文章", link: "https://young.hpa.gov.tw/index/yngshow.aspx?CDE=YNG20170819201020GCO&t=YTP20170704133622N8A" },
  { type: ArticleType.contraception, title: "【藍方博士嘎哩共】保險套這樣用不NG 戴錯就得當爸媽！", linkType: "影片", link: "https://www.youtube.com/watch?v=qiGJQB5CIyM" },
  { type: ArticleType.masturbate, title: "【闢謠文章】青少年常自慰是否是心理不正常呢？", linkType: "文章", link: "https://young.hpa.gov.tw/index/yngshow.aspx?CDE=YNG20190528151129C6G&t=YTP201707041332478KJ" },
  { type: ArticleType.masturbate, title: "自慰是正常的嗎？", linkType: "文章", link: "https://young.hpa.gov.tw/index/yngshow.aspx?CDE=YNG201707101622221SE&t=YTP201707041332478KJ" },
  { type: ArticleType.masturbate, title: "自慰時該注意什麼呢？", linkType: "文章", link: "https://young.hpa.gov.tw/index/yngshow.aspx?CDE=YNG20171003002300FG0&t=YTP201707041332478KJ" },
  { type: ArticleType.masturbate, title: "這樣算過度嗎？談自慰的次數", linkType: "文章", link: "https://young.hpa.gov.tw/index/yngshow.aspx?CDE=YNG20171004120649SGH&t=YTP201707041332478KJ" },
  { type: ArticleType.masturbate, title: "又性衝動了！我該怎麼辦？", linkType: "文章", link: "https://young.hpa.gov.tw/index/yngshow.aspx?CDE=YNG20171004124525JP9&t=YTP201707041332478KJ" },
  { type: ArticleType.masturbate, title: "【闢謠資料】經常自慰會精盡人亡嗎?", linkType: "文章", link: "https://young.hpa.gov.tw/index/yngshow.aspx?CDE=YNG20200409102939FW3&t=YTP201707041332478KJ" },
];