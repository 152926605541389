import { BaseLayout } from '@component/layouts';
import React, { FunctionComponent, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import './auth.scss';
import { ModifyPasswordFormValue } from '../model';
import { AuthService as useCase } from '../use-case';

export const ModifyPasswordPageCmp: FunctionComponent = () => {
  const { register, handleSubmit, errors, getValues } = useForm<ModifyPasswordFormValue>();
  const history = useHistory();
  const [apiError, setApiError] = useState<string>();

  function onSubmit(data: ModifyPasswordFormValue) {
    useCase.modifyPassword(data)
      .then(() => {
        history.replace('/');
      }, () => {
        setApiError('修改密碼錯誤，請再試一次');
      });
  }

  function samePasswordValidation(value: string): boolean {
    const password = getValues('password');
    return value === password;
  }

  return (
    <BaseLayout
      className="modify-password-page"
      body={
        <div className="from-container horizontal-flex">
          <h3 className="text-center form-title">修改密碼</h3>
          <div className="form-group">
            <label>密碼</label>
            <div className="form-field">
              <input className="form-control" id="password" type="password" name="password" ref={register({ required: true, minLength: 6 })} />
              {errors.password && errors.password.type === 'required' && <p className="error-message">必填</p>}
              {errors.password && errors.password.type === 'minLength' && <p className="error-message">至少 6 字</p>}
            </div>
          </div>
          <div className="form-group">
            <label>再次輸入密碼</label>
            <div className="form-field">
              <input className="form-control" type="password" name="repeatPassword" ref={register({ validate: { same: samePasswordValidation } })} />
              {errors.repeatPassword && errors.repeatPassword.type === 'same' && <p className="error-message">須和密碼相同</p>}
            </div>
          </div>
          <div className="text-center">
            <button
              type="button"
              className="btn btn-submit fz-lg"
              onClick={handleSubmit(onSubmit)}
            >修改密碼</button>
            {apiError && <p className="error-message">{apiError}</p>}
          </div>
        </div>
      }
    />
  );
};
