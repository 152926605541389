import { Loader, Renderer, LoaderResource, Text } from 'pixi.js';
import { Scene, NodeLifecircle, Game, DisplayNode, SpriteStorage } from '@library/game';
import { SceneName } from './config';

export class PreloadScene extends Scene implements NodeLifecircle {
  constructor(
    private game: Game,
    private loader: Loader,
    private renderer: Renderer,
  ) {
    super();
  }

  onInit() {
    console.log('onInit preloadScene');
    this.loadResource();
  }

  onDestory() {
    console.log('onDestory preloadScene');
  }

  render() {
    return [
      DisplayNode.of({
        object: new Text('', { fill: 'white', fontSize: 24 }),
        decoration: {
          anchor: { x: 0.5, y: 0.5 },
          text: `${this.loader.progress}%`,
          x: this.renderer.width / 2,
          y: this.renderer.height / 2,
        },
      }),
    ];
  }

  loadResource() {
    const self = this;

    if (this.loader.progress === 100) {
      self.goPregame();
      return;
    }

    this.loader
      .add('spritesheet0', '/game/dart/spritesheet-0.json')
      .add('spritesheet1', '/game/dart/spritesheet-1.json')
      .add('bgm', '/game/dart/bgm.mp3')
      .on('start', () => self.updateProgressText())
      .on('progress', () => self.updateProgressText())
      .on('complete', () => self.updateProgressText())
      .load((l, r) => {
        self.setupSpriteStorage(r);
        self.playBgm(r);
        self.goPregame();
      });
  }

  private updateProgressText() {
    const text = this.children[0] as DisplayNode<Text>;
    text.decoration = {
      ...text.decoration,
      text: `${this.loader.progress | 0}%`
    };
  }

  private setupSpriteStorage(resources: Partial<Record<string, LoaderResource>>) {
    this.game.spriteStorage = new SpriteStorage({
      ...resources['spritesheet0']?.textures!,
      ...resources['spritesheet1']?.textures!,
    });
  }

  private playBgm(resources: Partial<Record<string, LoaderResource>>): void {
    this.game.bgm = resources['bgm']?.sound!;
    this.game.bgm.play({ loop: true, volume: 0.1 });
  }

  private goPregame() {
    setTimeout(() => {
      this.game.navigate(SceneName.PregameScene);
    }, 1000);
  }
}
