import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ChipSelect, Modal, ModalControl } from '@component/atoms';
import { AvatarOptionCmp } from './avatar-option';
import { OpenReplyModalQuery, Reply, AvatarOptions, Avatar } from '../model';

export const ReplyModal = ({
  modal,
  onConfirm = () => { },
  onCancel = () => { },
}: {
  modal: ModalControl<OpenReplyModalQuery, Reply>;
  onConfirm?(outputData: Reply): void;
  onCancel?(): void;
}) => {
  const { inputData, close } = modal;
  const { register, control, handleSubmit, errors } = useForm();

  function cancel() {
    close(onCancel);
  }

  function confirm() {
    handleSubmit<Reply>(record => {
      const outputData: Reply = {
        ...record,
        postId: inputData.postId!,
        replyId: inputData.replyId!,
      };
      close(() => onConfirm(outputData));
    })();
  }

  return (
    <Modal className="reply-modal" visible={modal.visible}>
      <div className="container">
        <header>
          <p className="title">我要回應······</p>
        </header>
        <main>
          <div className="form-group">
            <label>頭像</label>
            <div className="form-field">
              <Controller
                control={control}
                render={({ onChange, value }) => (
                  <ChipSelect
                    className="form-control"
                    options={AvatarOptions}
                    optionComponent={AvatarOptionCmp}
                    onValueChanged={value => onChange(value)}
                    value={value}
                  />
                )}
                name="avatar"
                rules={{ required: true }}
                defaultValue={Avatar.boy1}
              />
              {errors.avatar && <p className="error-message">必填</p>}
            </div>
          </div>
          <div className="form-group">
            <label>暱稱</label>
            <div className="form-field">
              <input className="form-control" type="text" name="nickname" ref={register({ required: true })} />
              {errors.nickname && <p className="error-message">必填</p>}
            </div>
          </div>
          <div className="form-group">
            <label>年齡</label>
            <div className="form-field">
              <Controller
                control={control}
                render={({ onChange, value }) => (
                  <select className="form-control" value={value} onChange={event => onChange(+event.target.value)}>
                    <option value="">請選擇</option>
                    <option value="12">12</option>
                    <option value="13">13</option>
                    <option value="14">14</option>
                    <option value="15">15</option>
                    <option value="16">16</option>
                    <option value="17">17</option>
                    <option value="18">18</option>
                  </select>
                )}
                name="age"
                rules={{ required: true }}
                defaultValue={Avatar.boy1}
              />
              {errors.age && <p className="error-message">必填</p>}
            </div>
          </div>
          <div className="form-group">
            <label>內容</label>
            <div className="form-field">
              <textarea className="form-control" name="content" ref={register({ required: true })}></textarea>
              {errors.content && <p className="error-message">必填</p>}
            </div>
          </div>
          <div className="form-group">
            <label></label>
            <div className="form-control">
              <button type="button" onClick={confirm}>張貼</button>
              <button type="button" onClick={cancel}>取消</button>
            </div>
          </div>
        </main>
      </div>
    </Modal>
  );
};
