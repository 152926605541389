import { History } from 'history';
import {
  Application,
  sound,
  Sprite,
  Container,
  Texture,
  ITextureDictionary,
} from 'pixi.js';

export type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>;
};

export type Type<T> = new (...args: any[]) => T;

export type Decoration<T = Container> = DeepPartial<T>;

export interface NodeLifecircle {
  onInit?(): void,
  onDestory?(): void,
  onPointertap?(): void,
}

export interface DisplayNodeParams<T = Container> extends NodeLifecircle {
  object?: T;
  decoration?: Decoration<T>;
  children?: DisplayNode[];
}

export class DisplayNode<T extends Container = Container> implements DisplayNodeParams<T> {
  static of<U extends Container>(params?: DisplayNodeParams<U>): DisplayNode<U> {
    return new DisplayNode<U>(params);
  }

  object!: T;

  private decorationState = useDecoration(this);
  get decoration() { return this.decorationState.get(); }
  set decoration(decoration) { this.decorationState.set(decoration); };

  private childrenState = useChildren(this);
  get children() { return this.childrenState.get(); }
  set children(nodes) { this.childrenState.set(nodes); };

  constructor({
    object,
    decoration = {},
    children = [],
    onPointertap = () => { },
    onInit = () => { },
    onDestory = () => { },
  }: DisplayNodeParams<T> = {}) {
    this.object = object || new Container() as T;
    this.decoration = decoration;
    this.children = children;
    this.onPointertap = onPointertap;
    this.onInit = onInit;
    this.onDestory = onDestory;
    attachEvent(this);
  }

  onInit() { }
  onDestory() { }
  onPointertap() { }
}

export abstract class Scene {
  object: Container = new Container();

  private decorationState = useDecoration(this);
  get decoration() { return this.decorationState.get(); }
  set decoration(decoration) { this.decorationState.set(decoration); };

  private childrenState = useChildren(this);
  get children() { return this.childrenState.get(); }
  set children(nodes) { this.childrenState.set(nodes); };

  constructor() {
    attachEvent(this);
  }

  render(): DisplayNode | DisplayNode[] { throw new Error('not implement'); }
}

export interface SenceDeclaration<T extends Scene> {
  sceneName: string;
  useFactory?(): T;
  redirectTo?: string;
}

export class SpriteStorage {
  readonly storage: Record<string, Sprite> = {};

  constructor(private textures: ITextureDictionary) { }

  get(textureName: string): Sprite {
    if (!this.has(textureName)) {
      this.storage[textureName] = new Sprite(this.getTexture(textureName));
    }
    return this.storage[textureName];
  }

  has(name: string): boolean {
    return !!this.storage[name];
  }

  getTexture(textureName: string): Texture {
    const texture: Texture = this.textures?.[textureName]!;
    if (!texture) { throw Error(`no texture name as ${textureName}`) }
    return texture;
  }
}

export class Game {
  static of(options: { declaration: SenceDeclaration<Scene>[] }): Game {
    const game = new Game();
    Object.assign(game, options);
    return game;
  }

  app!: Application;
  history!: History;
  bgm!: sound.Sound;
  sence!: Scene;
  declaration!: SenceDeclaration<Scene>[];
  spriteStorage!: SpriteStorage;

  bootstrap(options: {
    view: HTMLCanvasElement,
    width: number,
    height: number,
  }) {
    this.app = new Application(options);
    this.navigate('');
  }

  navigate(senceName: string) {
    // clear stage & destory sence
    this.app.stage.removeChildren();

    // bootstrap sence & apply to stage
    this.sence = this.makeSence(senceName);
    this.sence.children = ([] as DisplayNode[]).concat(this.sence.render());
    this.app.stage.addChild(this.sence.object);
  }

  destroy(): void {
    this.app.destroy();
    this.bgm.destroy();
  }

  private makeSence(senceName: string): Scene {
    let declaration = this.declaration.find(d => d.sceneName === senceName);
    if (declaration?.redirectTo!) {
      declaration = this.declaration.find(d => d.sceneName === declaration?.redirectTo);
    }
    const sence = declaration?.useFactory?.();
    if (!sence) { throw new Error(`no sence as ${senceName}`); }
    return sence;
  }
}

function attachEvent(context: NodeLifecircle & Pick<DisplayNode, 'object'>) {
  context.object
    .on('pointertap', () => context.onPointertap?.())
    .on('added', () => context.onInit?.())
    .on('removed', () => {
      context.onDestory?.();
      context.object.removeAllListeners();
      context.object.removeChildren();
    });
}

function useDecoration<T extends Container = Container>(context: { object: T }) {
  let _decoration: Decoration<T> = context.object;
  return {
    get() { return _decoration },
    set(decoration: Decoration<T>) {
      _decoration = decoration;
      context.object = Object.assign(context.object, decoration);
    }
  };
}

function useChildren<T extends Container = Container>(context: { object: T }) {
  let _children: DisplayNode[] = [];
  return {
    get() { return _children },
    set(nodes: DisplayNode[]) {
      _children = nodes;
      context.object.removeChildren();
      const objects = nodes.map(n => n.object);
      if (objects.length) {
        context.object.addChild(...objects);
      }
    }
  };
}
