export class HttpHelper {
  static handleResponse(res: Response) {
    const isSuccess = /^2/.test('' + res.status);
    const header = HttpHelper.parseHeader(res);
    const isJson = /^application\/json/.test(header.contentType);
    const pBody = isJson ? res.json() : res.text();

    if (isSuccess) {
      return pBody
    } else {
      return pBody.then(t => {
        const msg = `${res.status} ${res.url} ${isJson ? JSON.stringify(t) : t}`;
        return Promise.reject(new Error(msg));
      });
    }
  }

  static parseHeader(res: Response | Request): Record<string, string> {
    return Array.from(res.headers.entries())
      .reduce((acc, [k, v]) => {
        const key = k
          .toLowerCase()
          .replace(/-(\w)/g, (...m) => m[1].toUpperCase());
        acc[key] = v;
        return acc;
      }, {} as Record<string, string>);
  }
}