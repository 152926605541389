import { Ellipse, Polygon } from 'pixi.js';
import { useStorage, CancelChangeFn } from '@library/storage';
import { State } from '@library/fsm';
import { ThrowStateName, TargetBoardScore, ScoreRules, GameScoreRule, ScoreChange, Gender } from './config';
import { CompleteTaskFn, TaskName } from '@/task';

export interface Position {
  x: number;
  y: number;
}

export interface ThrowDart {
  onAngle(changeFn: (data: number) => void): CancelChangeFn;
  onStrength(changeFn: (data: number) => void): CancelChangeFn;
  onScore(changeFn: (data: number) => void): CancelChangeFn;
  onDartPosition(changeFn: (data: Position) => void): CancelChangeFn;
  onState(changeFn: (stateName: string) => void): CancelChangeFn;
  isState(stateName: string): boolean;
  nextState(): void;
  isGender(gender: string): boolean;
  setGender(gender: string): void;
  getScoreRule(): GameScoreRule;
  reset(): void;
}

export function useThrowDart(completeTaskFn: CompleteTaskFn): ThrowDart {
  const gender = useStorage<string>();
  const score = useStorage<number>({ initValue: 0 });
  const angle = useStorage<number>({ initValue: 0 });
  const strength = useStorage<number>({ initValue: 0 });
  const dartDefaultPosition = { x: 205, y: 613 };
  const dartPosition = useStorage<Position>({ initValue: { ...dartDefaultPosition } });

  function completeTask() {
    const taskNameMap: Record<string, TaskName> = {
      [Gender.boy]: 'taskMaleGame',
      [Gender.girl]: 'taskFemaleGame',
    };
    completeTaskFn(taskNameMap[gender.value]);
  }

  const start = State.of({ name: ThrowStateName.start, next: () => chosingAngle });
  const delta = 17;

  let angleTickerId: any;
  const chosingAngle = State.of({
    name: ThrowStateName.chosingAngle,
    next: () => chosingStrength,
    onEnter() {
      dartPosition.value = { ...dartDefaultPosition };
      const min = -30, max = 30, store = angle;
      let unit = 1;
      store.value = 0;
      angleTickerId = setInterval(() => {
        store.value = store.value + unit;
        if (store.value >= max || store.value <= min) {
          unit = unit * -1
        }
      }, delta);
    },
    onLeave() {
      clearInterval(angleTickerId);
    },
  });

  let strengthTickerId: any;
  const chosingStrength = State.of({
    name: ThrowStateName.chosingStrength,
    next: () => dartFlying,
    onEnter() {
      const min = 0, max = 100, store = strength;
      let unit = 1.7;
      store.value = 0;
      strengthTickerId = setInterval(() => {
        store.value = store.value + unit;
        if (store.value >= max || store.value <= min) {
          unit = unit * -1
        }
      }, delta);
    },
    onLeave() {
      clearInterval(strengthTickerId);
    },
  });

  let isDartFlying = false;
  let dartFlyingTickerId: any;
  const dartFlying = State.of({
    name: ThrowStateName.dartFlying,
    onEnter() {
      const flyingTime = 560;
      let flyingFrame = 0;
      const dartOrigin: Position = { ...dartPosition.value };
      const dartIncrementUnit = calcDartIncrementUnit(
        150 + strength.value * 3,
        angle.value,
        flyingTime / delta | 0
      );

      isDartFlying = true;
      dartFlyingTickerId = setInterval(() => {
        dartPosition.value = {
          x: dartOrigin.x + dartIncrementUnit.x * flyingFrame,
          y: dartOrigin.y + dartIncrementUnit.y * flyingFrame,
        };
        flyingFrame = flyingFrame + 1;
      }, delta);

      setTimeout(() => {
        clearInterval(dartFlyingTickerId);
        isDartFlying = false;
        nextState();
      }, flyingTime);
    },
    canNext: () => !isDartFlying,
    next: () => scoring,
  });

  let isScoring = false;
  let scoreChange: ScoreChange = { before: score.value, after: score.value };
  const scoring = State.of({
    name: ThrowStateName.scoring,
    onEnter() {
      isScoring = true;
      const dartHeadOffset = calcDartIncrementUnit(95, angle.value, 1);
      const dartHeadPosition: Position = {
        x: dartPosition.value.x + dartHeadOffset.x,
        y: dartPosition.value.y + dartHeadOffset.y
      };
      const hitScore = calcHitScore(dartHeadPosition);
      scoreChange = { before: score.value, after: score.value + hitScore };
      score.value = scoreChange.after;
      console.log({ hitScore, score: score.value, });
      setTimeout(() => {
        isScoring = false;
        nextState();
      }, 1000);
    },
    canNext: () => !isScoring,
    next: () => isHigherScoreRule(gender.value, scoreChange) ? result : chosingAngle,
  });
  function isHigherScoreRule(g: string, change: ScoreChange) {
    const beforeScoreRule = findClosestScoreRule(g, change.before);
    const afterScoreRule = findClosestScoreRule(g, change.after);
    return change.before < change.after && beforeScoreRule !== afterScoreRule;
  }

  const result = State.of({
    name: ThrowStateName.result,
    next: () => isFinalScoreRule() ? end : chosingAngle,
  });
  function isFinalScoreRule() {
    return getScoreRule() === findHighestGenderScoreRule(gender.value);
  }

  const end = State.of({
    name: ThrowStateName.end,
    onEnter: completeTask,
  });

  const state = useStorage<State>({ initValue: start });

  function nextState() {
    state.value = state.value.next();
  }

  function getScoreRule(): GameScoreRule {
    return findClosestScoreRule(gender.value, score.value);
  }

  function findClosestScoreRule(g: string, s: number): GameScoreRule {
    return ScoreRules.find(r => g === r.gender && s >= r.requiredScore)!;
  }
  function findHighestGenderScoreRule(g: string): GameScoreRule {
    return ScoreRules
      .filter(a => a.gender === g)
      .sort((a, b) => a.requiredScore > b.requiredScore ? -1 : 1)[0];
  }

  function reset() {
    gender.cleanObserver();
    gender.value = '';
    score.cleanObserver();
    score.value = 0;
    angle.cleanObserver();
    angle.value = 0;
    strength.cleanObserver();
    strength.value = 0;
    dartPosition.cleanObserver();
    dartPosition.value = { ...dartDefaultPosition };
    state.cleanObserver();
    state.value = start;

    console.log('reset', {
      gender: gender.value,
      score: score.value,
      angle: angle.value,
      strength: strength.value,
      dartPosition: dartPosition.value,
    });
  }

  return {
    onAngle: angle.onChange,
    onStrength: strength.onChange,
    onScore: score.onChange,
    onDartPosition: dartPosition.onChange,
    onState: (cb: (stateName: string) => void) => state.onChange(s => cb(s.name)),
    isState: (stateName: string) => state.value.name === stateName,
    nextState,
    isGender: (g: string) => gender.value === g,
    setGender(g: string) {
      console.log('gender', g);
      gender.value = g;
    },
    getScoreRule,
    reset,
  }
}

function calcDartIncrementUnit(hypotenuseLength: number, angle: number, flyingFrames: number): Position {
  const isAnglePlusMinusSign = angle > 0 ? 1 : -1;
  const cos = Math.cos(2 * Math.PI / 360 * (90 - isAnglePlusMinusSign * angle));
  const xIncrement = (cos * hypotenuseLength) * isAnglePlusMinusSign;
  const unit: Position = {
    x: xIncrement / flyingFrames,
    y: (Math.sqrt(hypotenuseLength * hypotenuseLength - xIncrement * xIncrement)) * -1 / flyingFrames,
  };
  return unit;
}

function calcHitScore(hitPosition: Position): number {
  const hitAreaConfig = hitAreaConfigs.find(c => c.hitArea.contains(hitPosition.x, hitPosition.y));
  return hitAreaConfig
    ? hitAreaConfig.score
    : 0;
}

const hitAreaConfigs = Object.keys(TargetBoardScore)
  .map(name => {
    const [n, shapType, scoreStr] = name.match(/^(ellipse|polygon)_.+_(\d+)$/)!;
    const shapPoints = TargetBoardScore[name];
    let hitArea = null;
    if (shapType === 'ellipse') {
      hitArea = new Ellipse(...shapPoints);
    } else if (shapType === 'polygon') {
      hitArea = new Polygon(shapPoints);
    } else {
      throw new Error(`${name} is unsupported name. RegExp: /^(ellipse|polygon)_.+_(\\d+)$/`);
    }
    return { score: +scoreStr, hitArea };
  });
