import { Btn, RelatedHref, Slide } from '@library/slide-board';

const FullScreenShap = { w: 414, h: 736, x: 0, y: 0 };
const FullScreenNextBtn: Btn[] = [{ href: RelatedHref.next, shap: FullScreenShap }];

export const MAGIC_JOHNSON_SLIDE: Slide[] = [
  { id: '1', bg: '/story/magic-johnson/1.jpg', btn: FullScreenNextBtn, },
  { id: '2', bg: '/story/magic-johnson/2.jpg', btn: FullScreenNextBtn, },
  { id: '2.1', bg: '/story/magic-johnson/2.1.jpg', btn: FullScreenNextBtn, },
  { id: '2.2', bg: '/story/magic-johnson/2.2.jpg', btn: FullScreenNextBtn, },
  { id: '3', bg: '/story/magic-johnson/3.jpg', btn: FullScreenNextBtn, },
  { id: '4', bg: '/story/magic-johnson/4.jpg', btn: FullScreenNextBtn, },
  { id: '4.1', bg: '/story/magic-johnson/4.1.jpg', btn: FullScreenNextBtn, },
  { id: '4.2', bg: '/story/magic-johnson/4.2.jpg', btn: FullScreenNextBtn, },
  { id: '4.3', bg: '/story/magic-johnson/4.3.jpg', btn: FullScreenNextBtn, },
  { id: '4.4', bg: '/story/magic-johnson/4.4.jpg', btn: FullScreenNextBtn, },
  { id: '5', bg: '/story/magic-johnson/5.jpg', btn: FullScreenNextBtn, },
  { id: '6', bg: '/story/magic-johnson/6.jpg', btn: FullScreenNextBtn, },
  { id: '6.1', bg: '/story/magic-johnson/6.1.jpg', btn: FullScreenNextBtn, },
  { id: '7', bg: '/story/magic-johnson/7.jpg', btn: FullScreenNextBtn, },
  { id: '7.1', bg: '/story/magic-johnson/7.1.jpg', btn: FullScreenNextBtn, },
  { id: '7.2', bg: '/story/magic-johnson/7.2.jpg', btn: FullScreenNextBtn, },
  { id: '7.3', bg: '/story/magic-johnson/7.3.jpg', btn: FullScreenNextBtn, },
  { id: '7.4', bg: '/story/magic-johnson/7.4.jpg', btn: FullScreenNextBtn, },
  { id: '8', bg: '/story/magic-johnson/8.jpg', btn: FullScreenNextBtn, },
  { id: '8.1', bg: '/story/magic-johnson/8.1.jpg', btn: FullScreenNextBtn, },
  { id: '8.2', bg: '/story/magic-johnson/8.2.jpg', btn: FullScreenNextBtn, },
  { id: '9', bg: '/story/magic-johnson/9.jpg', btn: FullScreenNextBtn, },
  { id: '9.1', bg: '/story/magic-johnson/9.1.jpg', btn: FullScreenNextBtn, },
  { id: '9.2', bg: '/story/magic-johnson/9.2.jpg', btn: FullScreenNextBtn, },
  { id: '9.3', bg: '/story/magic-johnson/9.3.jpg', btn: FullScreenNextBtn, },
  { id: '9.4', bg: '/story/magic-johnson/9.4.jpg', btn: FullScreenNextBtn, },
  { id: '9.5', bg: '/story/magic-johnson/9.5.jpg', btn: FullScreenNextBtn, },
  { id: '9.6', bg: '/story/magic-johnson/9.6.jpg', btn: FullScreenNextBtn, },
  { id: '9.7', bg: '/story/magic-johnson/9.7.jpg', btn: FullScreenNextBtn, },
  { id: '9.8', bg: '/story/magic-johnson/9.8.jpg', btn: FullScreenNextBtn, },
  { id: '10', bg: '/story/magic-johnson/10.jpg', btn: FullScreenNextBtn, },
  { id: '11', bg: '/story/magic-johnson/11.jpg', btn: FullScreenNextBtn, },
  { id: '11.1', bg: '/story/magic-johnson/11.1.jpg', btn: FullScreenNextBtn, },
  { id: '11.2', bg: '/story/magic-johnson/11.2.jpg', btn: FullScreenNextBtn, },
  { id: '11.3', bg: '/story/magic-johnson/11.3.jpg', btn: FullScreenNextBtn, },
  { id: '11.4', bg: '/story/magic-johnson/11.4.jpg', btn: FullScreenNextBtn, },
  { id: '11.5', bg: '/story/magic-johnson/11.5.jpg', btn: FullScreenNextBtn, },
  { id: '12', bg: '/story/magic-johnson/12.jpg', btn: FullScreenNextBtn, },
  { id: '12.1', bg: '/story/magic-johnson/12.1.jpg', btn: FullScreenNextBtn, },
  { id: '12.2', bg: '/story/magic-johnson/12.2.jpg', btn: FullScreenNextBtn, },
  { id: '12.3', bg: '/story/magic-johnson/12.3.jpg', btn: FullScreenNextBtn, },
  { id: '12.4', bg: '/story/magic-johnson/12.4.jpg', btn: FullScreenNextBtn, },
  { id: '12.5', bg: '/story/magic-johnson/12.5.jpg', btn: FullScreenNextBtn, },
  { id: '12.6', bg: '/story/magic-johnson/12.6.jpg', btn: FullScreenNextBtn, },
  { id: '12.7', bg: '/story/magic-johnson/12.7.jpg', btn: FullScreenNextBtn, },
  { id: '13', bg: '/story/magic-johnson/13.jpg', btn: FullScreenNextBtn, },
  { id: '13.1', bg: '/story/magic-johnson/13.1.jpg', btn: FullScreenNextBtn, },
  { id: '13.2', bg: '/story/magic-johnson/13.2.jpg', btn: FullScreenNextBtn, },
  { id: '14', bg: '/story/magic-johnson/14.jpg', btn: FullScreenNextBtn, },
  { id: '15', bg: '/story/magic-johnson/15.jpg', btn: FullScreenNextBtn, },
  { id: '15.1', bg: '/story/magic-johnson/15.1.jpg', btn: FullScreenNextBtn, },
  { id: '15.2', bg: '/story/magic-johnson/15.2.jpg', btn: FullScreenNextBtn, },
  { id: '16', bg: '/story/magic-johnson/16.jpg', btn: FullScreenNextBtn, },
  { id: '16.1', bg: '/story/magic-johnson/16.1.jpg', btn: FullScreenNextBtn, },
  { id: '18', bg: '/story/magic-johnson/18.jpg', btn: FullScreenNextBtn, },
  { id: '18.1', bg: '/story/magic-johnson/18.1.jpg', btn: FullScreenNextBtn, },
  { id: '19', bg: '/story/magic-johnson/19.jpg', btn: FullScreenNextBtn, },
  { id: '19.1', bg: '/story/magic-johnson/19.1.jpg', btn: FullScreenNextBtn, },
  { id: '19.2', bg: '/story/magic-johnson/19.2.jpg', btn: FullScreenNextBtn, },
  { id: '19.3', bg: '/story/magic-johnson/19.3.jpg', btn: FullScreenNextBtn, },
  { id: '19.4', bg: '/story/magic-johnson/19.4.jpg', btn: FullScreenNextBtn, },
  { id: '19.5', bg: '/story/magic-johnson/19.5.jpg', btn: FullScreenNextBtn, },
  { id: '19.6', bg: '/story/magic-johnson/19.6.jpg', btn: FullScreenNextBtn, },
  { id: '19.7', bg: '/story/magic-johnson/19.7.jpg', btn: FullScreenNextBtn, },
  { id: '19.8', bg: '/story/magic-johnson/19.8.jpg', btn: FullScreenNextBtn, },
  { id: '19.9', bg: '/story/magic-johnson/19.9.jpg', btn: FullScreenNextBtn, },
  { id: '19.10', bg: '/story/magic-johnson/19.10.jpg', btn: FullScreenNextBtn, },
  { id: '19.11', bg: '/story/magic-johnson/19.11.jpg', btn: FullScreenNextBtn, },
  { id: '19.12', bg: '/story/magic-johnson/19.12.jpg', btn: FullScreenNextBtn, },
  { id: '19.13', bg: '/story/magic-johnson/19.13.jpg', btn: FullScreenNextBtn, },
  { id: '19.14', bg: '/story/magic-johnson/19.14.jpg', btn: FullScreenNextBtn, },
  { id: '19.15', bg: '/story/magic-johnson/19.15.jpg', btn: FullScreenNextBtn, },
  { id: '20', bg: '/story/magic-johnson/20.jpg', btn: FullScreenNextBtn, },
  { id: '20.1', bg: '/story/magic-johnson/20.1.jpg', btn: FullScreenNextBtn, },
  { id: '20.2', bg: '/story/magic-johnson/20.2.jpg', btn: FullScreenNextBtn, },
  { id: '20.3', bg: '/story/magic-johnson/20.3.jpg', btn: FullScreenNextBtn, },
  { id: '21', bg: '/story/magic-johnson/21.jpg', btn: FullScreenNextBtn, },
  { id: '21.1', bg: '/story/magic-johnson/21.1.jpg', btn: FullScreenNextBtn, },
  { id: '21.2', bg: '/story/magic-johnson/21.2.jpg', btn: FullScreenNextBtn, },
  { id: '22', bg: '/story/magic-johnson/22.jpg', btn: FullScreenNextBtn, },
  { id: '22.1', bg: '/story/magic-johnson/22.1.jpg', btn: FullScreenNextBtn, },
  { id: '22.2', bg: '/story/magic-johnson/22.2.jpg', btn: FullScreenNextBtn, },
  { id: '23', bg: '/story/magic-johnson/23.jpg', btn: FullScreenNextBtn, },
  { id: '23.1', bg: '/story/magic-johnson/23.1.jpg', btn: FullScreenNextBtn, },
  { id: '23.2', bg: '/story/magic-johnson/23.2.jpg', btn: FullScreenNextBtn, },
  { id: '23.3', bg: '/story/magic-johnson/23.3.jpg', btn: FullScreenNextBtn, },
  { id: '24', bg: '/story/magic-johnson/24.jpg', btn: FullScreenNextBtn, },
  { id: '24.1', bg: '/story/magic-johnson/24.1.jpg', btn: FullScreenNextBtn, },
  { id: '24.2', bg: '/story/magic-johnson/24.2.jpg', btn: FullScreenNextBtn, },
  { id: '25', bg: '/story/magic-johnson/25.jpg', btn: FullScreenNextBtn, },
  { id: '25.1', bg: '/story/magic-johnson/25.1.jpg', btn: FullScreenNextBtn, },
  { id: '26', bg: '/story/magic-johnson/26.jpg', btn: FullScreenNextBtn, },
  { id: '26.1', bg: '/story/magic-johnson/26.1.jpg', btn: FullScreenNextBtn, },
  { id: '27', bg: '/story/magic-johnson/27.jpg', btn: FullScreenNextBtn, },
  { id: '27.1', bg: '/story/magic-johnson/27.1.jpg', btn: FullScreenNextBtn, },
  { id: '27.2', bg: '/story/magic-johnson/27.2.jpg', btn: FullScreenNextBtn, },
  { id: '28', bg: '/story/magic-johnson/28.jpg', btn: FullScreenNextBtn, },
  { id: '28.1', bg: '/story/magic-johnson/28.1.jpg', btn: FullScreenNextBtn, },
  { id: '28.2', bg: '/story/magic-johnson/28.2.jpg', btn: FullScreenNextBtn, },
  { id: '29', bg: '/story/magic-johnson/29.jpg', btn: FullScreenNextBtn, },
  { id: '29.1', bg: '/story/magic-johnson/29.1.jpg', btn: FullScreenNextBtn, },
  { id: '30', bg: '/story/magic-johnson/30.jpg', btn: FullScreenNextBtn, },
  { id: '30.1', bg: '/story/magic-johnson/30.1.jpg', btn: FullScreenNextBtn, },
  { id: '31', bg: '/story/magic-johnson/31.jpg', btn: FullScreenNextBtn, },
  { id: '31.1', bg: '/story/magic-johnson/31.1.jpg', btn: FullScreenNextBtn, },
  { id: '31.2', bg: '/story/magic-johnson/31.2.jpg', btn: FullScreenNextBtn, },
  { id: '32', bg: '/story/magic-johnson/32.jpg', btn: FullScreenNextBtn, },
  { id: '32.1', bg: '/story/magic-johnson/32.1.jpg', btn: [{ href: RelatedHref.close, shap: FullScreenShap }], },
];
