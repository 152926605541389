import { Game } from '@library/game';

import { SceneName } from './config';
import { PreloadScene } from './PreloadScene';
import { PregameScene } from './PregameScene';
import { IntroScene } from './IntroScene';
import { ThrowDartsScene } from './ThrowDartsScene';
import { ResultScene } from './ResultScene';
import { EndgameScene } from './EndgameScene';
import { useThrowDart } from './useThrowDarts';
import { CompleteTaskFn } from '@/task';

export function makeGame(completeTaskFn: CompleteTaskFn) {
  const useCase = useThrowDart(completeTaskFn);
  const game = Game.of({
    declaration: [{
      sceneName: '',
      redirectTo: SceneName.PreloadScene,
    }, {
      sceneName: SceneName.PreloadScene,
      useFactory: () => new PreloadScene(game, game.app.loader, game.app.renderer),
    }, {
      sceneName: SceneName.PregameScene,
      useFactory: () => new PregameScene(game, game.spriteStorage, useCase),
    }, {
      sceneName: SceneName.IntroScene,
      useFactory: () => new IntroScene(game, game.spriteStorage),
    }, {
      sceneName: SceneName.ThrowDartsScene,
      useFactory: () => new ThrowDartsScene(game, game.spriteStorage, useCase),
    }, {
      sceneName: SceneName.ResultScene,
      useFactory: () => new ResultScene(game, game.spriteStorage, useCase),
    }, {
      sceneName: SceneName.EndgameScene,
      useFactory: () => new EndgameScene(game, game.spriteStorage, useCase),
    }],
  });
  return game;
}
