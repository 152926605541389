export class AuthStorage {
  static tokenKey = '$token';

  getToken(): string | null {
    return localStorage.getItem(AuthStorage.tokenKey);
  }

  setToken(token: string) {
    if (token) {
      localStorage.setItem(AuthStorage.tokenKey, token);
    } else {
      localStorage.removeItem(AuthStorage.tokenKey);
    }
  }
}

export function makeHeaderWithAuthorization(): HeadersInit {
  return { 'Content-Type': 'application/json', Authorization: localStorage.getItem(AuthStorage.tokenKey)! };
}
