export interface Step {
  name: StepName;
  stage: number;
}

export interface Rule {
  title?: string;
  description?: string;
  score?: Score;
}

export interface QuizConfig {
  quizId: string;
  question: string;
  option: Answer[];
  answer: Answer;
  tips: string;
  reference?: {
    label: string;
    link: string;
  };
}

export type Answer = 'yes' | 'no';

export interface Quiz {
  quizId?: string;
  title?: string;
  description?: string;
  option?: string[];
  score?: Score;
}

export interface QuizResponse {
  quizId: string;
  replyAnswer: Answer;
}

export interface Result {
  title?: string;
  description?: string;
  reference?: {
    label: string;
    link: string;
  };
  whetherToScore?: boolean;
  score?: Score;
}

export interface Score {
  yes: number;
  no: number;
}

export enum StepName {
  showStart = 'showStart',
  getRule = 'getRule',
  pickQuiz = 'pickQuiz',
  getResult = 'getResult',
  showAnimat = 'showAnimat',
  showGameOver = 'showGameOver',
}

export type AnimatName = 'shotScore' | 'shotNoScore' | 'slamDunkScore' | 'slamDunkNoScore';

export interface Animat {
  name: AnimatName;
  frame: string[];
}

export interface ImgModalData {
  bgSrc?: string;
  title?: string;
  message?: string;
  btn?: {
    onClose?: (() => void);
    src?: string;
  }[]
}
