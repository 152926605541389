import { Btn, RelatedHref, Slide } from '@library/slide-board';

const FullScreenShap = { w: 414, h: 736, x: 0, y: 0 };
const FullScreenNextBtn: Btn[] = [{ href: RelatedHref.next, shap: FullScreenShap }];
const FullScreenCloseBtn: Btn[] = [{ href: RelatedHref.close, shap: FullScreenShap }];

const PREGNANCY_GOODBOY_SLIDE: Slide[] = [
  { id: 'goodboy_1', bg: '/story/pregnancy/goodboy/1.jpg', btn: FullScreenNextBtn, },
  { id: 'goodboy_1.1', bg: '/story/pregnancy/goodboy/1.1.jpg', btn: FullScreenNextBtn },
  { id: 'goodboy_2', bg: '/story/pregnancy/goodboy/2.jpg', btn: FullScreenNextBtn },
  { id: 'goodboy_3', bg: '/story/pregnancy/goodboy/3.jpg', btn: FullScreenNextBtn },
  {
    id: 'goodboy_4', bg: '/story/pregnancy/goodboy/4.jpg',
    btn: [
      {
        href: 'goodboy_6.1',
        shap: { x: 71, y: 343, w: 125, h: 56 },
      }, {
        href: RelatedHref.next,
        shap: { x: 215, y: 343, w: 125, h: 57 },
      }
    ]
  },
  { id: 'goodboy_5', bg: '/story/pregnancy/goodboy/5.jpg', btn: FullScreenNextBtn },
  { id: 'goodboy_5.1', bg: '/story/pregnancy/goodboy/5.1.jpg', btn: FullScreenNextBtn },
  { id: 'goodboy_5.2', bg: '/story/pregnancy/goodboy/5.2.jpg', btn: FullScreenNextBtn },
  { id: 'goodboy_5.3', bg: '/story/pregnancy/goodboy/5.3.jpg', btn: FullScreenNextBtn },
  { id: 'goodboy_5.4', bg: '/story/pregnancy/goodboy/5.4.jpg', btn: FullScreenNextBtn },
  { id: 'goodboy_5.5', bg: '/story/pregnancy/goodboy/5.5.jpg', btn: FullScreenNextBtn },
  { id: 'goodboy_6.1', bg: '/story/pregnancy/goodboy/6.1.jpg', btn: FullScreenNextBtn },
  { id: 'goodboy_6.2', bg: '/story/pregnancy/goodboy/6.2.jpg', btn: FullScreenNextBtn },
  { id: 'goodboy_6.3', bg: '/story/pregnancy/goodboy/6.3.jpg', btn: FullScreenNextBtn },
  { id: 'goodboy_7', bg: '/story/pregnancy/goodboy/7.jpg', btn: FullScreenNextBtn },
  { id: 'goodboy_8', bg: '/story/pregnancy/goodboy/8.jpg', btn: FullScreenNextBtn },
  { id: 'goodboy_9', bg: '/story/pregnancy/goodboy/9.jpg', btn: FullScreenNextBtn },
  {
    id: 'goodboy_10', bg: '/story/pregnancy/goodboy/10.jpg',
    btn: [
      {
        href: RelatedHref.next,
        shap: { x: 62, y: 370, w: 124, h: 67 },
      }, {
        href: 'goodboy_11',
        shap: { x: 229, y: 370, w: 124, h: 67 },
      }
    ]
  },
  { id: 'goodboy_10.1', bg: '/story/pregnancy/goodboy/10.1.jpg', btn: FullScreenNextBtn },
  { id: 'goodboy_10.2', bg: '/story/pregnancy/goodboy/10.2.jpg', btn: FullScreenNextBtn },
  { id: 'goodboy_11', bg: '/story/pregnancy/goodboy/11.jpg', btn: FullScreenNextBtn },
  {
    id: 'goodboy_12', bg: '/story/pregnancy/goodboy/12.jpg',
    btn: [
      {
        href: RelatedHref.next,
        shap: { x: 84, y: 494, w: 107, h: 46 },
      }, {
        href: 'goodboy_13.3.1',
        shap: { x: 216, y: 492, w: 93, h: 45 },
      }, {
        href: 'goodboy_13.4.1',
        shap: { x: 43, y: 568, w: 148, h: 48 },
      }, {
        href: 'goodboy_13.2.1',
        shap: { x: 216, y: 566, w: 127, h: 50 },
      }
    ]
  },
  { id: 'goodboy_13.1.1', bg: '/story/pregnancy/goodboy/13.1.1.jpg', btn: FullScreenNextBtn },
  { id: 'goodboy_13.1.2', bg: '/story/pregnancy/goodboy/13.1.2.jpg', btn: FullScreenNextBtn },
  { id: 'goodboy_13.1.3', bg: '/story/pregnancy/goodboy/13.1.3.jpg', btn: FullScreenNextBtn },
  { id: 'goodboy_13.1.4', bg: '/story/pregnancy/goodboy/13.1.4.jpg', btn: FullScreenNextBtn },
  { id: 'goodboy_13.1.5', bg: '/story/pregnancy/goodboy/13.1.5.jpg', btn: [{ href: 'goodboy_14', shap: FullScreenShap, }] },
  { id: 'goodboy_13.2.1', bg: '/story/pregnancy/goodboy/13.2.1.jpg', btn: FullScreenNextBtn },
  { id: 'goodboy_13.2.2', bg: '/story/pregnancy/goodboy/13.2.2.jpg', btn: FullScreenNextBtn },
  { id: 'goodboy_13.2.3', bg: '/story/pregnancy/goodboy/13.2.3.jpg', btn: FullScreenNextBtn },
  { id: 'goodboy_13.2.4', bg: '/story/pregnancy/goodboy/13.2.4.jpg', btn: FullScreenNextBtn },
  { id: 'goodboy_13.2.5', bg: '/story/pregnancy/goodboy/13.2.5.jpg', btn: [{ href: 'goodboy_14', shap: FullScreenShap, }] },
  { id: 'goodboy_13.3.1', bg: '/story/pregnancy/goodboy/13.3.1.jpg', btn: FullScreenNextBtn },
  { id: 'goodboy_13.3.2', bg: '/story/pregnancy/goodboy/13.3.2.jpg', btn: FullScreenNextBtn },
  { id: 'goodboy_13.3.3', bg: '/story/pregnancy/goodboy/13.3.3.jpg', btn: FullScreenNextBtn },
  { id: 'goodboy_13.3.4', bg: '/story/pregnancy/goodboy/13.3.4.jpg', btn: FullScreenNextBtn },
  { id: 'goodboy_13.3.5', bg: '/story/pregnancy/goodboy/13.3.5.jpg', btn: FullScreenNextBtn },
  { id: 'goodboy_13.3.6', bg: '/story/pregnancy/goodboy/13.3.6.jpg', btn: FullScreenNextBtn },
  { id: 'goodboy_13.3.7', bg: '/story/pregnancy/goodboy/13.3.7.jpg', btn: FullScreenNextBtn },
  { id: 'goodboy_13.3.8', bg: '/story/pregnancy/goodboy/13.3.8.jpg', btn: [{ href: 'goodboy_14', shap: FullScreenShap, }] },
  { id: 'goodboy_13.4.1', bg: '/story/pregnancy/goodboy/13.4.1.jpg', btn: FullScreenNextBtn },
  { id: 'goodboy_13.4.2', bg: '/story/pregnancy/goodboy/13.4.2.jpg', btn: FullScreenNextBtn },
  { id: 'goodboy_13.4.3', bg: '/story/pregnancy/goodboy/13.4.3.jpg', btn: FullScreenNextBtn },
  { id: 'goodboy_13.4.4', bg: '/story/pregnancy/goodboy/13.4.4.jpg', btn: [{ href: 'goodboy_14', shap: FullScreenShap, }] },
  { id: 'goodboy_14', bg: '/story/pregnancy/goodboy/14.jpg', btn: FullScreenNextBtn },
  { id: 'goodboy_15', bg: '/story/pregnancy/goodboy/15.jpg', btn: FullScreenNextBtn },
  { id: 'goodboy_16.1', bg: '/story/pregnancy/goodboy/16.1.jpg', btn: FullScreenNextBtn },
  { id: 'goodboy_16.2', bg: '/story/pregnancy/goodboy/16.2.jpg', btn: FullScreenNextBtn },
  { id: 'goodboy_16.3', bg: '/story/pregnancy/goodboy/16.3.jpg', btn: FullScreenNextBtn },
  { id: 'goodboy_16.4', bg: '/story/pregnancy/goodboy/16.4.jpg', btn: FullScreenNextBtn },
  { id: 'goodboy_16.5', bg: '/story/pregnancy/goodboy/16.5.jpg', btn: FullScreenNextBtn },
  { id: 'goodboy_16.6', bg: '/story/pregnancy/goodboy/16.6.jpg', btn: FullScreenNextBtn },
  { id: 'goodboy_17.1', bg: '/story/pregnancy/goodboy/17.1.jpg', btn: FullScreenNextBtn },
  { id: 'goodboy_17.2', bg: '/story/pregnancy/goodboy/17.2.jpg', btn: FullScreenNextBtn },
  { id: 'goodboy_17.3', bg: '/story/pregnancy/goodboy/17.3.jpg', btn: FullScreenNextBtn },
  { id: 'goodboy_17.4', bg: '/story/pregnancy/goodboy/17.4.jpg', btn: FullScreenNextBtn },
  { id: 'goodboy_17.5', bg: '/story/pregnancy/goodboy/17.5.jpg', btn: FullScreenNextBtn },
  { id: 'goodboy_17.6', bg: '/story/pregnancy/goodboy/17.6.jpg', btn: FullScreenNextBtn },
  { id: 'goodboy_17.7', bg: '/story/pregnancy/goodboy/17.7.jpg', btn: FullScreenNextBtn },
  { id: 'goodboy_17.8', bg: '/story/pregnancy/goodboy/17.8.jpg', btn: FullScreenNextBtn },
  { id: 'goodboy_17.9', bg: '/story/pregnancy/goodboy/17.9.jpg', btn: FullScreenNextBtn },
  { id: 'goodboy_18.1.1', bg: '/story/pregnancy/goodboy/18.1.1.jpg', btn: FullScreenNextBtn },
  { id: 'goodboy_18.1.2', bg: '/story/pregnancy/goodboy/18.1.2.jpg', btn: FullScreenNextBtn },
  {
    id: 'goodboy_18.2', bg: '/story/pregnancy/goodboy/18.2.jpg',
    btn: [
      {
        href: 'goodboy_21',
        shap: { x: 72, y: 286, w: 119, h: 55 },
      }, {
        href: 'goodboy_22.1',
        shap: { x: 222, y: 286, w: 120, h: 55 },
      }, {
        href: RelatedHref.next,
        shap: { x: 123, y: 359, w: 168, h: 55 },
      },
    ]
  },
  { id: 'goodboy_20.1', bg: '/story/pregnancy/goodboy/20.1.jpg', btn: FullScreenNextBtn },
  { id: 'goodboy_20.2', bg: '/story/pregnancy/goodboy/20.2.jpg', btn: FullScreenNextBtn },
  {
    id: 'goodboy_21', bg: '/story/pregnancy/goodboy/21.jpg',
    btn: [
      {
        href: 'goodboy_23.1',
        shap: { x: 109, y: 311, w: 198, h: 75 },
      }, {
        href: 'goodboy_22.6',
        shap: { x: 109, y: 420, w: 198, h: 75 },
      },
    ]
  },
  { id: 'goodboy_22.1', bg: '/story/pregnancy/goodboy/22.1.jpg', btn: FullScreenNextBtn },
  { id: 'goodboy_22.2', bg: '/story/pregnancy/goodboy/22.2.jpg', btn: FullScreenNextBtn },
  { id: 'goodboy_22.3', bg: '/story/pregnancy/goodboy/22.3.jpg', btn: FullScreenNextBtn },
  { id: 'goodboy_22.4', bg: '/story/pregnancy/goodboy/22.4.jpg', btn: FullScreenNextBtn },
  { id: 'goodboy_22.5', bg: '/story/pregnancy/goodboy/22.5.jpg', btn: [{ href: 'goodboy_24', shap: FullScreenShap }] },
  { id: 'goodboy_22.6', bg: '/story/pregnancy/goodboy/22.6.jpg', btn: FullScreenNextBtn },
  { id: 'goodboy_22.7', bg: '/story/pregnancy/goodboy/22.7.jpg', btn: [{ href: 'goodboy_24', shap: FullScreenShap }] },
  { id: 'goodboy_23.1', bg: '/story/pregnancy/goodboy/23.1.jpg', btn: FullScreenNextBtn },
  { id: 'goodboy_23.2', bg: '/story/pregnancy/goodboy/23.2.jpg', btn: FullScreenNextBtn },
  { id: 'goodboy_23.3', bg: '/story/pregnancy/goodboy/23.3.jpg', btn: [{ href: 'goodboy_24', shap: FullScreenShap }] },
  { id: 'goodboy_24', bg: '/story/pregnancy/goodboy/24.jpg', btn: FullScreenNextBtn },
  { id: 'goodboy_finished', bg: '/story/pregnancy/goodboy/25.jpg', btn: FullScreenCloseBtn },
];

const PREGNANCY_BADBOY_SLIDE: Slide[] = [
  { id: 'badboy_1', bg: '/story/pregnancy/badboy/1.jpg', btn: FullScreenNextBtn },
  { id: 'badboy_1.1', bg: '/story/pregnancy/badboy/1.1.jpg', btn: FullScreenNextBtn },
  { id: 'badboy_2', bg: '/story/pregnancy/badboy/2.jpg', btn: FullScreenNextBtn },
  { id: 'badboy_2.1', bg: '/story/pregnancy/badboy/2.1.jpg', btn: FullScreenNextBtn },
  { id: 'badboy_3', bg: '/story/pregnancy/badboy/3.jpg', btn: FullScreenNextBtn },
  { id: 'badboy_4', bg: '/story/pregnancy/badboy/4.jpg', btn: FullScreenNextBtn },
  { id: 'badboy_5', bg: '/story/pregnancy/badboy/5.jpg', btn: FullScreenNextBtn },
  { id: 'badboy_6', bg: '/story/pregnancy/badboy/6.jpg', btn: FullScreenNextBtn },
  {
    id: 'badboy_7', bg: '/story/pregnancy/badboy/7.jpg', btn: [
      {
        href: 'badboy_9',
        shap: { x: 71, y: 343, w: 125, h: 56 },
      },
      {
        href: 'badboy_8',
        shap: { x: 215, y: 342, w: 125, h: 57 },
      },
    ]
  },
  { id: 'badboy_8', bg: '/story/pregnancy/badboy/8.jpg', btn: FullScreenNextBtn },
  { id: 'badboy_8.1', bg: '/story/pregnancy/badboy/8.1.jpg', btn: FullScreenNextBtn },
  { id: 'badboy_9', bg: '/story/pregnancy/badboy/9.jpg', btn: FullScreenNextBtn },
  { id: 'badboy_9.1', bg: '/story/pregnancy/badboy/9.1.jpg', btn: FullScreenNextBtn },
  { id: 'badboy_9.2', bg: '/story/pregnancy/badboy/9.2.jpg', btn: FullScreenNextBtn },
  { id: 'badboy_10', bg: '/story/pregnancy/badboy/10.jpg', btn: FullScreenNextBtn },
  { id: 'badboy_11', bg: '/story/pregnancy/badboy/11.jpg', btn: FullScreenNextBtn },
  { id: 'badboy_12', bg: '/story/pregnancy/badboy/12.jpg', btn: FullScreenNextBtn },
  {
    id: 'badboy_13', bg: '/story/pregnancy/badboy/13.jpg', btn: [
      {
        href: 'badboy_13.1',
        shap: { x: 62, y: 370, w: 124, h: 67, },
      },
      {
        href: 'badboy_14',
        shap: { x: 229, y: 370, w: 124, h: 67, },
      },
    ]
  },
  { id: 'badboy_13.1', bg: '/story/pregnancy/badboy/10.1.jpg', btn: FullScreenNextBtn },
  { id: 'badboy_13.2', bg: '/story/pregnancy/badboy/10.2.jpg', btn: FullScreenNextBtn },
  { id: 'badboy_14', bg: '/story/pregnancy/badboy/14.jpg', btn: FullScreenNextBtn },
  {
    id: 'badboy_15', bg: '/story/pregnancy/badboy/15.jpg',
    btn: [
      {
        href: RelatedHref.next,
        shap: { x: 84, y: 494, w: 107, h: 46 },
      }, {
        href: 'badboy_16.3.1',
        shap: { x: 216, y: 492, w: 93, h: 45 },
      }, {
        href: 'badboy_16.4.1',
        shap: { x: 43, y: 568, w: 148, h: 48 },
      }, {
        href: 'badboy_16.2.1',
        shap: { x: 216, y: 566, w: 127, h: 50 },
      }
    ]
  },
  { id: 'badboy_16.1.1', bg: '/story/pregnancy/badboy/16.1.1.jpg', btn: FullScreenNextBtn },
  { id: 'badboy_16.1.2', bg: '/story/pregnancy/badboy/16.1.2.jpg', btn: FullScreenNextBtn },
  { id: 'badboy_16.1.3', bg: '/story/pregnancy/badboy/16.1.3.jpg', btn: FullScreenNextBtn },
  { id: 'badboy_16.1.4', bg: '/story/pregnancy/badboy/16.1.4.jpg', btn: FullScreenNextBtn },
  { id: 'badboy_16.1.5', bg: '/story/pregnancy/badboy/16.1.5.jpg', btn: FullScreenNextBtn },
  { id: 'badboy_16.1.6', bg: '/story/pregnancy/badboy/16.1.6.jpg', btn: [{ href: 'badboy_17', shap: FullScreenShap }] },
  { id: 'badboy_16.2.1', bg: '/story/pregnancy/badboy/16.2.1.jpg', btn: FullScreenNextBtn },
  { id: 'badboy_16.2.2', bg: '/story/pregnancy/badboy/16.2.2.jpg', btn: FullScreenNextBtn },
  { id: 'badboy_16.2.3', bg: '/story/pregnancy/badboy/16.2.3.jpg', btn: FullScreenNextBtn },
  { id: 'badboy_16.2.4', bg: '/story/pregnancy/badboy/16.2.4.jpg', btn: FullScreenNextBtn },
  { id: 'badboy_16.2.5', bg: '/story/pregnancy/badboy/16.2.5.jpg', btn: [{ href: 'badboy_17', shap: FullScreenShap }] },
  { id: 'badboy_16.3.1', bg: '/story/pregnancy/badboy/16.3.1.jpg', btn: FullScreenNextBtn },
  { id: 'badboy_16.3.2', bg: '/story/pregnancy/badboy/16.3.2.jpg', btn: FullScreenNextBtn },
  { id: 'badboy_16.3.3', bg: '/story/pregnancy/badboy/16.3.3.jpg', btn: FullScreenNextBtn },
  { id: 'badboy_16.3.4', bg: '/story/pregnancy/badboy/16.3.4.jpg', btn: FullScreenNextBtn },
  { id: 'badboy_16.3.5', bg: '/story/pregnancy/badboy/16.3.5.jpg', btn: FullScreenNextBtn },
  { id: 'badboy_16.3.6', bg: '/story/pregnancy/badboy/16.3.6.jpg', btn: FullScreenNextBtn },
  { id: 'badboy_16.3.7', bg: '/story/pregnancy/badboy/16.3.7.jpg', btn: FullScreenNextBtn },
  { id: 'badboy_16.3.8', bg: '/story/pregnancy/badboy/16.3.8.jpg', btn: [{ href: 'badboy_17', shap: FullScreenShap }] },
  { id: 'badboy_16.4.1', bg: '/story/pregnancy/badboy/16.4.1.jpg', btn: FullScreenNextBtn },
  { id: 'badboy_16.4.2', bg: '/story/pregnancy/badboy/16.4.2.jpg', btn: FullScreenNextBtn },
  { id: 'badboy_16.4.3', bg: '/story/pregnancy/badboy/16.4.3.jpg', btn: FullScreenNextBtn },
  { id: 'badboy_16.4.4', bg: '/story/pregnancy/badboy/16.4.4.jpg', btn: [{ href: 'badboy_17', shap: FullScreenShap }] },
  { id: 'badboy_17', bg: '/story/pregnancy/badboy/17.jpg', btn: FullScreenNextBtn },
  { id: 'badboy_18', bg: '/story/pregnancy/badboy/18.jpg', btn: FullScreenNextBtn },
  { id: 'badboy_19', bg: '/story/pregnancy/badboy/19.jpg', btn: FullScreenNextBtn },
  { id: 'badboy_20', bg: '/story/pregnancy/badboy/20.jpg', btn: FullScreenNextBtn },
  { id: 'badboy_21.1', bg: '/story/pregnancy/badboy/21.1.jpg', btn: FullScreenNextBtn },
  { id: 'badboy_21.2', bg: '/story/pregnancy/badboy/21.2.jpg', btn: FullScreenNextBtn },
  { id: 'badboy_21.3', bg: '/story/pregnancy/badboy/21.3.jpg', btn: FullScreenNextBtn },
  { id: 'badboy_21.4', bg: '/story/pregnancy/badboy/21.4.jpg', btn: FullScreenNextBtn },
  { id: 'badboy_21.5', bg: '/story/pregnancy/badboy/21.5.jpg', btn: FullScreenNextBtn },
  { id: 'badboy_21.6', bg: '/story/pregnancy/badboy/21.6.jpg', btn: FullScreenNextBtn },
  { id: 'badboy_21.7', bg: '/story/pregnancy/badboy/21.7.jpg', btn: FullScreenNextBtn },
  { id: 'badboy_21.8', bg: '/story/pregnancy/badboy/21.8.jpg', btn: FullScreenNextBtn },
  { id: 'badboy_21.9', bg: '/story/pregnancy/badboy/21.9.jpg', btn: FullScreenNextBtn },
  { id: 'badboy_22.1', bg: '/story/pregnancy/badboy/22.1.jpg', btn: FullScreenNextBtn },
  { id: 'badboy_22.2', bg: '/story/pregnancy/badboy/22.2.jpg', btn: FullScreenNextBtn },
  {
    id: 'badboy_22.3', bg: '/story/pregnancy/badboy/22.3.jpg',
    btn: [
      {
        href: 'badboy_24',
        shap: { x: 72, y: 286, w: 119, h: 55 },
      }, {
        href: 'badboy_26.1',
        shap: { x: 222, y: 286, w: 120, h: 55 },
      }, {
        href: RelatedHref.next,
        shap: { x: 123, y: 359, w: 168, h: 55 },
      },
    ]
  },
  { id: 'badboy_23.1', bg: '/story/pregnancy/badboy/23.1.jpg', btn: FullScreenNextBtn },
  { id: 'badboy_23.2', bg: '/story/pregnancy/badboy/23.2.jpg', btn: FullScreenNextBtn },
  {
    id: 'badboy_24', bg: '/story/pregnancy/badboy/24.jpg',
    btn: [
      {
        href: 'badboy_24.1',
        shap: { x: 109, y: 311, w: 198, h: 75 },
      }, {
        href: 'badboy_25.1',
        shap: { x: 109, y: 420, w: 198, h: 75 },
      },
    ]
  },
  { id: 'badboy_24.1', bg: '/story/pregnancy/badboy/24.1.jpg', btn: FullScreenNextBtn },
  { id: 'badboy_24.2', bg: '/story/pregnancy/badboy/24.2.jpg', btn: FullScreenNextBtn },
  { id: 'badboy_24.3', bg: '/story/pregnancy/badboy/24.3.jpg', btn: [{ href: 'badboy_27', shap: FullScreenShap }] },
  { id: 'badboy_25.1', bg: '/story/pregnancy/badboy/25.1.jpg', btn: FullScreenNextBtn },
  { id: 'badboy_25.2', bg: '/story/pregnancy/badboy/25.2.jpg', btn: FullScreenNextBtn },
  { id: 'badboy_25.3', bg: '/story/pregnancy/badboy/25.3.jpg', btn: [{ href: 'badboy_27', shap: FullScreenShap }] },
  { id: 'badboy_26.1', bg: '/story/pregnancy/badboy/26.1.jpg', btn: FullScreenNextBtn },
  { id: 'badboy_26.2', bg: '/story/pregnancy/badboy/26.2.jpg', btn: FullScreenNextBtn },
  { id: 'badboy_26.3', bg: '/story/pregnancy/badboy/26.3.jpg', btn: FullScreenNextBtn },
  { id: 'badboy_26.4', bg: '/story/pregnancy/badboy/26.4.jpg', btn: [{ href: 'badboy_27', shap: FullScreenShap }] },
  { id: 'badboy_27', bg: '/story/pregnancy/badboy/27.jpg', btn: FullScreenNextBtn },
  { id: 'badboy_28', bg: '/story/pregnancy/badboy/28.jpg', btn: FullScreenCloseBtn },
];

export const PREGNANCY_SLIDE: Slide[] = [
  {
    id: 'chose_boy', bg: '/story/pregnancy/chose_boy.jpg',
    btn: [
      {
        href: 'goodboy_1',
        shap: { x: 143, y: 305, w: 129, h: 50 },
      }, {
        href: 'badboy_1',
        shap: { x: 143, y: 373, w: 129, h: 50 },
      }
    ]
  },
  ...PREGNANCY_GOODBOY_SLIDE,
  ...PREGNANCY_BADBOY_SLIDE,
];
