import { useState } from 'react';

type OrientationAngle = 0 | 180 | 90 | -90;

export enum OrientationType {
  portrait = 'portrait',
  landscape = 'landscape',
};

const helper = {
  isRegistered: false,
  type: {
    0: OrientationType.portrait,
    180: OrientationType.portrait,
    90: OrientationType.landscape,
    [-90]: OrientationType.landscape,
    portrait: OrientationType.portrait,
    landscape: OrientationType.landscape,
  },
  getTypeByAngle(angle: string | number): OrientationType {
    return helper.type[angle as OrientationAngle] || null;
  },
};

export const useOrientation = (global: Window) => {
  const [orientationType, setOrientationType] = useState<OrientationType>(
    helper.getTypeByAngle(global.orientation)
  );

  if (!helper.isRegistered && 'onorientationchange' in global) {
    helper.isRegistered = true;
    global.addEventListener('orientationchange', function onOrientationChange() {
      const type = helper.getTypeByAngle(global.orientation);
      setOrientationType(type);
    }, false);
  }

  return { orientationType };
}
