import React, { createElement, FunctionComponent, isValidElement } from 'react';
import './ChipSelect.scss';
import classNames from 'classnames';

export type OptionComponent<T> = FunctionComponent<{ option: T, className: string; isActive: boolean; onClick: VoidFunction }>;
export interface ChipSelectProps<T = any> {
  label?: string;
  value?: T | null;
  onValueChanged?(v: T): void;
  options?: T[];
  optionComponent?: OptionComponent<T>;
  isSelected?(option: T, value: T): boolean;
  toLabel?(option: T): string;
  horizontal?: boolean;
  className?: string;
}

export const ChipSelect: FunctionComponent<ChipSelectProps> = ({
  label = null,
  value = null,
  onValueChanged = () => { },
  options = [],
  optionComponent,
  isSelected = (a, b) => a === b,
  toLabel = op => op,
  horizontal = false,
  className = ''
}) => {
  function select(op: any) {
    const nextValue = isSelected(op, value!) ? null : op;
    onValueChanged(nextValue);
  };
  return (
    <ul className={classNames({ 'chip-select': true, horizontal }, className)}>
      {options.map((option, key) => {
        const isActive = isSelected(option, value!);
        const className = classNames('chip', { active: isActive });
        const onClick = () => select(option);
        const props = { option, key, className, onClick };
        return optionComponent
          ? createElement(optionComponent, { ...props, isActive })
          : createElement('li', props, toLabel(option))
      })}
    </ul>
  );
};