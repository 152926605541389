import React, { FunctionComponent } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { BaseLayout } from '@component/layouts';
import { AuthService as useCase } from '@/auth';

export const LobbyPageCmp: FunctionComponent = () => {
  const history = useHistory();

  function signInAsAnonymous() {
    useCase.signInAsAnonymous();
    history.push('/intro');
  }

  return (
    <BaseLayout
      body={
        <div>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '40px' }}>
            <img className="img-response" src="/home/line.png" alt="" />
            <h2 style={{ color: '#68A8B7' }}>歡迎使用</h2>
            <h2 style={{ color: '#D67A72' }}>幸福 On the Go</h2>
            <img className="img-response" src="/home/line.png" alt="" />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
            <img style={{ margin: '20px' }} src="/home/boy.png" alt="" />
            <img style={{ margin: '20px' }} src="/home/girl.png" alt="" />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '40px' }}>
            <Link className="btn primary fz-lg" to="/sign-in">登入</Link>
            <Link className="btn fz-lg" style={{ color: '#6D6E71', marginTop: '10px' }} to="/sign-up">註冊</Link>
            <button className="btn fz-lg" style={{ color: '#B7A0A0' }} onClick={signInAsAnonymous}>訪客進入進入</button>
          </div>
        </div>
      }
    />
  );
};
