import { useLocation } from 'react-router-dom';

export type QueryParams = { [key: string]: any; };

export function useQueryParams<T extends QueryParams>(): T {
  const searchParams = new URLSearchParams(useLocation().search);
  return Array.from(searchParams)
    .reduce((acc, [k, v]) => {
      acc[k] = v;
      return acc;
    }, {} as any);
}
