export interface Storage<T = any> {
  value: T;
  onChange(changeFn: (data: T) => void): CancelChangeFn;
  onComplete(completeFn: VoidFunction): void;
  complete: VoidFunction;
  cleanObserver: VoidFunction;
}

export type CancelChangeFn = VoidFunction;
export interface StorageParams<T = any> {
  initValue?: T;
  getter?(): T;
  setter?(data: T): void;
  skipReplay?: boolean;
}

export function useStorage<T = any>({ initValue, getter, setter, skipReplay }: StorageParams = {}): Storage<T> {
  let _data: T;
  let changeObservers: Array<(data: T) => void> = [];
  let completeObservers: Array<() => void> = [];
  let isComplete = false;
  const completeError = new Error('is complete');

  const getterFn = getter! || (() => _data);
  const setterFn = setter! || ((data: T) => { _data = data; });

  function clearChange(changeFn: Function) {
    changeObservers = changeObservers.filter(o => o !== changeFn);
  }

  setterFn(initValue);

  return {
    get value() {
      if (isComplete) { throw completeError; }
      return getterFn();
    },
    set value(data: T) {
      if (isComplete) { throw completeError; }
      setterFn(data);
      changeObservers.forEach(changeFn => changeFn(data));
    },
    onChange(changeFn: (data: T) => void) {
      if (isComplete) { throw completeError; }
      changeObservers.push(changeFn);
      if (!skipReplay) { changeFn(getterFn()); }
      return () => clearChange(changeFn);
    },
    onComplete(completeFn: () => void) {
      if (isComplete) { throw completeError; }
      changeObservers.push(completeFn);
    },
    complete() {
      if (isComplete) { throw completeError; }
      completeObservers.forEach(completeFn => completeFn());
      isComplete = true;
      completeObservers = [];
      changeObservers = [];
    },
    cleanObserver() {
      completeObservers = [];
      changeObservers = [];
    }
  }
}
