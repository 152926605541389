import { CanActivate } from '@/route';
import { FindBackUsernameFormValue, ModifyAngelReqBody, ModifyPasswordFormValue, ModifyPasswordReqBody, SignInFormValue, SignUpFormValue, SignUpReqBody, VerfyForgotPasswordFormValue, VerfyForgotPasswordReqBody } from '../model';
import { AuthApiService, AuthStorage } from '../repository';

function toSignUpReqBody({
  username,
  password,
  gender,
  grade,
  birthdate,
  city,
  email,
  phone,
}: SignUpFormValue): SignUpReqBody {
  return {
    username: username.toLowerCase(),
    password,
    gender,
    grade,
    birthdate,
    city,
    email: email?.toLocaleLowerCase(),
    phone,
  };
}

function toVerfyForgotPasswordReqBody(
  { username, gender, birthdate }: VerfyForgotPasswordFormValue
): VerfyForgotPasswordReqBody {
  const date = new Date(birthdate);
  const monthOfBirth = date.getMonth() + 1;
  const dayOfBirth = date.getDate();
  return { username, gender, monthOfBirth, dayOfBirth };
}

function toModifyPasswordReqBody(
  { username, gender, monthOfBirth, dayOfBirth, }: VerfyForgotPasswordReqBody,
  { password }: ModifyPasswordFormValue
): ModifyPasswordReqBody {
  const birthday = `${monthOfBirth}-${dayOfBirth}`;
  return { username, password, gender, birthday };
}

class AuthUseCase implements CanActivate {
  private api = new AuthApiService(process.env.REACT_APP_ONTHEGO_API as string);
  private storage = new AuthStorage();
  private verfyForgotPasswordReq: VerfyForgotPasswordReqBody = null as any;

  getMyUserId(): string {
    return this.storage.getToken()!;
  }

  canActivate(): boolean {
    return this.isPermission();
  }

  isPermission(): boolean {
    return !!this.storage.getToken();
  }

  signIn(record: SignInFormValue): Promise<null> {
    record.username = record.username.toLowerCase();
    return this.api.signIn(record)
      .then(username => {
        this.storage.setToken(username);
        return null;
      });
  }

  signInAsAnonymous(): void {
    this.storage.setToken('anonymous');
  }

  signUp(record: SignUpFormValue) {
    const payload = toSignUpReqBody(record);
    return this.api.signUp(record)
      .then(() => {
        this.storage.setToken(payload.username);
        return null;
      });
  }

  signOut() {
    this.storage.setToken('');
  }

  verfyForgotPassword(record: VerfyForgotPasswordFormValue): Promise<null> {
    const payload = toVerfyForgotPasswordReqBody(record);
    return this.api.verfyForgotPassword(payload)
      .then(() => {
        this.verfyForgotPasswordReq = payload;
        return null;
      });
  }

  modifyPassword(record: ModifyPasswordFormValue): Promise<null> {
    const payload = toModifyPasswordReqBody(this.verfyForgotPasswordReq, record);
    return this.api.modifyPassword(payload);
  }

  findBackUsername(record: FindBackUsernameFormValue): Promise<string> {
    return this.api.findBackUsername(record);
  }

  modifyAngel(record: ModifyAngelReqBody): Promise<null> {
    return this.api.modifyAngel(record);
  }
}

export const AuthService = new AuthUseCase();
export const UnauthorizedService: CanActivate = { canActivate: () => !AuthService.canActivate() };
