import React, { useState, useRef, useEffect, FunctionComponent } from 'react';
import './chatbot.page.scss';
import { getProvider } from '@/Context';
import { BaseHeader, BaseLayout } from '@component/layouts';
import { Chatbot } from '../use-case';
import { Message } from '../model';
import { Option, Msg } from '../atom';

export const ChatbotPageCmp: FunctionComponent = () => {
  const { completeTask } = getProvider('taskHook');
  const [messages, setMessages] = useState<Message[]>([]);
  const [options, setOptions] = useState<Message[]>([]);
  const [isFreshStart, setFreshStart] = useState<boolean>(true);
  const msgContainerRef = useRef<HTMLDivElement>(null);
  const isUnmounted = useRef(false);

  function completeTaskChatroomOnLastMsgCodeIs72(msgs: Message[]) {
    const lastMsg = msgs.slice().pop();
    const isLastMsgCode72 = lastMsg && lastMsg.code === '72';
    if (isLastMsgCode72) {
      completeTask('taskChatroom');
    }
    console.log(msgs, { isLastMsgCode72 });
  }

  useEffect(() => {
    Chatbot.clear();
    const messagesSubscrption = Chatbot.messages$
      .subscribe({
        next(data) {
          setMessages(data);
          completeTaskChatroomOnLastMsgCodeIs72(data);
        }
      });
    const optionsSubscrption = Chatbot.options$
      .subscribe({ next: setOptions })
    const storeHistorySubscrption = Chatbot.storeOnNotTyping()
      .subscribe();

    return () => {
      isUnmounted.current = true;
      messagesSubscrption.unsubscribe();
      optionsSubscrption.unsubscribe();
      storeHistorySubscrption.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const node = msgContainerRef.current;
    if (node) {
      setTimeout(() => { // After the message and options are rendered
        node.scrollTop = node.scrollHeight;
      }, 10);
    }
  }, [messages, options]);

  const startChat = async () => {
    setFreshStart(false);
    try {
      await Chatbot.init();
    } catch (error) {
      setFreshStart(true);
      console.error(error);
    }
  };

  return (
    <BaseLayout
      className="chatbot-page"
      header={
        <>
          <BaseHeader title="幸福聊天室" />
          <div className="toolbar">
            <img src="/chatroom/female1.png" alt="" />
            <label style={{ marginLeft: 8 }}>幸福小天使</label>
          </div>
        </>
      }
      body={
        <div className="msg-container" ref={msgContainerRef}>
          {messages.map((msg, i) => (<Msg msg={msg} key={i} />))}
        </div>
      }
      footer={
        <>
          <div className="option-container">
            {options.map((op, i) => (<Option key={i} option={op} />))}
            {isFreshStart && <button className='option' onClick={() => startChat()}>開始聊天</button>}
          </div>
          <div className="input-container hidden">
            <input type="text" />
          </div>
        </>
      }
    />
  );
}