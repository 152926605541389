// Hook
export interface ForumControl {
  category: number| null;
  setCategory(category: number| null): void;
  isCategory(category: number| null): boolean;

  posts: Post[];
  getPosts(params: GetPostsParams): Promise<void>;
  addPost(payload: Post): Promise<void>;

  post: Post;
  getPost(params: GetOnePostParams): Promise<void>;
  addPostEmoji({ emoji }: Pick<PostEmoji, 'emoji'>): Promise<void>;

  replies: Reply[];
  getReplies(params: GetReplyFromPostParams): Promise<void>;
  addReply(payload: Reply): Promise<void>;
  addReplyEmoji(replyEmoji: ReplyEmoji): Promise<void>;
  canOpenReplyModal(params: OpenReplyModalQuery): boolean;
  getReplyLayerNum(payload: Reply): number;
}

// Post

export interface Post { //貼文
  postId?: string;
  avatar: number; //頭像: 1/2/3/4/5/6
  nickname: string; //暱稱
  age: number;
  topic: string; //標題
  category: number; //文章類別: 1.心情/2.人際/3.身體/0.無
  content: string; //文章內容
  postDate?: string; //貼文日期 (儲存時系統給)
  emojiCounts?: EmojiCount[];  //得到的表情與表情數量
  replyCount?: number; //回應文數量
  replies?: Reply[]; //回應文
}

export function makePost(post?: Post): Post {
  return {
    postId: '',
    avatar: 1,
    nickname: '',
    age: 0,
    topic: '',
    category: 0,
    content: '',
    postDate: '',
    emojiCounts: [],
    replyCount: 0,
    replies: [],
  }
}

export interface EmojiCount {
  type: number;
  count: number;
}

export interface GetPostsParams {
  category: number | null;
}

export interface GetOnePostParams {
  postId: string;
}

export interface PostEmoji {
  postId: string;
  emoji: number;
}

// Reply

export interface Reply { //回應文
  replyId: string;
  postId: string; // 所屬的貼文/回應文 id
  avatar: number; // 頭像: 1/2/3/4/5/6
  nickname: string; //暱稱
  age: number; //年齡
  content: string; //內容
  replyDate: string; //回應日期 (儲存時系統給)
  emojiCount: EmojiCount; //得到的愛心表情與愛心數量
  replies: number; //回應文的回應文數量
}

export interface GetReplyFromReplyParams {
  replyId: string;
  postId: string;
}

export interface GetReplyFromPostParams {
  postId: string;
}

export interface FilterReplyParams {
  parentPostId: string;
}

export interface ReplyEmoji {
  postId: string;
  replyId: string;
}

export interface OpenReplyModalQuery {
  postId?: string;
  replyId?: string;
}

export interface GetReplyLayerNumParams {
  replyId: string;
}

// enum

export const Avatar = {
  boy1: 1,
  boy2: 2,
  boy3: 3,
  girl1: 4,
  girl2: 5,
  girl3: 6,
}

export const AvatarOptions = Object.values(Avatar);

export const Emoji = {
  sad: 1,
  smile: 2,
  strong: 3,
  like: 4,
  heart: 5,
};

export const EmojiOptions = Object.values(Emoji);

export const Category = {
  mood: 1,
  interpersonal: 2,
  body: 3,
  none: 0,
  all: null,
};

export const CategoryOptions = [{
  label: '所有',
  code: Category.all
}, {
  label: '心情',
  code: Category.mood
}, {
  label: '人際',
  code: Category.interpersonal
}, {
  label: '身體',
  code: Category.body,
}, {
  label: '無分類',
  code: Category.none
}];

export const toCategoryLabel = (code: number) => {
  return CategoryOptions.find(c => c.code === code)?.label;
}
