import React, { createElement, FunctionComponent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { BaseLayout } from '@component/layouts';
import { ChipSelect, OptionComponent } from '@component/atoms';
import { AvatarOptions } from '@/forum/model';
import classNames from 'classnames';
import './intro.scss';
import { AuthService as useCase } from '@/auth';

interface StepCmpConfig {
  component: FunctionComponent<IntroProps>;
  props: IntroProps;
}

interface IntroProps {
  onNext(avatar?: number): void;
  describe?: string;
}

export const IntroPageCmp: FunctionComponent = () => {
  const history = useHistory();
  const [setp, setStep] = useState<number>(0);
  const stepCmps: StepCmpConfig[] = [{
    component: Intro0,
    props: { onNext },
  }, {
    component: IntroDescribe,
    props: {
      onNext,
      describe: '/home/intro0.png',
    },
  }, {
    component: IntroDescribe,
    props: {
      onNext,
      describe: '/home/intro1.png',
    },
  }, {
    component: IntroDescribe,
    props: {
      onNext,
      describe: `/home/intro2.png`,
    }
  }, {
    component: IntroDescribe,
    props: {
      onNext,
      describe: `/home/intro3.png`,
    }
  }, {
    component: IntroDescribe,
    props: {
      onNext,
      describe: `/home/intro4.png`,
    }
  }, {
    component: IntroDescribe,
    props: {
      onNext,
      describe: `/home/intro5.png`,
    }
  }, {
    component: IntroDescribe,
    props: {
      onNext,
      describe: `/home/intro6.png`,
    }
  }, {
    component: IntroDescribe,
    props: {
      onNext,
      describe: `/home/intro7.png`,
    }
  }];

  async function onNext(avatar?: number) {
    const isFirst = setp === 0 && avatar;
    if (isFirst) {
      setStep(setp + 1);
      // Todo get angel on chatroom page initial
      return useCase.modifyAngel({ angel: `${avatar}` })
        .then(() => setStep(setp + 1));
    }

    const isLast = setp === stepCmps.length - 1;
    if (isLast) {
      history.push('/');
      return;
    }

    setStep(setp + 1);
    return;
  }

  return (
    <BaseLayout
      className="intro-page"
      body={createElement<IntroProps>(stepCmps[setp].component, stepCmps[setp].props)}
    />
  );
};

const AvatarOptionCmp: OptionComponent<number> = ({ option, className, onClick, isActive }) => (
  <li className={classNames('avatar', className)} onClick={onClick}>
    <img className="img-response" src={`/home/avatar${option}${isActive ? '-active' : ''}.png`} alt="" />
  </li>
);

const Intro0: FunctionComponent<IntroProps> = ({ onNext = () => { } }) => {
  const [avatar, setAvatar] = useState<number>(null as any);

  function confirm() {
    if (avatar) {
      onNext(avatar);
    }
  }

  return (
    <div className="text-center from-container">
      <p>請點選一位你喜歡的幸福小天使</p>
      <ChipSelect
        className="form-control"
        options={AvatarOptions}
        optionComponent={AvatarOptionCmp}
        onValueChanged={value => setAvatar(value)}
        value={avatar}
      />
      <div className="text-center">
        <button
          type="button"
          className="btn btn-submit fz-lg"
          onClick={confirm}
        >確定</button>
      </div>
    </div>
  )
};

const IntroDescribe: FunctionComponent<IntroProps> = ({ onNext = () => { }, describe = '' }) => (
  <div style={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  }} onClick={() => onNext()}>
    <img className="img-response" src={describe} alt="" />
  </div>
);
