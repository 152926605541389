import { Slide, Size, RelatedHref, Shap, SlideApp, SlideAppParams } from './slide-board.model';
import { Application, LoaderResource, sound, Sprite } from 'pixi.js';

export function makeSildeApp({
  view,
  slide,
  bgmSrc,
  onProgress = () => { },
  size,
  goBack,
  completeTask,
  taskName
}: SlideAppParams): SlideApp {
  // make app
  const app = new Application({
    view,
    width: size.width,
    height: size.height,
  });
  let bgm: sound.Sound;
  const completeTaskFn = () => completeTask(taskName);

  // add resource
  if (bgmSrc) {
    app.loader.add('bgm', bgmSrc);
  }
  app.loader
    .add(slide.map(s => s.bg))
    .on('start', () => onProgress(app.loader.progress | 0))
    .on('progress', () => onProgress(app.loader.progress | 0))
    .on('complete', () => onProgress(app.loader.progress | 0))
    .load((loader, resources) => {
      const ctrl: SlideCtrl = new SlideCtrl(app, slide, goBack, completeTaskFn);
      ctrl.onInit();
      playBgm(app.loader.resources);
    });

  function playBgm(resources: Partial<Record<string, LoaderResource>>): void {
    bgm = resources['bgm']?.sound!;
    bgm?.play({ loop: true, volume: 0.1 });
  }

  return {
    destory() {
      app.destroy();
      bgm?.destroy();
    }
  }
}

class SlideCtrl {
  currentSlide: Slide = null as any as Slide;

  constructor(
    private app: Application,
    private slides: Slide[],
    private goBack: () => void,
    private completeTask: () => void,
  ) { }

  onInit() {
    this.currentSlide = this.slides[0];
    this.renderSlide(this.currentSlide);
  }

  go(href: string | RelatedHref) {
    if (href === RelatedHref.close) {
      this.goBack();
      return;
    }
    const slide = this.getSlideByHref(href);
    if (slide && slide !== this.currentSlide) {
      this.currentSlide = slide;
      this.renderSlide(slide);
    }
    const lastSlide = this.getSlideByHref(RelatedHref.last);
    // Todo customize specific slide ID for task completion
    const goodBoyEndSlide = this.slides.find(s => s.id === 'goodboy_finished');
    const isOnGoodBoyEndSlide = goodBoyEndSlide && slide === goodBoyEndSlide;
    const isOnLastSlide = slide === lastSlide || isOnGoodBoyEndSlide;
    if (isOnLastSlide) {
      this.completeTask();
    }
  }

  getSlideByHref(href: string | RelatedHref): Slide {
    const list = this.slides;
    switch (href) {
      case RelatedHref.first:
        return list[0];
      case RelatedHref.last:
        return list.slice(-1)[0];
      case RelatedHref.next:
        return list[list.indexOf(this.currentSlide) + 1];
      case RelatedHref.previous:
        return list[list.indexOf(this.currentSlide) - 1];
      default:
        return list.find(s => s.id === href as string) as Slide;
    }
  }

  renderSlide(slide: Slide) {
    const texture = this.app.loader.resources[slide.bg].texture;
    const bgSize = scale(texture, this.app.screen);
    const bg = new Sprite(texture);

    bg.anchor.set(0.5);
    bg.x = this.app.screen.width / 2;
    bg.y = this.app.screen.height / 2;
    bg.width = bgSize.width;
    bg.height = bgSize.height;

    this.app.stage.removeChildren();
    this.app.stage.addChild(bg);
    this.renderButton(slide);
  }

  renderButton(slide: Slide) {
    const self = this;
    slide.btn.forEach(b => {
      const shap = b.shap as Shap;
      const btn = new Sprite();
      btn.x = shap?.x || 0;
      btn.y = shap?.y || 0;
      btn.width = shap?.w || this.app.screen.width;
      btn.height = shap?.h || this.app.screen.height;

      // bind btn event
      btn.interactive = true;
      btn.buttonMode = true;
      btn
        .on('pointertap', () => {
          self.go(b.href);
        });

      this.app.stage.addChild(btn);
    });
  }
}

function scale(origin: Size, limit: Size): Size {
  const pScale = limit.width / limit.height;
  const cScale = origin.width / origin.height;
  const resultSize: Size = {
    width: limit.width,
    height: limit.height,
  };
  if (pScale > cScale) {
    resultSize.width = Math.floor(limit.height * cScale);
  } else {
    resultSize.height = Math.floor(limit.width / cScale);
  }
  return resultSize;
};
