import { Scene, NodeLifecircle, Game, SpriteStorage, DisplayNode } from '@library/game';
import { CancelChangeFn } from '@library/storage';
import { SceneName, ThrowStateName } from './config';
import { ThrowDart } from './useThrowDarts';

export class ResultScene extends Scene implements NodeLifecircle {

  private cancelThrowDartStateFn!: CancelChangeFn;

  constructor(
    private game: Game,
    private spriteStorage: SpriteStorage,
    private useCase: ThrowDart,
  ) {
    super();
  }

  onInit() {
    console.log('onInit ResultScene');
    this.navigateSceneOnState();
  }

  onDestory() {
    console.log('onDestory ResultScene');
    this.cancelNavigateScene();
  }

  navigateSceneOnState() {
    const self = this;
    this.cancelThrowDartStateFn = this.useCase
      .onState(stateName => {
        if (stateName === ThrowStateName.chosingAngle) {
          self.game.navigate(SceneName.ThrowDartsScene);
        }
        if (stateName === ThrowStateName.end) {
          self.game.navigate(SceneName.EndgameScene);
        }
      });
  }

  cancelNavigateScene() {
    this.cancelThrowDartStateFn();
  }

  render() {
    const self = this;
    const scoreRule = this.useCase.getScoreRule();
    const resultObject = scoreRule.resultTextureName
      ? this.spriteStorage.get(scoreRule.resultTextureName)
      : undefined;

    if (!resultObject) {
      setTimeout(() => {
        self.game.navigate(SceneName.EndgameScene);
      }, 10);
    }

    return (
      DisplayNode.of({
        object: resultObject,
        decoration: {
          interactive: true,
          buttonMode: true,
        },
        onPointertap() {
          self.useCase.nextState();
        }
      })
    );
  }
}
