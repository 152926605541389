import { Observable, from, concat } from 'rxjs';
import { map, filter, concatMap } from 'rxjs/operators';
import { Message, GetMessageInput, TypingMessage, GeneralMessage, EmailMessage, ChatroomMessageHistory, Chatroom, HistoryMessage } from '../model';
import { ChatbotStore, ChatbotApiService } from '../repository';
import { random } from '@library/random';

class ChatbotService {
  private apiService = new ChatbotApiService(process.env.REACT_APP_ONTHEGO_API as string)
  private store = new ChatbotStore();

  readonly messages$: Observable<Message[]> = this.store.messages$;
  readonly options$: Observable<Message[]> = this.store.options$;

  clear() {
    this.store.setAll([]);
    this.store.setOptions([]);
  }

  init(): Promise<Message> {
    return this.getAndStoreFirstMessage()
      .then(m => this.loopGetNextWhenOnlyNextMessage(m))
      .then(chatbotMsg => {
        this.store.setOptions(chatbotMsg.next || []);
        return Promise.resolve(chatbotMsg);
      });
  }

  reply(message: Message): Promise<Message> {
    const myMessage: Message = decrateMy(message);
    this.store.addOne(myMessage);
    this.store.setOptions([]);

    return this.getAndStoreOneMessage(message)
      .then(m => this.loopGetNextWhenOnlyNextMessage(m))
      .then(chatbotMsg => {
        this.store.setOptions(chatbotMsg.next || []);
        return Promise.resolve(chatbotMsg);
      });
  }

  storeOnNotTyping(): Observable<any> {
    let chatroom: Chatroom;

    const getNewChatroom$ = from(this.apiService.getNewChatroom()
      .then(c => chatroom = c));

    const saveHistoryOnLastMsg$ = this.messages$
      .pipe(
        map(msgs => msgs.slice().pop()),
        filter(msg => !!msg && (msg.type === 'message' || msg.type === 'mail')),
        concatMap((msg) => this.saveHistory(chatroom, msg as GeneralMessage | EmailMessage)),
      );

    return concat(getNewChatroom$, saveHistoryOnLastMsg$);
  }

  private saveHistory(chatroom: Chatroom, msg: GeneralMessage | EmailMessage): Observable<string> {
    const { isMine, ...historyMessages } = msg;
    const payload = makeChatroomMessageHistory(chatroom, historyMessages);
    return from(this.apiService.saveHistory(payload));
  }

  private loopGetNextWhenOnlyNextMessage(message: Message): Promise<Message> {
    const isOnlyNext = message.next && message.next.length === 1;
    return isOnlyNext
      ? this.getAndStoreOneMessage(message.next![0])
        .then(m => this.loopGetNextWhenOnlyNextMessage(m))
      : Promise.resolve(message);
  }

  private async getAndStoreFirstMessage(): Promise<Message> {
    const m = await this.apiService.getFirst()

    const inputtingMsg = makeTypingMsg();
    this.store.addOne(inputtingMsg);
    await delay(this.calcChatbotWritingTime(m));

    const chatbotMsg = decrateTime(m);
    this.store.putOne(inputtingMsg, chatbotMsg);

    return chatbotMsg;
  }

  private async getAndStoreOneMessage(message: Message): Promise<Message> {
    const params: GetMessageInput = { code: message.code };
    const m = await this.apiService.getOne(params);

    await delay(this.calcChatbotReadingTime(message));

    const inputtingMsg = makeTypingMsg();
    this.store.addOne(inputtingMsg);
    await delay(this.calcChatbotWritingTime(m));

    const chatbotMsg = decrateTime(m);
    this.store.putOne(inputtingMsg, chatbotMsg);

    return chatbotMsg;
  }

  private calcChatbotReadingTime(message: Message) {
    return random(0, 200);
  }

  private calcChatbotWritingTime(message: Message) {
    return random(1000, 3000);
  }

}

export const Chatbot = new ChatbotService();

const decrateTime: (msg: Message) => Message = msg => {
  return { ...msg, time: new Date().toISOString() };
};
const decrateMy: (msg: Message) => Message = msg => {
  return { ...msg, isMine: true, time: new Date().toISOString() };
};
const makeTypingMsg: () => TypingMessage = () => {
  return { isMine: false, code: '-1', type: 'typing', description: '...', content: '...' };
};
const makeChatroomMessageHistory: (chatroom: Chatroom, message: HistoryMessage) => ChatroomMessageHistory = (chatroom, message) => {
  return { ...chatroom, message };
};

const delay: (time: number) => Promise<void> = time => new Promise(
  resolve => setTimeout(resolve, time)
);
