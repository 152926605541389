
export interface Gameboard {
  gameId?: string;
  thumbnail?: string;
  title?: string;
  description?: string;
  link?: string;
}

export class GameService {

  private config: Gameboard[];

  constructor() {
    this.config = GAMES;
  }

  async getGame(): Promise<Gameboard[]> {
    return this.config;
  }

  async getGameById(gameId: string): Promise<Gameboard> {
    return this.config.find(c => c.gameId === gameId)!;
  }
};

const GAMES: Gameboard[] = [{
  gameId: 'condom',
  thumbnail: '/game/condom/condom_pic.png',
  title: '保險套遊戲',
  description: '要發生性行為時全程使用保險套很重要，正確使用保險套更重要！怎麼使用才正確呢？',
  link: '/game/condom',
}, {
  gameId: 'dart',
  thumbnail: '/game/dart/dart_pic.png',
  title: '飛鏢遊戲（預防懷孕知識）',
  description: '我還年輕，不想有小寶寶，那我該注意什麼呢？ 沒錯！點進來，你/妳就能快速了解男女避孕方式！',
  link: '/game/dart',
}, {
  gameId: 'basketball',
  thumbnail: '/game/basketball/btn_start.png',
  title: '籃球遊戲（預防愛滋病知識）',
  description: '愛滋病距離我們並不遙遠，通過籃球遊戲，馬上讓你/妳掌握愛滋病知識，成為這方面的小專家！',
  link: '/game/basketball',
}];
