import React, { useState, useEffect, FunctionComponent } from 'react';
import { Gameboard, GameService } from './game.service';
import { BaseHeader, BaseLayout } from '@component/layouts';
import { TopicList } from '@component/atoms';

export const GameListPageCmp: FunctionComponent = () => {
  const gameService = new GameService();
  const [list, setList] = useState<Gameboard[]>([]);

  useEffect(() => {
    gameService.getGame()
      .then(setList)
      .catch(console.error);
    return () => {
    };
  }, [gameService]);

  return (
    <BaseLayout
      header={<BaseHeader title="性教育遊戲" />}
      body={<TopicList list={list} />}
    />
  );
}
