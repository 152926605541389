import React, { ReactNode } from 'react';
import './story-layout.scss';

interface StoryLayoutProps {
  children?: ReactNode;
}

export const StoryLayout = ({ children }: StoryLayoutProps) => (
  <div className="story-layout">
    {children}
  </div>
);
