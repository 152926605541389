import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import { ChipSelect, ChipSelectProps, OptionComponent } from '@component/atoms';
import { formatDate } from '@library/object';
import { EmojiOptions, Post, toCategoryLabel } from '../model';
import { calcPostEmojiAmount } from '../use-case';
import './post.scss';

const EmojiPicker: FunctionComponent<
  Pick<ChipSelectProps, 'value' | 'onValueChanged'>
> = ({ value = null, onValueChanged }) => (
  <ChipSelect
    className="emoji-picker"
    options={EmojiOptions}
    optionComponent={EmojiOptionCmp}
    onValueChanged={value => onValueChanged!(value)}
    value={value}
  />
)

const EmojiOptionCmp: OptionComponent<number> = ({ option, className, onClick, isActive }) => (
  <li className={classNames('emoji', className)} onClick={onClick}>
    <img src={`/post/emoji${option}_L.png`} alt="" />
  </li>
);

export const PostDetail: FunctionComponent<Post & {
  disableEmojiPicker?: boolean;
  onEmoji?(emoji: number): void;
  onShare?(): void;
  onReply?(): void;
}> = ({
  avatar,
  nickname,
  age,
  topic,
  category,
  content,
  postDate,
  emojiCounts,
  replyCount = 0,
  disableEmojiPicker = false,
  onEmoji = () => { },
  onShare = () => { },
  onReply = () => { },
}) => (
      <>
        <div className="post">
          <div className="post-head">
            <div className="avatar">
              <img className="img-response" src={`/post/avatar${avatar}_L.png`} alt="" />
            </div>
            <div className="meta">
              <div className="user-info">
                <span>{nickname}</span>
                <span>．</span>
                <span>{age}歲</span>
              </div>
              <p className="title">{topic}</p>
              <div className="post-info">
                <span>{toCategoryLabel(category)}</span>
                <span>．</span>
                <span>{formatDate(postDate!)}</span>
              </div>
            </div>
          </div>
          <div className="post-content">
            <p>{content}</p>
          </div>
        </div>
        <div className="post-action">
          <div className="emoji-container">
            {
              (emojiCounts || []).map(e => (
                <span key={e.type.toString()} className="emoji">
                  <img src={`/post/emoji${e.type}.png`} alt="" />
                </span>
              ))
            }
            <span className="emoji-count">{calcPostEmojiAmount(emojiCounts!)}</span>
            {!disableEmojiPicker && <EmojiPicker onValueChanged={onEmoji} />}
          </div>
          <span className="reply-count">回應 {replyCount}</span>
          <div className="btn-share" onClick={onShare}><img src="/post/share.png" alt="" /></div>
        </div>
        <div className="open-reply-container">
          <div className="btn-reply" onClick={onReply}>我要回應......</div>
        </div>
      </>
    );
